import React from 'react'
import { Modal, Button, Row, Col, Select, notification } from "antd";
import moment from "moment";

const rowInput = {
    marginBottom: 5,
}

const App = (
    {
        journalUpdateData, accountData, addJournalLoading,
        changeJournalUpdateData, handleUpdateJournalSave,
    }
) => {
    const [selectedAccountId, setSelectedAccountId] = React.useState(null)

    const hadleSimpanBtnClick = () => {
        if (selectedAccountId === journalUpdateData.account_id) {
            return notification.warn({
                message: '!',
                description: 'Akun sama dengan akun saat ini',
            })
        }
        handleUpdateJournalSave(journalUpdateData?.id, {
            account_id: selectedAccountId,
        })
        return
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div>
            <Modal
                title={`Update Data Journal`}
                open={journalUpdateData.hasOwnProperty('id')}
                onCancel={() => {
                    changeJournalUpdateData({});
                }}
                style={{ top: 20 }}
                bodyStyle={{
                    height: "100%",
                }}
                width={"800px"}
                footer={[
                    <Button
                        key={1}
                        onClick={() => {
                            changeJournalUpdateData({})
                        }}
                        loading={addJournalLoading}
                        disabled={addJournalLoading}
                    >
                        cancel
                    </Button>,
                    <Button
                        key={2} type='primary' onClick={hadleSimpanBtnClick}
                        loading={addJournalLoading}
                        disabled={addJournalLoading}
                    >
                        Simpan
                    </Button>
                ]}
                disabled
                destroyOnClose
            >
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>No Bukti</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {journalUpdateData?.no_bukti}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>No Ref</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {journalUpdateData?.no_ref}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Tanggal</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {moment(journalUpdateData?.date).format('DD MMMM YYYY')}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Tanggal Input</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {moment(journalUpdateData?.created_at).format('DD MMMM YYYY')}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Deskripsi</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {journalUpdateData?.description}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Akun Sekarang</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {journalUpdateData?.account_group}  / {journalUpdateData?.account_name}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Akun Baru</label>
                    </Col>
                    <Col span={18}>
                        <Select
                            showSearch
                            filterOption={filterOption}
                            style={{ marginRight: '8px', width: "60%" }}
                            placeholder="Select an account"
                            onChange={(value) => {
                                if (value === journalUpdateData.account_id) {
                                    return notification.warn({
                                        message: '!',
                                        description: 'Akun sama dengan akun saat ini',
                                    })
                                }
                                setSelectedAccountId(value)
                            }}
                            options={accountData}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default App