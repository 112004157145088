import React from 'react'
import { Modal, Button, Row, Col, } from "antd";
import moment from "moment";

const rowInput = {
    marginBottom: 5,
}

const App = (
    {
        journalDeleteData, addJournalLoading,
        changeJournalDeleteData, handleDeleteJournalSave,
    }
) => {
    const hadleSimpanBtnClick = () => {
        handleDeleteJournalSave(journalDeleteData.id)
        return
    }

    return (
        <div>
            <Modal
                title={`Hapus Data Journal?`}
                open={journalDeleteData.hasOwnProperty('id')}
                onCancel={() => {
                    changeJournalDeleteData({});
                }}
                style={{ top: 20 }}
                bodyStyle={{
                    height: "100%",
                }}
                width={"800px"}
                footer={[
                    <Button
                        key={1}
                        onClick={() => {
                            changeJournalDeleteData({})
                        }}
                        loading={addJournalLoading}
                        disabled={addJournalLoading}
                    >
                        cancel
                    </Button>,
                    <Button
                        key={2} type='danger' onClick={hadleSimpanBtnClick}
                        loading={addJournalLoading}
                        disabled={addJournalLoading}
                    >
                        Porses Hapus
                    </Button>
                ]}
                disabled
                destroyOnClose
            >
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>No Bukti</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {journalDeleteData?.no_bukti}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>No Ref</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {journalDeleteData?.no_ref}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Tanggal</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {moment(journalDeleteData?.date).format('DD MMMM YYYY')}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Tanggal Input</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {moment(journalDeleteData?.created_at).format('DD MMMM YYYY')}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Deskripsi</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {journalDeleteData?.description}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Akun Sekarang</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {journalDeleteData?.account_group}  / {journalDeleteData?.account_name}
                        </span>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default App