import React, { useState, useEffect } from 'react';
import { Button, Card } from 'antd';

import Layout from "../../layout"

import BayarCepat from "./childComponents/BayarCepat";
import LastPayments from "./childComponents/LastPayments";
import RecapPayments from "./childComponents/RecapPayments";

const App = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleCloseBacarCepatModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    document.title = 'Home';
  }, []);

  return (
    <Layout>
      <BayarCepat modalOpen={modalOpen} closeBayarModal={handleCloseBacarCepatModal} />
      <Card title="Pembayaran Terahir">
        <Button onClick={() => setModalOpen(true)} type="primary" style={{ marginRight: "5px" }}>Tambah Pembayaran</Button>
        <LastPayments />
      </Card>
      <br />
      <RecapPayments />
    </Layout>
  );
};

export default App;
