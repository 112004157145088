import React from 'react'

import {
    Modal, Form, Input, Button, DatePicker
} from 'antd';

export default function App(props) {
    const {
        // actions
        changeModalAddHeir, handleSaveJamaahHeir,
        // data
        isModalAddHeirOpen, isHeirSaveLoading,
        jamaah,
    } = props

    return (
        <Modal
            title="Input Data Ahli Waris"
            open={isModalAddHeirOpen}
            onCancel={() => changeModalAddHeir()}
            width={600} footer={null}
        >
            <Form
                labelCol={{ span: 6 }}
                layout="horizontal"
                onFinish={(data) => handleSaveJamaahHeir(data, jamaah.id)}
            >
                <Form.Item label="Nama Ahli Waris" name={"name"}
                    rules={[{ required: true, min: 2 }]}
                >
                    <Input width={"10"} />
                </Form.Item>
                <Form.Item label="NIK" name={"nik"}
                    rules={[{ required: true, min: 16, max: 16 }, { pattern: new RegExp(/^[0-9]+$/), message: "Invalid NIK" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Tempat Lahir" name={"pob"}
                    rules={[{ required: true, min: 2, message:"Tempat Lahir is required"}]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Tanggal Lahir" name={"dob"} rules={[{ required: true, message:"Tanggal Lahir is required"}]} >
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Hubungan" name={"relationship_status"}
                    rules={[{ required: true, min: 3, message: "Hubungan is required" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Keterangan" name={"description"} >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label="No Hp/telp" name={"phone"} rules={[{ min: 2 }]} >
                    <Input />
                </Form.Item>
                <Form.Item label="Email" name={"email"} rules={[{ type: "email" }]} >
                    <Input />
                </Form.Item>
                <Form.Item label="Alamat" name={"address"} >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item>
                    <div>
                        <Button loading={isHeirSaveLoading} type="primary" style={{ marginRight: 2, left: 420 }} htmlType="submit">Simpan Data</Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
}
