import { Get, Delete, Put } from "../../../helpers/ajax";
import { BASE_API_URL, BASE_URL } from "../../../config";

import { notification } from 'antd';

const key = "PACKAGE_DETAIL"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_PACKAGE_DETAIL: `${key}_CHANGE_PACKAGE_DETAIL`,
    CHANGE_DELETE_PACKAGE_LOADING: `${key}_CHANGE_DELETE_PACKAGE_LOADING`,
    CHANGE_MODAL_DELETE_PACKAGE_OPEN: `${key}_CHANGE_MODAL_DELETE_PACKAGE_OPEN`,
    CHANGE_MODAL_UPDATE_PACKAGE_OPEN: `${key}_CHANGE_MODAL_UPDATE_PACKAGE_OPEN`,
    CHANGE_UPDATE_PACKAGE_LOADING: `${key}_CHANGE_UPDATE_PACKAGE_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changePackageDetail = (value) => ({
    type: type.CHANGE_PACKAGE_DETAIL,
    value
})

export const changeDeletePackageLoading = (value) => ({
    type: type.CHANGE_DELETE_PACKAGE_LOADING,
    value
})

export const changeModalDeletePackageOpen = (value) => ({
    type: type.CHANGE_MODAL_DELETE_PACKAGE_OPEN,
    value
})

export const changeModalUpdatePackageOpen = (value) => ({
    type: type.CHANGE_MODAL_UPDATE_PACKAGE_OPEN,
    value
})

export const changeUpdatePackageLoading = (value) => ({
    type: type.CHANGE_UPDATE_PACKAGE_LOADING,
    value
})


export const handleGetPackageDetail = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeLoading(true))
        const res = await Get(`${BASE_API_URL}/packages/${id}`);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changePackageDetail(res.data || {}))
        return
    }
}

export const handleDeletePackage = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeDeletePackageLoading(true))
        const res = await Delete(`${BASE_API_URL}/packages/${id}`);
        dispatch(changeDeletePackageLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        window.location.replace(`${BASE_URL}/packages/list`);
        return
    }
}

export const handleStorePackage = (data, id) => {
    return async (dispatch, getState) => {
        dispatch(changeUpdatePackageLoading(true))
        const res = await Put(`${BASE_API_URL}/packages/${id}`, data);
        dispatch(changeUpdatePackageLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeModalUpdatePackageOpen(false))
        dispatch(changePackageDetail(res.data || {}))
        return
    }
}

export default type