import React, { } from 'react'
import { Button, Modal, Form, Input, Spin, Select } from 'antd';

const App = (props) => {

    const {
        // data
        userUpdateData, updateLoading, roles,
        // actions
        handleUpdateUser, changeUserUpdateData,
    } = props

    return (
        <Modal
            title="Update Data User?"
            open={(userUpdateData.hasOwnProperty('id') ? true : false)}
            footer={null}
            layout="horizontal"
            style={{ top: 20 }}
            width={700}
            closable={false}
            destroyOnClose
        >
            <Spin spinning={updateLoading}>
                <Form
                    labelCol={{ span: 6 }}
                    layout="horizontal"
                    onFinish={(data) => handleUpdateUser(userUpdateData.id, data)}
                >
                    <Form.Item
                        label="Nama User"
                        name="name"
                        rules={[
                            { required: true, message: 'Nama User harus diisi!' },
                        ]}
                        initialValue={userUpdateData.name}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email User"
                        name="email"
                        rules={[
                            { required: true, message: 'Email User harus diisi!' },
                            { type: 'email', message: 'Format email tidak valid!' },
                        ]}
                        initialValue={userUpdateData.email}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="User Role"
                        name="role_id"
                        // rules={[{ required: true, message: 'Role User harus diisi!' },]}
                        initialValue={userUpdateData.role_id}
                    >
                        <Select
                            placeholder="Pilih Role"
                            style={{
                                width: 300,
                            }}
                            options={roles}
                        />
                    </Form.Item>

                    <Form.Item>
                        <div style={{ float: 'right' }}>
                            <Button type="default" style={{ marginRight: 2 }} onClick={() => changeUserUpdateData({})}>Cancel</Button>
                            <Button type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Data</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>

        </Modal>
    )
}

export default App;

