import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Breadcrumb, Layout, Menu } from 'antd';
import {
    LogoutOutlined, BankOutlined,
} from '@ant-design/icons';
import { superadmin, manager } from "../config_role";

import { items } from "./menuItems";
import { getAccessToken, removeToken } from "../library/userAuth";
import { BASE_URL } from '../config';

const { Header, Content, Sider } = Layout;

const App = (props) => {
    const { children } = props
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [paths, setPaths] = useState([]);
    const [role, setRole] = useState(null);

    const accessToken = getAccessToken();

    useEffect(() => {
        let paths = window.location.pathname.split('/').filter(function (value) {
            return value !== "";
        });
        setPaths(paths)

        if (!accessToken) {
            navigate("/login")
        }else{
            setRole(jwt_decode(accessToken).role_name)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogout = () => {
        removeToken()
        navigate("/")
    }

    let itemsAuthFiltered = []
    if (role) {
        if (role === superadmin) {
            itemsAuthFiltered = itemsAuthFiltered.concat(items.filter(element => element.auth === superadmin))
        }
        if (role === manager) itemsAuthFiltered = items.filter(element => element.auth === manager)

        itemsAuthFiltered = itemsAuthFiltered.concat(items.filter(element => element.auth === '*'))
    }

    itemsAuthFiltered = itemsAuthFiltered.sort((a, b) => a.index - b.index)

    if (!accessToken) {
        return <></>
    }

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
                    <div style={{
                        margin: 10,
                        width: '170px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center', // untuk vertikal align tengah
                        backgroundColor:"#fff"
                    }}>
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain', // atau 'cover' atau 'fill' tergantung kebutuhan
                            }}
                            src={`${BASE_URL}/logo.png`} alt="Logo"
                        />
                    </div>
                    <Menu
                        onSelect={() => navigate("/")}
                        theme="dark" mode="inline"
                        items={[{
                            label: "Home",
                            key: "/",
                            icon: <BankOutlined />,
                            children: ""
                        }]}
                    />
                    <Menu
                        theme="dark" defaultSelectedKeys={paths} mode="inline" items={itemsAuthFiltered}
                        onSelect={({ selectedKeys }) => navigate(`/${selectedKeys[0]}`)}
                    />
                    <Menu
                        onSelect={() => handleLogout()}
                        theme="dark" mode="inline"
                        items={[{
                            label: "Logout",
                            key: "logout",
                            icon: <LogoutOutlined />,
                            children: ""
                        }]}
                    />
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }} />
                    <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}>
                            {
                                paths.map((item, index) => {
                                    return <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                                })
                            }
                        </Breadcrumb>

                        <main >
                            {children}
                        </main>
                    </Content>
                </Layout>
            </Layout>
        </>

    );
};

export default App;