import { connect } from 'react-redux'
import App from './component'
import {
    handleFetchDataAccount,
    changePage, changeStatus, changeSorter,
    changeAccountUpdateData, handleUpdateAccount,
    changeAccountDeleteData, handleDeleteAccount,
    changeModalNewOpen, handleSaveAccount,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.accountList,
})

const mapDispatchToProps = {
    handleFetchDataAccount,
    changePage, changeStatus, changeSorter,
    changeAccountUpdateData, handleUpdateAccount,
    changeAccountDeleteData, handleDeleteAccount,
    changeModalNewOpen, handleSaveAccount,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)