import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_JAMAAH_DETAIL:
            return {
                ...state,
                jamaah: action.value
            }
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_HEIR_LOADING:
            return {
                ...state,
                isHeirLoading: action.value
            }
        case type.CHANGE_HEIR_SAVE_LOADING:
            return {
                ...state,
                isHeirSaveLoading: action.value
            }
        case type.CHANGE_JAMAAH_HEIR:
            return {
                ...state,
                jamaahHeir: action.value
            }
        case type.CHANGE_MODAL_ADD_HEIR:
            return {
                ...state,
                isModalAddHeirOpen: !state.isModalAddHeirOpen
            }
        case type.CHANGE_IMAGE_UPLOAD_LOADING:
            return {
                ...state,
                isImageUploadLoading: action.value
            }
        case type.CHANGE_MODAL_UPDATE_OPEN:
            return {
                ...state,
                modalUpdateOpen: action.value
            }
        case type.CHANGE_SAVE_UPDATE_LOADING:
            return {
                ...state,
                isSaveUpdateLoading: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    jamaah: {},

    isHeirLoading: false,
    jamaahHeir: {},
    isModalAddHeirOpen: false,
    isHeirSaveLoading: false,

    isImageUploadLoading: false,
    modalUpdateOpen:false,
    isSaveUpdateLoading: false,
})

export default main