import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_FETCH_LOADING:
            return {
                ...state,
                isFetchLoading: action.value
            }
        case type.CHANGE_KLOTER_COST:
            return {
                ...state,
                kloterCost: action.value
            }
        case type.CHANGE_MODAL_ADD_OPEN:
            return {
                ...state,
                modalAddOpen: action.value
            }
        case type.CHANGE_KLOTER_COST_UPDATE:
            return {
                ...state,
                kloterCostUpdate: action.value
            }
        case type.CHANGE_KLOTER_COST_DELETE:
            return {
                ...state,
                kloterCostDelete: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_KLOTER_COST_NOTE:
            return {
                ...state,
                kloterCostNote: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isFetchLoading: false,
    kloterCost: [],
    modalAddOpen: false,
    kloterCostUpdate: {},
    kloterCostDelete: {},
    kloterCostNote: null,
})

export default main