import React, { useState } from 'react';
import { Button, Modal, Form, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

function App(props) {
    const [fileList, setFileList] = useState([]);

    const {
        // data
        modalCompletingItemData, saveCompletingItemLoading,
        // actions
        changeModalCompletingItemData, habdleSaveCompletingItem,
    } = props;

    const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    const handleChange = ({ fileList }) => {
        Promise.all(
            fileList.map((file) => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onloadend = () => {
                        const base64Data = reader.result;
                        const newFile = { ...file, base64Data };
                        resolve(newFile);
                    };
                });
            })
        ).then((updatedFileList) => {
            // Filter the fileList to only include accepted file types
            // const filteredFileList = updatedFileList.filter((file) =>
            //     acceptedFileTypes.includes(file.type)
            // );
            setFileList(updatedFileList);
        });
    };

    const beforeUpload = (file) => {
        const isJpgOrPngOrPdf = acceptedFileTypes.includes(file.type);
        if (!isJpgOrPngOrPdf) {
            message.error('You can only upload PDF/JPG/PNG file!');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('Image must be smaller than 10MB!');
        }
        return isJpgOrPngOrPdf && isLt10M;
    };

    const propsUpload = {
        onChange: handleChange,
        maxCount: modalCompletingItemData?.supporting_files_number,
        multiple: true,
        action: '', // Remove the action property to prevent sending data to the server
        beforeUpload: beforeUpload,
        listType: "picture",
    };

    const isAllImageFiles = fileList.every((file) => acceptedFileTypes.includes(file.type));


    return (
        <>
            <Modal
                title={`Set Lengkap Item ${modalCompletingItemData?.name}?`}
                visible={modalCompletingItemData.hasOwnProperty('kloter_jamaah_id')}
                onCancel={() => {
                    setFileList([]);
                    changeModalCompletingItemData({});
                }}
                footer={null}
                disabled
                destroyOnClose
            >
                <Form
                    labelCol={{ span: 5 }}
                    layout="vertical"
                    onFinish={() => {
                        const base64DataArr = fileList.map((item) => item.base64Data);
                        habdleSaveCompletingItem(
                            modalCompletingItemData.kloter_jamaah_id,
                            modalCompletingItemData.item_id,
                            base64DataArr
                        );
                    }}
                >
                    {(modalCompletingItemData?.supporting_files_number > 0) && (
                        <Form.Item label="Foto" name={"berkas"}>
                            <Upload {...propsUpload}>
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                        </Form.Item>
                    )}
                    <Form.Item>
                        <div style={{ float: 'right' }}>
                            <Button
                                type="primary"
                                style={{ marginRight: 2 }}
                                htmlType="submit"
                                disabled={!isAllImageFiles || saveCompletingItemLoading || ((modalCompletingItemData?.supporting_files_number > 0 && fileList.length === 0) ? true : false)}
                                loading={saveCompletingItemLoading}
                            >
                                Proses
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default App;
