import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_JOURNAL_DATA_LOADING:
            return {
                ...state,
                fetchJournalLoading: action.value
            }
        case type.CHANGE_JOURNAL_DATA:
            return {
                ...state,
                journalData: action.value
            }
        case type.CHANGE_ACCOUNT_DATA:
            return {
                ...state,
                accountData: action.value
            }
        case type.CHANGE_MODAL_ADD_JOURNAL_OPEN:
            return {
                ...state,
                modalAddJournalOpen: action.value
            }
        case type.CHANGE_ADD_JOURNAL_LOADING:
            return {
                ...state,
                addJournalLoading: action.value
            }
        case type.CHANGE_FILTER_TYPE:
            return {
                ...state,
                filterType: action.value
            }
        case type.CHANGE_FILTER_NO_BUKTI:
            return {
                ...state,
                filterNoBukti: action.value
            }
        case type.CHANGE_FILTER_DESCRIPTION:
            return {
                ...state,
                filterDescription: action.value
            }
        case type.CHANGE_FILTER_NO_REF:
            return {
                ...state,
                filterNoRef: action.value
            }
        case type.CHANGE_FILTER_ACCOUNT:
            return {
                ...state,
                filterAccount: action.value
            }
        case type.CHANGE_FILTER_ACCOUNT_GROUP:
            return {
                ...state,
                filterAccountGroup: action.value
            }
        case type.CHANGE_PAGE:
            return {
                ...state,
                page: action.value
            }
        case type.CHANGE_SORTER:
            return {
                ...state,
                sorter: action.value
            }
        case type.CHANGE_JOURNAL_UPDATE_DATA:
            return {
                ...state,
                journalUpdateData: action.value
            }
        case type.CHANGE_JOURNAL_DELETE_DATA:
            return {
                ...state,
                journalDeleteData: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    fetchJournalLoading: false,
    journalData: {},
    accountData: [],

    modalAddJournalOpen: false,
    addJournalLoading: false,

    // pagination
    page: 1,
    perPage: 50,
    sorter: null,

    //filter
    filterType: null,
    filterNoBukti: null,
    filterDescription: null,
    filterNoRef: null,
    filterAccount: null,
    filterAccountGroup: null,

    //update data
    journalUpdateData: {},
    //hapus data
    journalDeleteData: {},
})

export default main