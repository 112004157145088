import { connect } from 'react-redux'
import App from './component'
import {
    handleFetchKloterEstimation, changeModalAddOpen, handleSaveKloterEstimation, changeKloterEstimationUpdate,
    handleUpdateKloterEstimation, changeKloterEstimationDelete, handleDeleteKloterEstimation, handleSaveNote, handleFetchNote, handleDeleteNote,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.kloterDetailKloterEstimation,
})

const mapDispatchToProps = {
    handleFetchKloterEstimation, changeModalAddOpen, handleSaveKloterEstimation, changeKloterEstimationUpdate,
    handleUpdateKloterEstimation, changeKloterEstimationDelete, handleDeleteKloterEstimation, handleSaveNote, handleFetchNote, handleDeleteNote,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)