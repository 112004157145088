import { connect } from 'react-redux'
import App from './component'
import {
    handleFetchData, handleFetchItemHead, changeModalVerificationData, verifyProcess,
    changeModalCompletingItemData, habdleSaveCompletingItem, handleFetchDataKloter, changeSelectedKloterId,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.kloterJamaahsItems,
})

const mapDispatchToProps = {
    handleFetchData, handleFetchItemHead, changeModalVerificationData, verifyProcess,
    changeModalCompletingItemData, habdleSaveCompletingItem, handleFetchDataKloter, changeSelectedKloterId,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)