import { Delete, Get, Post } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "KAS_LIST"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_KAS_DATA: `${key}_CHANGE_KAS_DATA`,

    CHANGE_ACCOUNT_DATA: `${key}_CHANGE_ACCOUNT_DATA`,

    CHANGE_MODAL_KAS_MASUK_OPEN: `${key}_CHANGE_MODAL_KAS_MASUK_OPEN`,
    CHANGE_SAVE_KAS_MASUK_LOADING: `${key}_CHANGE_SAVE_KAS_MASUK_LOADING`,

    CHANGE_MODAL_KAS_KELUAR_OPEN: `${key}_CHANGE_MODAL_KAS_KELUAR_OPEN`,
    CHANGE_SAVE_KAS_KELUAR_LOADING: `${key}_CHANGE_SAVE_KAS_KELUAR_LOADING`,

    CHANGE_PAGE: `${key}_CHANGE_PAGE`,

    CHANGE_TYPE: `${key}_CHANGE_TYPE`,
    CHANGE_SORTER: `${key}_CHANGE_SORTER`,

    CHANGE_FILTER_DESCRIPTION: `${key}_CHANGE_FILTER_DESCRIPTION`,
    CHANGE_FILTER_NO_BUKTI: `${key}_CHANGE_FILTER_NO_BUKTI`,
    CHANGE_FILTER_DATE: `${key}_CHANGE_FILTER_DATE`,

    CHANGE_KAS_DELETE_DATA: `${key}_CHANGE_KAS_DELETE_DATA`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeKasData = (value) => ({
    type: type.CHANGE_KAS_DATA,
    value
})

export const changeModalKasMasukOpen = (value) => ({
    type: type.CHANGE_MODAL_KAS_MASUK_OPEN,
    value
})

export const changeSaveKasMasukLoading = (value) => ({
    type: type.CHANGE_SAVE_KAS_MASUK_LOADING,
    value
})

export const changeModalKasKeluarOpen = (value) => ({
    type: type.CHANGE_MODAL_KAS_KELUAR_OPEN,
    value
})

export const changeSaveKasKeluarLoading = (value) => ({
    type: type.CHANGE_SAVE_KAS_KELUAR_LOADING,
    value
})

export const changeAccountData = (value) => ({
    type: type.CHANGE_ACCOUNT_DATA,
    value
})

export const changePage = (value) => ({
    type: type.CHANGE_PAGE,
    value
})

export const changeType = (value) => ({
    type: type.CHANGE_TYPE,
    value
})

export const changeSorter = (value) => ({
    type: type.CHANGE_SORTER,
    value
})

export const changeFilterDescription = (value) => ({
    type: type.CHANGE_FILTER_DESCRIPTION,
    value
})

export const changeFilterNoBukti = (value) => ({
    type: type.CHANGE_FILTER_NO_BUKTI,
    value
})

export const changeFilterDate = (value) => ({
    type: type.CHANGE_FILTER_DATE,
    value
})

export const changeKasDeleteData = (value) => ({
    type: type.CHANGE_KAS_DELETE_DATA,
    value
})

export const handleFetchDataAccounts = () => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/accounts?per_page=200`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        let accounts = []
        if (res.data) {
            res.data.forEach((val) => {
                let temp = {
                    title: `(${val.group}) ${val.name}`,
                    value: val.id,
                    label: `(${val.group}) ${val.name}`
                };
                accounts.push(temp)
            });
        }

        dispatch(changeAccountData(accounts))
        return
    }
}

export const handleSaveKasMasuk = (data) => {
    return async (dispatch, getState) => {
        dispatch(changeSaveKasMasukLoading(true))
        const res = await Post(`${BASE_API_URL}/cash/in`, data);
        dispatch(changeSaveKasMasukLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(handleFetchDataKas())
        dispatch(changeModalKasMasukOpen(false))
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })

    }
}

export const handleSaveKasKeluar = (data) => {
    return async (dispatch, getState) => {
        dispatch(changeSaveKasKeluarLoading(true))
        const res = await Post(`${BASE_API_URL}/cash/out`, data);
        dispatch(changeSaveKasKeluarLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }
        dispatch(handleFetchDataKas())
        dispatch(changeModalKasKeluarOpen(false))
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })

    }
}


export const handleFetchDataKas = () => {
    return async (dispatch, getState) => {

        const state = getState()
        const { page, perPage, type, filterDescription, filterNoBukti, filterDate } = state.kasList

        let filterTypeQ = (type) ? `&type=${type}` : ``
        let filterDescriptionQ = (filterDescription !== null) ? `&description=${filterDescription}` : ``
        let filterNoBuktiQ = (filterNoBukti !== null) ? `&no_bukti=${filterNoBukti}` : ``
        let filterDateQ = (filterDate !== null) ? `&start_date=${filterDate?.start_date}&end_date=${filterDate?.end_date}` : ``

        let url = `${BASE_API_URL}/cash?page=${page}&per_page=${perPage}${filterTypeQ}${filterDescriptionQ}${filterNoBuktiQ}${filterDateQ}`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeKasData(res || {}))
        return
    }
}

export const handleDeleteKasSave = (no_bukti) => {
    return async (dispatch, getState) => {
        dispatch(changeSaveKasMasukLoading(true))
        const res = await Delete(`${BASE_API_URL}/cash/delete/${no_bukti}`);
        dispatch(changeSaveKasMasukLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        await dispatch(changeKasDeleteData({}))
        await dispatch(handleFetchDataKas())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })
    }
}

export default type