import { applyMiddleware, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";


import { reducers as authLogin } from "./pages/AuthLogin";
import { reducers as authRegister } from "./pages/AuthRegister";

import { reducers as jamaahList } from "./pages/Jamaah/JamaahList";
import { reducers as jamaahNew } from "./pages/Jamaah/JamaahNew";
import { reducers as jamaahDetail } from "./pages/Jamaah/JamaahDetail";
import { reducers as tabJamaahDetailListKloter } from "./pages/Jamaah/JamaahDetail/childComponents/TabJamaahDetailListKloter";

import { reducers as branchList } from "./pages/Branch/BranchList";
import { reducers as branchNew } from "./pages/Branch/BranchNew";
import { reducers as branchDetail } from "./pages/Branch/BranchDetail";

import { reducers as packageNew } from "./pages/Package/PackageNew";
import { reducers as packageList } from "./pages/Package/PackageList";
import { reducers as packageDetail } from "./pages/Package/PackageDetail";

import { reducers as kloterDetail } from "./pages/Kloter/KloterDetail";
import { reducers as kloterDetailJamaahDetail } from "./pages/Kloter/KloterDetailJamaahDetail";
import { reducers as kloterDetailJamaahList } from "./pages/Kloter/KloterDetail/childComponets/KloterDetailJamaahList";
import { reducers as kloterDetailModalJamaahAddToKloter } from "./pages/Kloter/KloterDetail/childComponets/KloterDetailModalJamaahAddToKloter";
import { reducers as kloterDetailAdditionalItem } from "./pages/Kloter/KloterDetail/childComponets/AdditionalItem";
import { reducers as kloterDetailRAB } from "./pages/Kloter/KloterDetail/childComponets/RAB";
import { reducers as kloterDetailKloterEstimation } from "./pages/Kloter/KloterDetail/childComponets/KloterEstimation";
import { reducers as kloterList } from "./pages/Kloter/KloterList";
import { reducers as kloterNew } from "./pages/Kloter/KloterNew";

import { reducers as itemNew } from "./pages/Item/ItemNew";
import { reducers as itemDetail } from "./pages/Item/ItemDetail";
import { reducers as itemList } from "./pages/Item/ItemList";
import { reducers as kloterJamaahsItems } from "./pages/Item/KloterJamaahsItems";

import { reducers as paymentList } from "./pages/Payment/PaymentList";

import { reducers as kasList } from "./pages/Kas/KasList";
import { reducers as journalList } from "./pages/Journal/JournalList";
import { reducers as modalUploadBri } from "./pages/Journal/JournalList/childComponets/ModalUploadBRI";
import { reducers as accountList } from "./pages/Account/AccountList";

import { reducers as userList } from "./pages/Setting/UserList";

import { reducers as kloterRoomSetting } from "./pages/Manifest/KloterRoomSetting";


const combine = combineReducers({
  authLogin, authRegister,
  jamaahList, jamaahNew, jamaahDetail, tabJamaahDetailListKloter,
  branchList, branchNew, branchDetail,
  packageNew, packageList, packageDetail,
  kloterDetail, kloterDetailJamaahDetail, kloterList, kloterNew, kloterDetailJamaahList, kloterDetailModalJamaahAddToKloter, kloterDetailAdditionalItem, kloterDetailRAB,
  kloterDetailKloterEstimation,
  itemNew, itemDetail, itemList, kloterJamaahsItems,
  paymentList,
  kasList, journalList, modalUploadBri, accountList,
  userList,
  kloterRoomSetting,
});

const store = createStore(combine, applyMiddleware(thunk, logger));

export default store;
