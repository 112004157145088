import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import {
  Card, Table, Input, Space, Button
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import Layout from "../../../layout"
import './style.css';

const App = (props) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [tableKey, setTableKey] = useState(0);
  const searchInput = useRef(null);

  const {
    // data
    isLoading, jamaahData, page, perPage, sorter, filterKode, filterName, filterNIK,
    // actions
    handleFetchDataJamaah, changePage, changeSorter, changeFilterKode, changeFilterName, changeFilterNIK, changeFilterAgentName,
    reset,
  } = props

  useEffect(() => {
    handleFetchDataJamaah()
    document.title = 'Jamaah List';
  }, [handleFetchDataJamaah])

  const handleTableChange = (pagination, filters, sorter) => {
    changePage(pagination.current)

    changeFilterKode((filters?.kode) ? filters?.kode : null)
    changeFilterName((filters?.name) ? filters?.name : null)
    changeFilterNIK((filters?.nik) ? filters?.nik : null)
    changeFilterAgentName((filters?.agent_name) ? filters?.agent_name : null)

    // get sort colum and order
    if (sorter.hasOwnProperty("column")) {
      if (typeof sorter.order === "undefined") {
        changeSorter(null)
      } else {
        changeSorter({
          field: sorter.field,
          order: (sorter.order === "ascend") ? "asc" : "desc",
        })
      }
    }

    handleFetchDataJamaah()
    return
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const dataIndexValue = record[dataIndex];
      if (dataIndexValue && dataIndexValue.toString) {
        return dataIndexValue.toString().toLowerCase().includes(value.toLowerCase());
      }
      return false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columnsJamaah = [
    {
      title: `Kode Jamaah`,
      dataIndex: 'kode',
      width: "50px",
      ...getColumnSearchProps('kode'),
    },
    {
      title: 'Nama Jamaah',
      dataIndex: 'name',
      filterSearch: true,
      sorter: true,
      width: "250px",
      ...getColumnSearchProps('name'),
    },
    {
      title: `J/K`,
      dataIndex: 'gender',
      className: "gender",
      render: (text) => <>{(text === 'male') ? "Pria" : "Wanita"}</>,
    },
    {
      title: `NIK`,
      dataIndex: 'nik',
      ...getColumnSearchProps('nik'),
    },
    {
      title: 'Referal agen',
      dataIndex: 'agent_name',
      sorter: true,
      width: "250px",
      ...getColumnSearchProps('agent_name'),
    },
    {
      title: `Alamat`,
      dataIndex: 'address',
      render: (text, record) => {
        return(
          <>{record.address}{(record.kecamatan)&& ', '+record.kecamatan}{(record.kabupaten)&& ', '+record.kabupaten}</>
        )
      },
    },
    {
      title: `Action`,
      dataIndex: 'id',
      render: (text, record) => <Link to={`/jamaah/detail/${record.id}`}>detail</Link>,
    },
  ]

  const resetFilters = () => {
    const clearFilters = () => { };
    handleReset(clearFilters);
    reset() // reset redux
    setTableKey(tableKey => tableKey + 1);
    handleFetchDataJamaah()
  };

  return (
    <Layout>
      <Card>
        {`Ditemukan: ${jamaahData.total_data} data`}
        <Button
          onClick={resetFilters}
          size="small"
          style={{ marginLeft: "5px" }}
        >
          Reset Filter
        </Button> <br />
        {
          sorter !== null &&
          <>Sort By:{sorter.field}({sorter.order})&nbsp;</>
        }
        {
          filterKode !== null &&
          <>Kode:{filterKode}&nbsp;</>
        }
        {
          filterName !== null &&
          <>Nama:{filterName}&nbsp;</>
        }
        {
          filterNIK !== null &&
          <>NIK:{filterNIK}&nbsp;</>
        }

        <Table
          key={tableKey}
          style={{ marginTop: 5 }}
          onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
          columns={columnsJamaah}
          dataSource={jamaahData.data || []}
          loading={isLoading}
          rowKey={data => data.id}
          pagination={{
            simple: true,
            current: page,
            total: jamaahData.total_data,
            pageSize: perPage,
          }}
        />

      </Card>
    </Layout>
  );
};

export default App;