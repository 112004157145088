/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Divider, Button, Table, Skeleton, Descriptions, Tag, Modal, Space
} from 'antd';
import {
  DeleteOutlined, ExclamationCircleFilled,
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import moment from 'moment';

import { BASE_URL } from "../../../config";
import Layout from "../../../layout"
import { rupiahFormat } from "../../../helpers/currency"
import './style.css'
import ModalAddPayment from './childComponents/ModalAddPayment'
import ModalAddItem from './childComponents/ModalAddItem'
import ModalShowItem from './childComponents/ModalShowItem'
import ModalAddAdditionalItem from './childComponents/ModalAddAdditionalItem'
import ModalDeleteAdditionalItem from './childComponents/ModalDeleteAdditionalItem'
import TransferToKloter from './childComponents/TransferToKloter'

import ModalPrintInvoice from './childComponents/ModalPrintInvoice';

const App = (props) => {
  let { id } = useParams();
  const [paymentData, setPaymentData] = useState(null);
  const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false);
  const [modalTransferToKloterOpen, setModalTransferToKloterOpen] = useState(false);
  const { confirm } = Modal;

  const {
    // data
    isLoadingKloterJamaahData,
    isLoadingPaymentSumary,
    isLoadingPaymentHistory,
    items, fetchItemsLoading,
    kloterJamaahData, paymentSumary, paymentHistory, additionalItems, fetchAdditionalItemsLoading,

    // actions
    fetchQueue, changePaymentHistorySorter, handleGetKloterJamaahPaymentHistory, handleOpenModalAddPayment,
    changeModalItemData, changeModalItemDetail, habdleModalAddAddtionalItemOpen, changeAdditionalItemToDelete, handleDeleteKloterJamaah,
  } = props

  useEffect(() => {
    document.title = 'Jamaah Detail In Kloter';

    fetchQueue(id)
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    // get sort colum and order
    if (sorter.hasOwnProperty("column")) {
      if (typeof sorter.order === "undefined") {
        changePaymentHistorySorter(null)
      } else {
        changePaymentHistorySorter({
          field: sorter.field,
          order: (sorter.order === "ascend") ? "asc" : "desc",
        })
      }
    }

    handleGetKloterJamaahPaymentHistory(id)
    return
  }


  const dataDeleteConfirm = () => {
    confirm({
      title: 'Konfirmasi!',
      icon: <ExclamationCircleFilled />,
      content: `Apakah anda yakin menghapus data "${kloterJamaahData.jamaah_name}" dari kloter "${kloterJamaahData.kloter_name}"`,
      okText: 'Ya, lanjutkan',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        handleDeleteKloterJamaah(id, kloterJamaahData.kloter_id);
      },
    });
  };

  const columns = [
    {
      title: 'Tanggal Bayar',
      dataIndex: 'date',
      width: '30%',
      sorter: (a, b) => a.age - b.age,
      render: (text, record) => <>{moment(text).format('DD MMMM YYYY')}</>,
    },
    {
      title: 'Nominal',
      dataIndex: 'nominal',
      align: 'right',
      render: (text, record) => <>{rupiahFormat(text)}</>,
    },
    {
      title: 'Metode Bayar',
      dataIndex: 'payment_method',
    },
    {
      title: 'Telah diverivikasi',
      dataIndex: 'is_verified',
      align: 'center',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Tanggal Input',
      dataIndex: 'created_at',
      width: '30%',
      align: 'center',
      render: (text, record) => <>{moment(text).format('DD MMMM YYYY HH:mm:ss')}</>,
    },
    {
      title: 'Action',
      align: 'center',
      render: (_, record) => {
        return (
          <Link onClick={() => {
            setModalInvoiceOpen(true)
            setPaymentData(record)
          }}>Cetak</Link>
        )
      },
    },
  ];

  const closeModalTransferToKloter = () => {
    setModalTransferToKloterOpen(false)
    return
  }

  return (
    <Layout>
      <ModalAddPayment {...props} />
      <ModalAddItem {...props} />
      <ModalShowItem {...props} />
      <ModalAddAdditionalItem {...props} />
      <ModalDeleteAdditionalItem {...props} />
      <ModalPrintInvoice handleModalClose={() => setModalInvoiceOpen(false)} modalInvoiceOpen={modalInvoiceOpen} kloterJamaahData={kloterJamaahData} paymentData={paymentData} />
      <TransferToKloter
        jamaahId={kloterJamaahData.jamaah_id}
        currentKloterId={kloterJamaahData.kloter_id}
        isVisible={modalTransferToKloterOpen} closeModal={closeModalTransferToKloter}
      />

      <>
        {
          (isLoadingKloterJamaahData)
            ?
            <Card style={{ marginBottom: 20 }}><Skeleton active /></Card>
            :
            <>
              <Divider orientation="left" orientationMargin="0">
                <strong>Detail Jamaah Kloter</strong>
              </Divider>
              <Card>
                <Descriptions>
                  <Descriptions.Item label="Kloter"><strong><Link to={`/kloters/detail/${kloterJamaahData.kloter_id}`}>{kloterJamaahData.kloter_name}</Link></strong></Descriptions.Item>
                  <Descriptions.Item label="Tanggal Terbang"><strong>{moment(kloterJamaahData.flying_date).format('DD MMMM YYYY')}</strong></Descriptions.Item>
                  <Descriptions.Item label="Nama Jamaah"><strong><Link to={`/jamaah/detail/${kloterJamaahData.jamaah_id}`}>{kloterJamaahData.jamaah_name}</Link></strong></Descriptions.Item>
                  <Descriptions.Item label="NIK"><strong>{kloterJamaahData.jamaah_nik}</strong></Descriptions.Item>
                  <Descriptions.Item label="Satus">
                    <strong>{(kloterJamaahData.kloter_active) ? "Aktif" : "Non Aktif"}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Kode Jamaah"><strong>{kloterJamaahData?.jamaah_kode}</strong></Descriptions.Item>
                </Descriptions>
                <>
                  <Space style={{ marginRight: 5 }}>
                    <Button type='primary'
                      onClick={() => {
                        const newTab = window.open(`${BASE_URL}/kloters-jamaahs/detail/${id}/print`, '_blank');
                        newTab.focus();
                      }}
                    >
                      Print Pendaftaran
                    </Button>
                  </Space>
                  <Space style={{ marginRight: 5 }}>
                    <Button onClick={() => setModalTransferToKloterOpen(true)}>Pindah Kloter</Button>
                  </Space>

                  <Space>
                    <Button type='danger' onClick={dataDeleteConfirm}>Batalkan Pendaftaran</Button>
                  </Space>
                </>
              </Card>
            </>
        }
      </>
      <>
        {
          (fetchItemsLoading)
            ?
            <Card style={{ marginBottom: 20, marginTop: 20 }}><Skeleton active /></Card>
            :
            <>
              <Divider orientation="left" orientationMargin="0">
                <strong>Kelengkapan Berkas</strong>
              </Divider>
              <Card style={{ marginBottom: 20 }}>
                {items.map((item) => {
                  if (item.is_completed) {
                    return <Tag color={"success"} key={item?.id} onClick={() => changeModalItemDetail(item)} style={{ cursor: "pointer" }}>{item.name}</Tag>
                  }
                  return <Tag color={"default"} key={item?.id} onClick={() => changeModalItemData(item)} style={{ cursor: "pointer" }}>{item.name}</Tag>
                })}
              </Card>
            </>
        }
      </>
      <>
        {
          (fetchAdditionalItemsLoading)
            ?
            <Card style={{ marginBottom: 20, marginTop: 20 }}><Skeleton active /></Card>
            :
            <>
              <Divider orientation="left" orientationMargin="0">
                <strong>Biaya Tambahan</strong>
              </Divider>
              <Card style={{ marginBottom: 5 }}>
                {
                  (additionalItems.length > 0)
                    ?
                    <>
                      <table>
                        <tbody>
                          {
                            additionalItems.map((val) => {
                              return (
                                <tr className='tr-detail' key={val.id}>
                                  <td align='left' className='th-detail'><strong>{val.name}</strong></td>
                                  <td align='right' className='nominal' width={10}>{rupiahFormat(val.cost)}</td>
                                  <td >
                                    <Link onClick={() => changeAdditionalItemToDelete(val)}><DeleteOutlined /></Link>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </>
                    :
                    <p>No Data</p>
                }
                <Button
                  type="primary"
                  onClick={() => habdleModalAddAddtionalItemOpen(id)}
                >
                  Tambah Data
                </Button>
              </Card>
            </>
        }
      </>
      <>
        {
          (isLoadingPaymentSumary)
            ?
            <Card style={{ marginBottom: 20 }}><Skeleton active /></Card>
            :
            <>
              <Divider orientation="left" orientationMargin="0">
                <strong>Riwayat Pembayaran</strong>
              </Divider>
              <Card style={{ marginBottom: 5 }}>
                <table>
                  <tbody>
                    <tr className='tr-detail'>
                      <th align='left' className='th-detail'>Biaya Pokok</th>
                      <td align='right' className='nominal'>{rupiahFormat(paymentSumary.kloter_cost)}</td>
                    </tr>
                    <tr className='tr-detail'>
                      <th align='left' className='th-detail'>Biaya Tambahan</th>
                      <td align='right' className='nominal'>{rupiahFormat(paymentSumary.additional_item_cost)}</td>
                    </tr>
                    <tr className='tr-detail'>
                      <th align='left' className='th-detail'>Biaya Total</th>
                      <td align='right' className='nominal'>
                        <strong>{rupiahFormat(paymentSumary.additional_item_cost + paymentSumary.kloter_cost)}</strong>
                      </td>
                    </tr>
                    <tr className='tr-detail'>
                      <th align='left' className='th-detail'>Telah Diverifikasi</th>
                      <td align='right' className='nominal'>{rupiahFormat(paymentSumary.verified_paid)}</td>
                    </tr>
                    <tr className='tr-detail'>
                      <th align='left' className='th-detail'>Belum Diverifikasi</th>
                      <td align='right' className='nominal'>{rupiahFormat(paymentSumary.unverified_paid)}</td>
                    </tr>
                    <tr className='tr-detail'>
                      <th align='left' className='th-detail'>Kekurangan Pembayaran</th>
                      <td align='right' className='nominal'>{rupiahFormat(paymentSumary.shortfall)}</td>
                    </tr>
                  </tbody>
                </table>
                <Button
                  type="primary" onClick={() => handleOpenModalAddPayment()}
                >
                  Tambah Pembayaran
                </Button>
              </Card>
            </>
        }
      </>
      <Table
        rowKey='id'
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
        columns={columns} dataSource={paymentHistory} pagination={false}
        loading={isLoadingPaymentHistory}
      />
    </Layout>
  );
};

export default App;