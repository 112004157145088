import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Card, Table
} from 'antd';

import Layout from "../../../layout"
import { rupiahFormat } from "../../../helpers/currency"

const App = (props) => {
  const {
    // data
    packageData, isLoading,
    // actions
    handleFetchDataPackage,
  } = props

  useEffect(() => {
    handleFetchDataPackage()
  }, [handleFetchDataPackage])

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      align:'left',
      render: (text, record) => <Link to={`/packages/detail/${record.id}`}>{text}</Link>,
      // filterSearch: true,
      // sorter: true,
    },
    {
      title: 'Kode',
      dataIndex: 'kode',
      align:'center',
      // filterSearch: true,
      // sorter: true,
    },
    {
      title: 'Estimasi Biaya',
      dataIndex: 'estimated_cost',
      render: (text) => <>{rupiahFormat(text)}</>,
      align:'right'
      // filterSearch: true,
      // sorter: true,
    },
  ]

  return (
    <Layout>
      <Card>
        <Table
          style={{ marginTop: 5 }}
          columns={columns}
          dataSource={packageData.data || []}
          loading={isLoading}
          rowKey={packages => packages.id}
          pagination={false}
        />
      </Card>

    </Layout>
  );
};

export default App;