import { PostFiles } from "../../../../../helpers/ajax";
import { BASE_API_URL } from "../../../../../config";

import { notification } from 'antd';

import { handleFetchJournalData } from "../../actions";

const key = "MODAL_UPLOAD_BRI"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_SAVE_LOADING: `${key}_CHANGE_SAVE_LOADING`,
    CHANGE_MODAL_OPEN: `${key}_CHANGE_MODAL_OPEN`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeSaveLoading = (value) => ({
    type: type.CHANGE_SAVE_LOADING,
    value
})

export const changeModalOpen = (value) => ({
    type: type.CHANGE_MODAL_OPEN,
    value
})

export const handleUploadProcess = (data) => {
    return async (dispatch, getState) => {
        dispatch(changeSaveLoading(true))
        const res = await PostFiles(`${BASE_API_URL}/cash/excel-upload`, data);
        dispatch(changeSaveLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== 'ok') {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        await dispatch(changeModalOpen(false))
        await dispatch(handleFetchJournalData())
        return
    }
}

export default type