import { connect } from 'react-redux'
import App from './component'
import {
    handleFetchDataJamaah, changePage, changePerPage, changeSorter,
    changeFilterKode, changeFilterName, changeFilterNIK,
} from './actions'

import { openModal } from "../KloterDetailModalJamaahAddToKloter/actions";


const mapStateToProps = (state) => ({
    ...state.kloterDetailJamaahList,
})

const mapDispatchToProps = {
    handleFetchDataJamaah, changePage, changePerPage, changeSorter,
    changeFilterKode, changeFilterName, changeFilterNIK,
    openModal,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)