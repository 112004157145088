import jwt_decode from "jwt-decode";
import { manager, superadmin } from "../config_role";


export const getAccessToken = () => {
    return localStorage.getItem("accessToken")
}

export const getRefreshToken = () => {
    return localStorage.getItem("refreshToken")
}

export const removeToken = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
}

export const isManager = () => {    
    const accessToken = getAccessToken();
    const allowedRoles = [manager, superadmin];

    const decodedToken = jwt_decode(accessToken);
    const role_name = decodedToken.role_name;
    

    return allowedRoles.includes(role_name);
};