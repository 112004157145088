import React, { useRef } from "react";
import { Modal, Button } from "antd";
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import jwt_decode from "jwt-decode";

import { getAccessToken } from "../../../../library/userAuth";

import { rupiahFormat } from "../../../../helpers/currency"
import { bilanganIndonesia } from "../../../../helpers/pembilangAngka"

import PrintHeader from '../../../../components/PrintHeader'

import './print.css';

const accessToken = getAccessToken();

class ComponentToPrintInvoice extends React.PureComponent {
    render() {
        const {
            jamaah_kode, jamaah_name, jamaah_nik,
            kloter_name, flying_date, nominal, description,
        } = this.props.kloterJamaahData

        return (
            <>
                <div
                    ref={this.props.componentRef}  // Use the ref directly on the div element
                    style={{
                        fontFamily: 'Raleway Dots, sans-serif',
                        marginLeft: "50px",
                        marginRight: "50px",
                    }}
                    className='print'
                >
                    <PrintHeader />
                    <strong style={{ textAlign: "center" }}>
                        <h3>
                            <strong>NOTA PEMBAYARAN</strong>
                        </h3>
                    </strong>
                    <p><strong>ID Transaksi: #{this.props.paymentData.id}</strong></p>

                    <strong>Telah menerima pembayaran dari:</strong>
                    <table style={{
                        textAlign: "left",
                        fontFamily: 'Raleway Dots, sans-serif',
                    }}>
                        <tbody
                            style={{
                                verticalAlign: "top"
                            }}
                        >
                            <tr>
                                <th>Nama Calon Jamaah</th>
                                <th>{jamaah_name}</th>
                            </tr>
                            <tr>
                                <th>Kode Jamaah</th>
                                <th>{jamaah_kode}</th>
                            </tr>
                            <tr>
                                <th>NIK Jamaah</th>
                                <th>{jamaah_nik}</th>
                            </tr>
                            <tr>
                                <th>Kloter / Estimasi Tanggal Terbang</th>
                                <th>{kloter_name} / {moment(flying_date).format('DD MMMM YYYY')}</th>
                            </tr>
                            <tr>
                                <th>Tanggal Pembayaran</th>
                                <th>{moment(this.props.paymentData.date).format('DD MMMM YYYY')}</th>
                            </tr>
                            <tr>
                                <th>Nominal</th>
                                <th>
                                    {
                                        (nominal)
                                            ?
                                            <strong>{rupiahFormat(nominal)} <i>({bilanganIndonesia(nominal)} Rupiah)</i></strong>
                                            :
                                            <strong>{rupiahFormat(this.props.paymentData.nominal)} <i>({bilanganIndonesia(this.props.paymentData.nominal)} Rupiah)</i></strong>
                                    }
                                </th>
                            </tr>
                            <tr>
                                <th>Keterangan</th>
                                <th>
                                    {
                                        (description)
                                            ?
                                            <>{description}</>
                                            :
                                            <>{(this.props.paymentData?.description) ?? "--"}</>
                                    }

                                </th>
                            </tr>
                        </tbody>
                    </table>

                    <div style={{ textAlign: "right" }}>
                        <strong>dicetak tanggal: {moment().format('DD MMMM YYYY HH:mm:ss')} - user id: {jwt_decode(accessToken).user_id}</strong>
                    </div>
                    <div style={{ width: "100%" }}>
                        <table
                            style={{
                                textAlign: "left",
                                fontFamily: 'Raleway Dots, sans-serif',
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th style={{ textAlign: "center", width: "50%" }}>
                                        <>
                                            Calon Jamaah
                                            <br />
                                            <br />
                                            <br />
                                            {jamaah_name}
                                        </>
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        <>
                                            Petugas Penerima
                                            <br />
                                            <br />
                                            <br />
                                            Admin - {jwt_decode(accessToken).name} <br />
                                        </>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

const App = ({
    handleModalClose, kloterJamaahData,
    paymentData, modalInvoiceOpen
}) => {
    const componentRefCetakBuktiPembayaran = useRef(null);  // Use the useRef hook

    return (
        <>
            <Modal
                title={"Cetak Bukti Pembayaran"}
                open={modalInvoiceOpen}
                onCancel={() => handleModalClose()}
                disabled
                destroyOnClose
                footer={[
                    <ReactToPrint
                        key="print-button" // Add a unique key prop
                        trigger={() => {
                            return <Button type='primary'>Print Pembayaran</Button>;
                        }}
                        content={() => componentRefCetakBuktiPembayaran.current}
                    />
                ]}
                width={1000}
            >
                <ComponentToPrintInvoice componentRef={componentRefCetakBuktiPembayaran} kloterJamaahData={kloterJamaahData} paymentData={paymentData} />
            </Modal>
        </>
    );
}

export default App;
