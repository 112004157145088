import { connect } from 'react-redux'
import App from './component'
import {
    handleFetchDataJamaah,
    changeModalJamaahAddToKloterOpen, closeModal, changeSelectedNik, changeSelectedJamaah,
    handleSaveJamaahsToKloter,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.kloterDetailModalJamaahAddToKloter,
})

const mapDispatchToProps = {
    handleFetchDataJamaah,
    changeModalJamaahAddToKloterOpen, closeModal, changeSelectedNik, changeSelectedJamaah,
    handleSaveJamaahsToKloter,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)