import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_BRANCH_DETAIL:
            return {
                ...state,
                branch: action.value
            }
        case type.CHANGE_BRANCH_OWNER:
            return {
                ...state,
                branchOwner: action.value
            }
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_MODAL_CONFIRM_OPEN:
            return {
                ...state,
                isModalConfirmOpen: action.value
            }
        case type.CHANGE_MODAL_CONFIRM_LOADING:
            return {
                ...state,
                isModalConfirmLoading: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    branch:{},
    branchOwner:{},
    isModalConfirmOpen:false,
    isModalConfirmLoading:false
})

export default main