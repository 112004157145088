import { Delete, Get, Post, Put } from "../../../../../helpers/ajax";
import { BASE_API_URL } from "../../../../../config";

import { notification } from 'antd';

const key = "KLOTER_DETAIL_KLOTER_ESTIMATION"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_FETCH_LOADING: `${key}_CHANGE_FETCH_LOADING`,
    CHANGE_KLOTER_ESTIMATION: `${key}_CHANGE_KLOTER_ESTIMATION`,
    CHANGE_KLOTER_NOTE: `${key}_CHANGE_KLOTER_NOTE`,
    CHANGE_MODAL_ADD_OPEN: `${key}_CHANGE_MODAL_ADD_OPEN`,
    CHANGE_KLOTER_ESTIMATION_UPDATE: `${key}_CHANGE_KLOTER_ESTIMATION_UPDATE`,
    CHANGE_KLOTER_ESTIMATION_DELETE: `${key}_CHANGE_KLOTER_ESTIMATION_DELETE`,
    CHANGE_KLOTER_ESTIMATION_NOTE: `${key}_CHANGE_KLOTER_ESTIMATION_NOTE`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeFetchLoading = (value) => ({
    type: type.CHANGE_FETCH_LOADING,
    value
})

export const changeKloterEstimation = (value) => ({
    type: type.CHANGE_KLOTER_ESTIMATION,
    value
})

export const changeKloterNote = (value) => ({
    type: type.CHANGE_KLOTER_NOTE,
    value
})

export const changeModalAddOpen = (value) => ({
    type: type.CHANGE_MODAL_ADD_OPEN,
    value
})

export const changeKloterEstimationUpdate = (value) => ({
    type: type.CHANGE_KLOTER_ESTIMATION_UPDATE,
    value
})

export const changeKloterEstimationDelete = (value) => ({
    type: type.CHANGE_KLOTER_ESTIMATION_DELETE,
    value
})

export const changeKloterEstimationNote = (value) => ({
    type: type.CHANGE_KLOTER_ESTIMATION_NOTE,
    value
})

export const handleFetchKloterEstimation = (kloterId) => {
    return async (dispatch, getState) => {
        dispatch(changeFetchLoading(true))
        const res = await Get(`${BASE_API_URL}/kloter-estiomations/${kloterId}`);
        dispatch(changeFetchLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeKloterEstimation(res?.data || []))
        return
    }
}

export const handleSaveKloterEstimation = (kloterId, data) => {
    return async (dispatch, getState) => {
        const res = await Post(`${BASE_API_URL}/kloter-estiomations/${kloterId}`, data);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeModalAddOpen(false))
        await dispatch(handleFetchKloterEstimation(kloterId))
        return
    }
}

export const handleUpdateKloterEstimation = (kloterId, id, data) => {
    return async (dispatch, getState) => {
        const res = await Put(`${BASE_API_URL}/kloter-estiomations/${id}`, data);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeKloterEstimationUpdate({}))
        await dispatch(handleFetchKloterEstimation(kloterId))
        return
    }
}

export const handleDeleteKloterEstimation = (kloterId, id) => {
    return async (dispatch, getState) => {
        const res = await Delete(`${BASE_API_URL}/kloter-estiomations/${id}`);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeKloterEstimationDelete({}))
        await dispatch(handleFetchKloterEstimation(kloterId))
        return
    }
}

export const handleFetchNote = (kloterId) => {
    return async (dispatch, getState) => {
        const res = await Get(`${BASE_API_URL}/kloter-estiomation-notes/${kloterId}`);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeKloterNote(res?.data || null))
        return
    }
}

export const handleSaveNote = (kloterId, data) => {
    return async (dispatch, getState) => {
        dispatch(changeFetchLoading(true))
        const res = await Post(`${BASE_API_URL}/kloter-estiomation-notes/${kloterId}`, data);
        dispatch(changeFetchLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(handleFetchNote(kloterId))
        return
    }
}

export const handleDeleteNote = (kloterId) => {
    return async (dispatch, getState) => {
        dispatch(changeFetchLoading(true))
        const res = await Delete(`${BASE_API_URL}/kloter-estiomation-notes/${kloterId}`);
        dispatch(changeFetchLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeKloterNote(null))
        return
    }
}

export default type