import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_PACKAGE_DETAIL:
            return {
                ...state,
                packageData: action.value
            }
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_DELETE_PACKAGE_LOADING:
            return {
                ...state,
                isDeletePackageLoading: action.value
            }
        case type.CHANGE_MODAL_DELETE_PACKAGE_OPEN:
            return {
                ...state,
                isModalDeletePackageOpen: action.value
            }
        case type.CHANGE_MODAL_UPDATE_PACKAGE_OPEN:
            return {
                ...state,
                isModalUpdatePackageOpen: action.value
            }
        case type.CHANGE_UPDATE_PACKAGE_LOADING:
            return {
                ...state,
                isUpdatePackageLoading: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    packageData: {},

    isDeletePackageLoading: false,
    isModalDeletePackageOpen: false,

    isModalUpdatePackageOpen: false,
    isUpdatePackageLoading: false,
})

export default main