import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { Card, Table, Button, Modal, Form, Input, InputNumber, Divider } from 'antd';
import {
  EditOutlined, DeleteOutlined,
} from '@ant-design/icons';

import { rupiahFormat } from "../../../../../helpers/currency";

const Textarea = Input.TextArea

const App = (props) => {
  let { id: kloterId } = useParams();
  const [note, setNote] = React.useState('')
  const {
    // data
    isFetchLoading, kloterCost, modalAddOpen, kloterCostUpdate, kloterCostDelete, kloterCostNote,
    // actions
    handleFetchKloterCost, changeModalAddOpen, handleSaveKloterCost, changeKloterCostUpdate,
    handleUpdateKloterCost, changeKloterCostDelete, handleDeleteKloterCost, handleAddKloterCostNote, handleFetchKloterCostNote,
    handleDeleteKloterCostNote,
  } = props

  React.useEffect(() => {
    handleFetchKloterCostNote(kloterId)
  }, [handleFetchKloterCostNote, kloterId])

  const handleChangeNote = (e) => {
    setNote(e.target.value);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Biaya',
      dataIndex: 'cost',
      align: 'right',
      render: (text) => rupiahFormat(text),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Link onClick={() => changeKloterCostUpdate(record)}><EditOutlined /></Link> |
            <Link onClick={() => changeKloterCostDelete(record)}><DeleteOutlined /></Link>
          </>
        )
      },
    },
  ];

  React.useEffect(() => {
    handleFetchKloterCost(kloterId)
  }, [handleFetchKloterCost, kloterId]);

  return (
    <>
      <Card>
        <div style={{ marginBottom: "15px" }}>
          <Button
            type='primary' style={{ float: "right" }}
            onClick={() => changeModalAddOpen(true)}
            disabled={false}
          >
            +Tambah Data
          </Button>
        </div>
        <Table
          style={{ marginTop: 5 }}
          columns={columns}
          dataSource={kloterCost?.kloter_cost}
          loading={isFetchLoading}
          rowKey={data => data.id}
          pagination={false}
        />

        <h1><strong>Total: {rupiahFormat(kloterCost?.sum_cost)}</strong></h1>
        <Divider orientation="left">Catatan</Divider>
        {
          (kloterCostNote)
            ?
            <span>
              <p>{kloterCostNote.note}</p>
              <p>
                <Link onClick={() => {
                  handleDeleteKloterCostNote(kloterId)
                  setNote('')
                }}><DeleteOutlined />Hapus catatan?</Link>
              </p>
            </span>
            :
            <>
              <Input.TextArea
                value={note}
                onChange={(e) => handleChangeNote(e)}
              />
              <Button size='small'
                type='primary' style={{ float: "right" }}
                onClick={() => handleAddKloterCostNote(kloterId, note)}
                disabled={isFetchLoading}
                loading={isFetchLoading}
              >
                +Tambah Catatan
              </Button>
            </>
        }
      </Card>

      <Modal
        title="Tambah Data?"
        open={modalAddOpen}
        footer={null}
        onCancel={() => changeModalAddOpen(false)}
        width={800}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={(data) => handleSaveKloterCost(kloterId, data)}
        >
          <Form.Item label="Nama Item" name={"name"} rules={[{ required: true, min: 2, max: 50 }]}>
            <Textarea min={2} max={50} />
          </Form.Item>
          <Form.Item label="Biaya" name={"cost"} rules={[{ required: true }]}>
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              style={{ width: 200 }} addonBefore="Rp"
              decimalSeparator={false}
              size="large"
            />
          </Form.Item>
          <div style={{ float: 'right' }}>
            <Button type="primary" style={{ top: -20 }} htmlType="submit">Simpan Data</Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Update Data?"
        open={(kloterCostUpdate.hasOwnProperty('name')) ? true : false}
        footer={null}
        onCancel={() => changeKloterCostUpdate({})}
        width={800}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={(data) => handleUpdateKloterCost(kloterId, kloterCostUpdate.id, data)}
        >
          <Form.Item label="Nama Item" name={"name"} rules={[{ required: true, min: 2, max: 50 }]} initialValue={kloterCostUpdate?.name}>
            <Textarea min={2} max={50} />
          </Form.Item>
          <Form.Item label="Biaya" name={"cost"} rules={[{ required: true }]}>
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              style={{ width: 200 }} addonBefore="Rp"
              decimalSeparator={false}
              size="large"
            />
          </Form.Item>
          <div style={{ float: 'right' }}>
            <Button type="primary" style={{ top: -20 }} htmlType="submit">Simpan Data</Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Apakah anda yakin Menghapus item ini?"
        open={(kloterCostDelete.hasOwnProperty('name')) ? true : false}
        footer={[
          <Button key="ok" type='danger' onClick={() => handleDeleteKloterCost(kloterId, kloterCostDelete.id)}>
            Konfirmasi
          </Button>
        ]}
        destroyOnClose
        onCancel={() => changeKloterCostDelete({})}
      >
        <p><strong>Nama Item:</strong> {kloterCostDelete.name}</p>
        <p><strong>Biaya:</strong> {rupiahFormat(kloterCostDelete?.cost)}</p>
      </Modal>
    </>
  )
}

export default App;

