import { Delete, Get, Post } from "../../../helpers/ajax";
import { BASE_API_URL, BASE_URL } from "../../../config";
import { notification } from 'antd';

const key = "KLOTER_DETAIL_JAMAAH_DETAIL"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING_KLOTER_JAMAAH_DATA: `${key}_CHANGE_LOADING_KLOTER_JAMAAH_DATA`,
    CHANGE_KLOTER_JAMAAH_DATA: `${key}_CHANGE_KLOTER_JAMAAH_DATA`,
    CHANGE_LOADING_PAYMENT_SUMARY: `${key}_CHANGE_LOADING_PAYMENT_SUMARY`,
    CHANGE_PAYMENT_SUMARY: `${key}_CHANGE_PAYMENT_SUMARY`,
    CHANGE_LOADING_PAYMENT_HISTORY: `${key}_CHANGE_LOADING_PAYMENT_HISTORY`,
    CHANGE_PAYMENT_HISTORY: `${key}_CHANGE_PAYMENT_HISTORY`,
    CHANGE_PAYMENT_HISTORY_SORTER: `${key}_CHANGE_PAYMENT_HISTORY_SORTER`,
    CHANGE_MODAL_ADD_PAYMENT_SHOW: `${key}_CHANGE_MODAL_ADD_PAYMENT_SHOW`,
    CHANGE_PAYMENT_METHODS_DATA: `${key}_CHANGE_PAYMENT_METHODS_DATA`,
    CHANGE_PAYMENT_METHODS_LOADING: `${key}_CHANGE_PAYMENT_METHODS_LOADING`,
    CHANGE_LOADING_SAVE: `${key}_CHANGE_LOADING_SAVE`,
    CHANGE_ITEMS: `${key}_CHANGE_ITEMS`,
    CHANGE_FETCH_ITEMS_LOADING: `${key}_CHANGE_FETCH_ITEMS_LOADING`,
    CHANGE_MODAL_ITEM_DATA: `${key}_CHANGE_MODAL_ITEM_DATA`,
    CHANGE_SAVE_ITEM_LOADING: `${key}_CHANGE_SAVE_ITEM_LOADING`,
    CHANGE_MODAL_ITEM_DETAIL: `${key}_CHANGE_MODAL_ITEM_DETAIL`,
    CHANGE_ADDITIONAL_ITEMS: `${key}_CHANGE_ADDITIONAL_ITEMS`,
    CHANGE_FETCH_ADDITIONAL_ITEMS_LOADING: `${key}_CHANGE_FETCH_ADDITIONAL_ITEMS_LOADING`,
    CHANGE_MODAL_ADD_ADDITIONAL_ITEM: `${key}_CHANGE_MODAL_ADD_ADDITIONAL_ITEM`,
    CHANGE_ADDITIONAL_ITEMS_CANDIDATE: `${key}_CHANGE_ADDITIONAL_ITEMS_CANDIDATE`,
    CHANGE_FETCH_ADDITIONAL_ITEMS_CANDIDATE_LOADING: `${key}_CHANGE_FETCH_ADDITIONAL_ITEMS_CANDIDATE_LOADING`,
    CHANGE_ADDITIONAL_ITEM_TO_DELETE: `${key}_CHANGE_ADDITIONAL_ITEM_TO_DELETE`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoadingKloterJamaahData = (value) => ({
    type: type.CHANGE_LOADING_KLOTER_JAMAAH_DATA,
    value
})

export const changeKloterJamaahData = (value) => ({
    type: type.CHANGE_KLOTER_JAMAAH_DATA,
    value
})

export const changeLoadingPaymentSumary = (value) => ({
    type: type.CHANGE_LOADING_PAYMENT_SUMARY,
    value
})

export const changePaymentSumary = (value) => ({
    type: type.CHANGE_PAYMENT_SUMARY,
    value
})

export const changeLoadingPaymentHistory = (value) => ({
    type: type.CHANGE_LOADING_PAYMENT_HISTORY,
    value
})

export const changePaymentHistory = (value) => ({
    type: type.CHANGE_PAYMENT_HISTORY,
    value
})

export const changePaymentHistorySorter = (value) => ({
    type: type.CHANGE_PAYMENT_HISTORY_SORTER,
    value
})

export const changeModalAddPaymentShow = (value) => ({
    type: type.CHANGE_MODAL_ADD_PAYMENT_SHOW,
    value
})

export const changePaymentMethodsData = (value) => ({
    type: type.CHANGE_PAYMENT_METHODS_DATA,
    value
})

export const changePaymentMethodsLoading = (value) => ({
    type: type.CHANGE_PAYMENT_METHODS_LOADING,
    value
})

export const changeLoadingSave = (value) => ({
    type: type.CHANGE_LOADING_SAVE,
    value
})

export const changeItems = (value) => ({
    type: type.CHANGE_ITEMS,
    value
})

export const changeFetchItemsLoading = (value) => ({
    type: type.CHANGE_FETCH_ITEMS_LOADING,
    value
})

export const changeModalItemData = (value) => ({
    type: type.CHANGE_MODAL_ITEM_DATA,
    value
})

export const changeSaveItemLoading = (value) => ({
    type: type.CHANGE_SAVE_ITEM_LOADING,
    value
})

export const changeModalItemDetail = (value) => {
    return ({
        type: type.CHANGE_MODAL_ITEM_DETAIL,
        value
    })
}

export const changeAdditionalItems = (value) => {
    return ({
        type: type.CHANGE_ADDITIONAL_ITEMS,
        value
    })
}

export const changeFetchAdditionalItemsLoading = (value) => {
    return ({
        type: type.CHANGE_FETCH_ADDITIONAL_ITEMS_LOADING,
        value
    })
}

export const changeModalAddAddtionalItem = (value) => {
    return ({
        type: type.CHANGE_MODAL_ADD_ADDITIONAL_ITEM,
        value
    })
}

export const changeAdditionalItemsCandidate = (value) => {
    return ({
        type: type.CHANGE_ADDITIONAL_ITEMS_CANDIDATE,
        value
    })
}

export const changeAdditionalItemsCandidateLoading = (value) => {
    return ({
        type: type.CHANGE_FETCH_ADDITIONAL_ITEMS_CANDIDATE_LOADING,
        value
    })
}

export const changeAdditionalItemToDelete = (value) => {
    return ({
        type: type.CHANGE_ADDITIONAL_ITEM_TO_DELETE,
        value
    })
}

export const handleOpenModalAddPayment = () => {
    return async (dispatch, getState) => {
        dispatch(changeModalAddPaymentShow(true))
    }
}

export const handleCloseModalAddPayment = () => {
    return async (dispatch, getState) => {
        dispatch(changeModalAddPaymentShow(false))
    }
}

export const handleFetchPaymentMethods = () => {
    return async (dispatch, getState) => {
        dispatch(changePaymentMethodsLoading(true))
        const res = await Get(`${BASE_API_URL}/kloters-jamaahs/payment-methods`);

        if (!res) return
        dispatch(changePaymentMethodsLoading(false))

        let methods = []
        if (res.data) {
            res.data.forEach((val) => {
                let temp = {
                    title: val.name,
                    value: val.id,
                };
                methods.push(temp)
            });
        }

        await dispatch(changePaymentMethodsData(methods))
    }
}

export const handleSavePayment = (data, klotersJamaahsId) => {
    return async (dispatch, getState) => {
        await dispatch(changeLoadingSave(true))
        const res = await Post(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/add-payment`, data);
        await dispatch(changeLoadingSave(false))

        if (!res) return
        await dispatch(handleCloseModalAddPayment())

        await dispatch(handleGetKloterJamaahPaymentSumary(klotersJamaahsId))
        await dispatch(handleGetKloterJamaahPaymentHistory(klotersJamaahsId))
    }
}

export const fetchQueue = (klotersJamaahsId) => {
    return async (dispatch, getState) => {
        await dispatch(handleGetKloterJamaahData(klotersJamaahsId))
        await dispatch(handleFetchItems(klotersJamaahsId))
        await dispatch(habdleFetchAdditionalItem(klotersJamaahsId))
        await dispatch(handleGetKloterJamaahPaymentSumary(klotersJamaahsId))
        await dispatch(handleGetKloterJamaahPaymentHistory(klotersJamaahsId))
    }
}

export const handleGetKloterJamaahData = (klotersJamaahsId) => {
    return async (dispatch, getState) => {
        await dispatch(changeLoadingKloterJamaahData(true))
        const res = await Get(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/detail`);

        if (!res) return

        if (!res.data) {
            return
        }

        await dispatch(changeLoadingKloterJamaahData(false))
        let data = (res.data) ? res.data : {}
        await dispatch(changeKloterJamaahData(data))
    }
}

export const handleGetKloterJamaahPaymentSumary = (klotersJamaahsId) => {
    return async (dispatch, getState) => {
        await dispatch(changeLoadingPaymentSumary(true))
        const res = await Get(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/summary-payment`);
        if (!res) return

        await dispatch(changeLoadingPaymentSumary(false))

        let data = (res.data) ? res.data : {}
        await dispatch(changePaymentSumary(data))
    }
}

export const handleGetKloterJamaahPaymentHistory = (klotersJamaahsId) => {
    return async (dispatch, getState) => {

        const state = getState()

        const { paymentHistorySorter } = state.kloterDetailJamaahDetail

        let filterQuery = (paymentHistorySorter !== null) ? `&sort_by=${paymentHistorySorter.field}&order=${paymentHistorySorter.order}` : ``

        await dispatch(changeLoadingPaymentHistory(true))
        const res = await Get(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/list-payment?${filterQuery}`);
        if (!res) return

        await dispatch(changeLoadingPaymentHistory(false))

        let data = (res.data) ? res.data : {}
        await dispatch(changePaymentHistory(data))
    }
}

export const handleFetchItems = (klotersJamaahsId) => {
    return async (dispatch, getState) => {
        await dispatch(changeFetchItemsLoading(true))
        const res = await Get(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/items`);
        if (!res) return

        await dispatch(changeFetchItemsLoading(false))

        let data = (res.data) ? res.data : []
        await dispatch(changeItems(data))
    }
}

export const habdleAddItemSave = (klotersJamaahsId, itemId, files) => {
    return async (dispatch, getState) => {

        const payload = {
            item_id: itemId,
            images: files
        }

        await dispatch(changeSaveItemLoading(true))
        const res = await Post(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/items`, payload);
        await dispatch(changeSaveItemLoading(false))

        if (!res) return

        dispatch(changeModalItemData({}))
        dispatch(handleFetchItems(klotersJamaahsId))
    }
}

export const habdleDeleteItem = (klotersJamaahsId, jamaah_kloter_item_id) => {
    return async (dispatch, getState) => {

        const res = await Delete(`${BASE_API_URL}/kloters-jamaahs/jamaah-kloter-item/${jamaah_kloter_item_id}`);
        if (!res) return

        dispatch(changeModalItemDetail({}))
        dispatch(handleFetchItems(klotersJamaahsId))
    }
}

export const habdleFetchAdditionalItem = (klotersJamaahsId) => {
    return async (dispatch, getState) => {

        await dispatch(changeFetchAdditionalItemsLoading(true))
        const res = await Get(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/additional-items`);
        await dispatch(changeFetchAdditionalItemsLoading(false))

        if (!res) return

        dispatch(changeAdditionalItems(res.data))
    }
}

export const habdleFetchAdditionalItemCandidate = (klotersJamaahsId) => {
    return async (dispatch, getState) => {
        await dispatch(changeAdditionalItemsCandidateLoading(true))
        const res = await Get(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/additional-items-candidate`);
        await dispatch(changeAdditionalItemsCandidateLoading(false))
        if (!res) return

        dispatch(changeAdditionalItemsCandidate(res.data))
    }
}

export const habdleModalAddAddtionalItemOpen = (klotersJamaahsId, type = "open") => {
    return async (dispatch, getState) => {
        if (type === "close") {
            dispatch(changeAdditionalItemsCandidate([]))
            await dispatch(changeModalAddAddtionalItem(false))
            return
        }
        await dispatch(changeModalAddAddtionalItem(true))
        await dispatch(habdleFetchAdditionalItemCandidate(klotersJamaahsId))
        return
    }
}

export const habdleAddAddtionalItemSave = (klotersJamaahsId, dataArr) => {
    return async (dispatch, getState) => {
        const payload = {
            "additional_item_id": dataArr
        }
        const res = await Post(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/additional-items`, payload);

        await dispatch(changeModalAddAddtionalItem(false))
        if (!res) return
        await dispatch(habdleFetchAdditionalItem(klotersJamaahsId))
        await dispatch(handleGetKloterJamaahPaymentSumary(klotersJamaahsId))
        return
    }
}

export const habdleDeleteAddtionalItemSave = (klotersJamaahsId, id) => {
    return async (dispatch, getState) => {
        const res = await Delete(`${BASE_API_URL}/kloters-jamaahs/jamaah-kloter-additional-item/${id}`);
        if (!res) return

        await dispatch(changeAdditionalItemToDelete({}))
        await dispatch(habdleFetchAdditionalItem(klotersJamaahsId))
        await dispatch(handleGetKloterJamaahPaymentSumary(klotersJamaahsId))
        return
    }
}

export const handleDeleteKloterJamaah = (klotersJamaahsId, kloterId) => {
    return async (dispatch, getState) => {
        await dispatch(changeLoadingKloterJamaahData(true))
        const res = await Delete(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}`);
        await dispatch(changeLoadingKloterJamaahData(false))

        if (!res) return

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: (res?.message) ?? "Terjadi Kesalahan",
            })
        }

        window.location.replace(`${BASE_URL}/kloters/detail/${kloterId}`);
    }
}

export default type