import React, {
    useState, useEffect
} from 'react'
import {
    Button, Modal, Input, Row, Col, Select, Radio,
    InputNumber,
} from 'antd';
import moment from 'moment';

import { generateRandomNumber } from "../../../../helpers/randomThings";

const Textarea = Input.TextArea

const rowInput = {
    marginBottom: 5,
}

const App = ({
    modalAddJournalOpen, changeModalAddJournalOpen, handleAddJournalSave,
    accountData, addJournalLoading,
}) => {
    const [noBukti, setNoBukti] = useState('')
    const [noRef, setNoRef] = useState('')
    const [date, setDate] = useState('')
    const [nominal, setNominal] = useState('')
    const [accountForm, setAccountForm] = useState([]);
    const [description, setDescription] = useState('')

    useEffect(() => {
        handleChangeNoBukti()
        setDate(moment(Date.now()).format("DD-MM-YYYY"))
    }, [])

    const handleChangeNoBukti = () => {
        setNoBukti(generateRandomNumber() + "" + moment(Date.now()).format("DDMMYYYY"))
        return
    }

    const resetLoacalState = () => {
        //reset data
        handleChangeNoBukti()
        setDate(moment(Date.now()).format("DD-MM-YYYY"))
        setDescription('')
        setNominal('')
        setNoRef('')
        setAccountForm([])
    }

    const closeModal = () => {
        resetLoacalState()
        changeModalAddJournalOpen(false)
        return
    }

    const handleAccountChange = (value, index) => {
        const updatedAccounts = [...accountForm];
        updatedAccounts[index].account_id = value;
        setAccountForm(updatedAccounts);
    };

    const handleNominalChange = (value, index) => {
        const updatedAccounts = [...accountForm];
        updatedAccounts[index].nominal = value;
        setAccountForm(updatedAccounts);
    };

    const addAccount = () => {
        setAccountForm([...accountForm, {}]);
    };

    const removeAccount = (index) => {
        const updatedAccounts = accountForm.filter((_, i) => i !== index);
        setAccountForm(updatedAccounts);
    };

    const handleSimpanBtnClick = () => {
        const payload = {
            no_bukti: noBukti,
            no_ref: noRef,
            date,
            nominal,
            description,
            accounts: accountForm,
        }
        resetLoacalState()
        handleAddJournalSave(payload)
        return
    }


    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Modal
            title="Input Jurnal Umum"
            open={modalAddJournalOpen}
            style={{ top: 20 }}
            bodyStyle={{
                height: "100%",
            }}
            width={"890px"}
            closable={false}
            footer={[
                <Button
                    key={1}
                    onClick={() => {
                        closeModal()
                    }}
                    loading={addJournalLoading}
                    disabled={addJournalLoading}
                >
                    cancel
                </Button>,
                <Button
                    key={2} type='primary' onClick={handleSimpanBtnClick}
                    loading={addJournalLoading}
                    disabled={addJournalLoading}
                >
                    Simpan
                </Button>
            ]}
            destroyOnClose
        >
            <div>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={4}>
                        <label style={{ fontWeight: 'bold' }}>No Bukti</label>
                    </Col>
                    <Col span={20}>
                        <Input disabled value={noBukti} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={4}>
                        <label style={{ fontWeight: 'bold' }}>No Ref</label>
                    </Col>
                    <Col span={20}>
                        <Input value={noRef}
                            onChange={(e) => setNoRef(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={4}>
                        <label style={{ fontWeight: 'bold' }}>Tanggal</label>
                    </Col>
                    <Col span={20}>
                        <Input placeholder="format: d-m-Y, contoh: 30-01-2002" value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={4}>
                        <label style={{ fontWeight: 'bold' }}>Nominal</label>
                    </Col>
                    <Col span={20}>
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            style={{ width: 200 }} addonBefore="Rp"
                            onChange={setNominal}
                            decimalSeparator={false}
                            value={nominal}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={4}>
                        <label style={{ fontWeight: 'bold' }}>Deskripsi</label>
                    </Col>
                    <Col span={20}>
                        <Textarea
                            onChange={(e) => setDescription(e.target.value)} value={description}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={4}>
                        <label style={{ fontWeight: 'bold' }}>Rincian</label>
                    </Col>
                    <Col span={20}>
                        {accountForm.map((account, index) => (
                            <Row style={{ display: 'flex', margin: "5px" }}>
                                <Col span={10}>
                                    <Select
                                        style={{ width: "100%" }}
                                        showSearch
                                        placeholder="Pilih Akun"
                                        filterOption={filterOption}
                                        onChange={(value) => handleAccountChange(value, index)}
                                        options={accountData}
                                    />
                                </Col>
                                <Col span={7}
                                    style={{ flex: 1, marginLeft: '8px' }}
                                >
                                    <InputNumber
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                        style={{ flex: 2, marginRight: '8px' }} addonBefore="Rp"
                                        decimalSeparator={false}
                                        onChange={(val) => handleNominalChange(val, index)}
                                    />

                                </Col>
                                <Col span={6}>
                                    <Radio.Group
                                        value={account.type}
                                        onChange={(e) => {
                                            const updatedAccounts = [...accountForm];
                                            updatedAccounts[index].type = e.target.value;
                                            setAccountForm(updatedAccounts);
                                        }}
                                        buttonStyle="solid"
                                        style={{
                                            position: "absolute",
                                            top:"4px"
                                        }}
                                    >
                                        <Radio value="d">Debet</Radio>
                                        <Radio value="c">Kredit</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col>
                                    <Button style={{ float: "left" }} type="danger" onClick={() => removeAccount(index)}>X</Button>
                                </Col>
                            </Row>
                        ))}
                        <Button type="primary" onClick={addAccount}>Tambah Akun</Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default App;

