import { Get } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "BRANCH_LIST"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_BRANCHES_DATA: `${key}_CHANGE_BRANCHES_DATA`,
    
    CHANGE_PAGE: `${key}_CHANGE_PAGE`,

    CHANGE_STATUS: `${key}_CHANGE_STATUS`,
    CHANGE_SORTER: `${key}_CHANGE_SORTER`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeBranchesData = (value) => ({
    type: type.CHANGE_BRANCHES_DATA,
    value
})

export const changePage = (value) => ({
    type: type.CHANGE_PAGE,
    value
})

export const changeStatus = (value) => ({
    type: type.CHANGE_STATUS,
    value
})

export const changeSorter = (value) => ({
    type: type.CHANGE_SORTER,
    value
})

export const handleFetchDataBranch = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const { status, sorter, page, perPage } = state.branchList

        let statusStringQuery = (status!=="")?`&status=${status}`:``
        let statusSorterQuery = (sorter!==null)?`&sort_by=${sorter.field}&order=${sorter.order}`:``

        let url = `${BASE_API_URL}/branches/list?page=${page}&per_page=${perPage}${statusStringQuery}${statusSorterQuery}`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }
        
        dispatch(changeBranchesData(res || []))
        return
    }
}

export default type