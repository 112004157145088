import { Get } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "JAMAAH_LIST"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_JAMAAHS_DATA: `${key}_CHANGE_JAMAAHS_DATA`,
    CHANGE_PAGE: `${key}_CHANGE_PAGE`,
    CHANGE_PER_PAGE: `${key}_CHANGE_PER_PAGE`,
    CHANGE_SORTER: `${key}_CHANGE_SORTER`,
    CHANGE_FILTER_KODE: `${key}_CHANGE_FILTER_KODE`,
    CHANGE_FILTER_NAME: `${key}_CHANGE_FILTER_NAME`,
    CHANGE_FILTER_NIK: `${key}_CHANGE_FILTER_NIK`,
    CHANGE_AGENT_NAME: `${key}_CHANGE_AGENT_NAME`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeJamaahsData = (value) => ({
    type: type.CHANGE_JAMAAHS_DATA,
    value
})

export const changePage = (value) => ({
    type: type.CHANGE_PAGE,
    value
})

export const changePerPage = (value) => ({
    type: type.CHANGE_PER_PAGE,
    value
})

export const changeSorter = (value) => ({
    type: type.CHANGE_SORTER,
    value
})

export const changeFilterKode = (value) => ({
    type: type.CHANGE_FILTER_KODE,
    value
})

export const changeFilterName = (value) => ({
    type: type.CHANGE_FILTER_NAME,
    value
})

export const changeFilterNIK = (value) => ({
    type: type.CHANGE_FILTER_NIK,
    value
})

export const changeFilterAgentName = (value) => ({
    type: type.CHANGE_AGENT_NAME,
    value
})

export const handleFetchDataJamaah = () => {
    return async (dispatch, getState) => {

        const state = getState()

        const { sorter, page, perPage, filterKode, filterName, filterNIK, filterAgentName } = state.jamaahList

        let sorterQuery = (sorter !== null) ? `&sort_by=${sorter.field}&order=${sorter.order}` : ``

        let filterKodeQuery = (filterKode !== null) ? `&kode=${filterKode}` : ``
        let filterNameQuery = (filterName !== null) ? `&name=${filterName}` : ``
        let filterNIKQuery = (filterNIK !== null) ? `&nik=${filterNIK}` : ``
        let filterAgentNameQuery = (filterAgentName !== null) ? `&agent_name=${filterAgentName}` : ``

        let url = `${BASE_API_URL}/jamaah/list?page=${page}&per_page=${perPage}${sorterQuery}${filterKodeQuery}${filterNameQuery}${filterNIKQuery}${filterAgentNameQuery}`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeJamaahsData(res || []))
        return
    }
}

export default type