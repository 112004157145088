import React, { useEffect } from 'react'
import {
    Skeleton, Button
} from 'antd';
import { PlusCircleOutlined } from "@ant-design/icons";

const TabHeir = ({
    isHeirLoading, jamaah, jamaahHeir, 
    handleGetJamaahHeir, changeModalAddHeir,
}) => {

    useEffect(() => {
        handleGetJamaahHeir(jamaah.id)
    }, [handleGetJamaahHeir, jamaah])

    if (isHeirLoading) {
        return (
            <Skeleton />
        )
    }

    if (Object.keys(jamaahHeir).length === 0) {
        return (
            <div>
                <p>
                    Belum mengisi data ahli waris yang ditunjuk.
                </p>
                <span>
                    <Button onClick={()=>changeModalAddHeir()} type='primary'><PlusCircleOutlined />Tambah Ahli Waris</Button>
                </span>
            </div>
        )
    }

    return (
        <div>
            <table>
                <tbody>
                    <tr>
                        <th className='myTh'>Nama Ahli Waris</th>
                        <td className='myTd'>{jamaahHeir.name}</td>
                    </tr>
                    <tr>
                        <th className='myTh'>NIK</th>
                        <td className='myTd'>{jamaahHeir.nik}</td>
                    </tr>
                    <tr>
                        <th className='myTh'>Hubungan</th>
                        <td className='myTd'>{jamaahHeir.relationship_status}</td>
                    </tr>
                    <tr>
                        <th className='myTh'>Alamat</th>
                        <td className='myTd'>{jamaahHeir.address}</td>
                    </tr>
                    <tr>
                        <th className='myTh'>No HP/Telp</th>
                        <td className='myTd'>{jamaahHeir.phone}</td>
                    </tr>
                    <tr>
                        <th className='myTh'>Email</th>
                        <td className='myTd'>{jamaahHeir.email}</td>
                    </tr>
                    <tr>
                        <th className='myTh'>Keterangan</th>
                        <td className='myTd'>{jamaahHeir.description}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabHeir;
