import React from 'react'
import { Button, Modal } from 'antd';
import moment from 'moment';

const App = (props) => {
    const {
        // data
        itemData, isModalActiveOrInactiveItemOpen, isActiveOrInactiveItemLoading,
        // actions
        changeModalActiveOrInactiveItemOpen, handleActiveOrInactiveItem,
    } = props
    return (
        <Modal
            title={"Apakah anda yakin "+((itemData.isActive)?"menonaktifkan":"mengaktifkan")+" item ini?"}
            open={isModalActiveOrInactiveItemOpen}
            confirmLoading={() => alert()}
            disabled
            closable={false}
            footer={[
                <Button key="cancel" type='default' onClick={() => changeModalActiveOrInactiveItemOpen(false)} loading={isActiveOrInactiveItemLoading}>
                    Batalkan
                </Button>,
                <Button key="ok" type='danger' onClick={() => handleActiveOrInactiveItem(itemData.id)} loading={isActiveOrInactiveItemLoading}>
                    {(itemData.isActive)?"Set Nonaktif":"Set Aktif"}
                </Button>,
            ]}
        >
            <p><strong>Nama Item:</strong> {itemData.name}</p>
        <p><strong>Keterangan:</strong> {itemData.description}</p>
        <p><strong>Created At:</strong> {moment(itemData.createdAt).format('DD MMMM YYYY')}</p>
        <p><strong>Updated At:</strong> {moment(itemData.updatedAt).format('DD MMMM YYYY')}</p>
        <p><strong>Status:</strong> {(itemData.isActive)?"Aktif":"Tidak Aktif"}</p>
        </Modal>
    )
}

export default App;

