import React, { useRef, useState } from 'react'
import { Modal, Table, Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const App = (props) => {
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn] = useState('');

    const {
        // data
        isModalJamaahAddToKloterOpen,
        isLoadingFetchData,
        jamaahData, selectedNik, selectedJamaahs,
        // action
        closeModal, changeSelectedNik, handleFetchDataJamaah, handleSaveJamaahsToKloter, changeSelectedJamaah,
    } = props

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedNik}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => changeSelectedNik(e.target.value)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleFetchDataJamaah()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'NIK',
            dataIndex: 'nik',
            key: 'nik',
            ...getColumnSearchProps('nik'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },

        {
            title: 'TTL',
            dataIndex: 'ttl',
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
    ];

    return (
        <Modal
            title="Tambahkan jamaah ke dalam kloter ini?"
            open={isModalJamaahAddToKloterOpen}
            // confirmLoading={true}
            width={1000}
            style={{ top: 20 }}
            bodyStyle={{
                height: "100%",
            }}
            onCancel={() => closeModal()}
            onOk={() => handleSaveJamaahsToKloter(selectedJamaahs)}
            destroyOnClose={true}
            maskClosable={false}
            footer={[
                <Button key="submit" type="default" onClick={() => closeModal()}>
                    Cancel
                </Button>,
                <Button
                    key="submit" type="primary" onClick={() => handleSaveJamaahsToKloter(selectedJamaahs)}
                    disabled={(selectedJamaahs.length>0)?false:true}
                >
                    Tambahkan
                </Button>,
            ]}
        >
            <Table
                rowSelection={{
                    type: 'checkbox',
                    onChange: (_, selectedRows) => {
                        changeSelectedJamaah(selectedRows)
                    },
                }}
                scroll={{
                    y: 240,
                }}
                columns={columns}
                dataSource={jamaahData.data}
                loading={isLoadingFetchData}
                rowKey={data => data.id}
                pagination={false}
            />

        </Modal>
    )
}

export default App;

