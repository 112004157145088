const local = "local"
const live = "live"
const live_test = "live_test"

// const env = "local"
// const env = "live_test"
const env = "live"

let BASE_URL = `http://localhost:3000`;
let BASE_API_URL = `http://localhost/umroh-app-laravel/api`;

if (env === local) {
    BASE_URL = `http://localhost:3000`;
    BASE_API_URL = `http://localhost/umroh-app-laravel/api`;
}

if (env === live_test) {
    BASE_URL = `http://localhost:3000`;
    BASE_API_URL = `https://api.zamzamsolo.com/api`;
}

if (env === live) {
    BASE_URL = `https://app.zamzamsolo.com`;
    BASE_API_URL = `https://api.zamzamsolo.com/api`;
}

export { BASE_URL, BASE_API_URL }