import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_BRANCHES_DATA:
            return {
                ...state,
                branchData: action.value
            }
        case type.CHANGE_STATUS:
            return {
                ...state,
                status: action.value
            }
        case type.CHANGE_SORTER:
            return {
                ...state,
                sorter: action.value
            }
        case type.CHANGE_PAGE:
            return {
                ...state,
                page: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    branchData:{},

    // pagination
    page:1,
    perPage:50,

    // filter and sort
    status:"",
    sorter:null,
})

export default main