/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Table, Button, Tooltip, DatePicker } from 'antd';
import moment from 'moment';

import { useAvailableKloters, useProcessTransferToKloter } from "./TransferToKloterActionHook"

const App = ({ currentKloterId, jamaahId, isVisible, closeModal }) => {
    let { id: klotersJamaahsId } = useParams();

    const [selectedKloterId, setSelectedKlotreId] = useState(null)
    const [planDate, setPlanDate] = useState(null)
    const { kloters, loadingFetchAvailKloter, fetchAvailableKloters } = useAvailableKloters();
    const { loadingTransferProcess, handleProcessTransferToKloter } = useProcessTransferToKloter();

    useEffect(() => {
        if (isVisible) {
            fetchAvailableKloters(jamaahId);
        }
    }, [isVisible]);

    const onChangePlan = (date) => {
        if (date !== null) {
            setPlanDate(moment(date).format('YYYY-MM-DD'))
            return
        }
        setPlanDate(null)
    }

    const handleProcess = () => {
        if (currentKloterId === selectedKloterId) {
            alert("Berada di kloter ini saat ini")
            return
        }
        handleProcessTransferToKloter(klotersJamaahsId, selectedKloterId)
        return
    }

    const columnsAvailKloter = [
        {
            title: 'Nama',
            dataIndex: 'name',
            align: 'left',
        },
        {
            title: 'Tanggal Terbang',
            dataIndex: 'flying_date',
            render: (text, record) => (moment(text).format('DD MMMM YYYY')),
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'active',
            align: 'right',
            width: 100,
            render: (text, record) => (text === 1) ? <>Aktif</> : <>Tidak Aktif</>,
        },
    ]
    return (
        <>
            <Modal
                title={`PINDAHKAH KE KLOTER LAIN`}
                visible={isVisible}
                onCancel={closeModal}
                disabled
                destroyOnClose
                width={900}
                footer={[
                    <Button
                        key="cancel"
                        onClick={() => {
                            setSelectedKlotreId('')
                            closeModal()
                        }}
                        disabled={loadingTransferProcess}
                        loading={loadingTransferProcess}
                    >
                        Calcel
                    </Button>,
                    <Tooltip title={(selectedKloterId === null) ? "Pilih kloter terlebih dahulu" : ""}>
                        <Button
                            style={{ marginLeft: '10px' }}
                            key="process"
                            type="primary"
                            onClick={() => handleProcess()}
                            disabled={(selectedKloterId !== null) ? false : true}
                            loading={loadingTransferProcess}
                        >
                            Proses Pindah
                        </Button>
                    </Tooltip>,
                ]}
            >
                <DatePicker onChange={onChangePlan} picker="month" placeholder='Bulang Terbang' style={{ marginRight: "5px" }} />
                <Tooltip title="Pilih periode dan tampilkan">
                    <Button
                        type='primary' loading={loadingFetchAvailKloter} onClick={() => fetchAvailableKloters(jamaahId, planDate)} disabled={(planDate === '' ? true : false)}
                    >
                        Tampilkan
                    </Button>
                </Tooltip>
                <Table
                    rowSelection={{
                        type: 'radio',
                        onChange: (_, selectedRows) => {
                            setSelectedKlotreId(selectedRows[0].id);
                            return
                        },
                    }}
                    scroll={{
                        y: 240,
                    }}
                    style={{ marginTop: 5 }}
                    columns={columnsAvailKloter}
                    dataSource={kloters}
                    loading={loadingFetchAvailKloter}
                    rowKey={data => data.id}
                    pagination={false}
                />
            </Modal>
        </>
    );
};

export default App;
