import React, { useEffect, useState } from 'react';
import {
  Card, Spin, Form, Input, Button, Radio, AutoComplete
} from 'antd';

import Layout from "../../../layout"


import { checkDateFormIsValid } from "./validator";

const App = (props) => {
  const [dob, setDob] = useState(null)
  const [passportValidUntil, setPassportValidUntil] = useState(null)

  const {
    // data
    isLoadingSave, nikOptions,
    // actions
    handleSaveNewJamaah, handleSearchNik,
  } = props

  const { TextArea } = Input;

  useEffect(function () {
    document.title = 'Tambah Jamaah Baru';
  }, [])

  return (
    <Layout>
      <Card>
        <Spin tip="Processing data..." spinning={isLoadingSave}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={(data) => handleSaveNewJamaah(data)}
          >
            <Form.Item label="NIK" name={"nik"}
              rules={[{ required: true, message: "required" }, { type: 'string', min: 16, max: 16, message: "harus 16 karakter NIK KTP" }]}
            >
              <AutoComplete
                dropdownMatchSelectWidth={252}
                style={{
                  width: 300,
                }}
                options={nikOptions}
                onSearch={handleSearchNik}
              >
                <Input placeholder="input here" allowClear />
              </AutoComplete>
            </Form.Item>

            <Form.Item label="Nama Jamaah" name={"name"}
              rules={[{ required: true, message: "required" }, { type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
            >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <Form.Item label="Nomer Passport" name={"passport_number"}
              rules={[{ type: 'string', min: 2, max: 16, message: "panjang minimal 2, maksimal 16 karakter" }]}
            >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} style={{ width: '50%' }} />
            </Form.Item>

            <Form.Item label="Nama Passport" name={"passport_name"}
              rules={[{ type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
            >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <Form.Item label="Masa Berlaku Passport S/D" name={"passport_valid_until"}
              rules={[{ validator: () => checkDateFormIsValid(passportValidUntil) }]}
            >
              <Input
                style={{ width: '50%' }}
                onChange={(val) => {
                  let dateStr = val.target.value.replace(/\s/g, "")
                  setPassportValidUntil(dateStr)
                }}
                placeholder="format: d-m-Y, contoh: 30-01-2002"
              />
            </Form.Item>

            <Form.Item label="Nama Ayah" name={"father_name"} >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <Form.Item label="Jenis Kelamin" name={"gender"}
              rules={[{ required: true, message: "required", validateTrigger: "onSubmit" }]}
            >
              <Radio.Group >
                <Radio value="male" key={1}>
                  Pria
                </Radio>
                <Radio value="female" key={2}>
                  Wanita
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item label="Tempat Lahir" name={"pob"}
              rules={[{ required: true, message: "required" }, { type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
            >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <Form.Item label="Tanggal Lahir" name={"dob"}
              rules={[{ validator: () => checkDateFormIsValid(dob) }]}
            >
              <Input
                style={{ width: '50%' }}
                onChange={(val) => {
                  let dateStr = val.target.value.replace(/\s/g, "")
                  setDob(dateStr)
                }}
                placeholder="format: d-m-Y, contoh: 30-01-2002"
              />
            </Form.Item>

            <Form.Item label="Pekerjaan" name={"occupation"}
              rules={[{ required: true, message: "required" }, { type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
            >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <Form.Item label="Alamat" name={"address"}
              rules={[{ required: true, message: "required" }, { type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
            >
              <TextArea onInput={e => e.target.value = e.target.value.toUpperCase()} ></TextArea>
            </Form.Item>

            <Form.Item label="Desa" name={"desa"}
            >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <Form.Item label="Kecamatan" name={"kecamatan"}
              rules={[{ required: true, message: "required" }]}
            >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <Form.Item label="Kabupaten" name={"kabupaten"}
              rules={[{ required: true, message: "required" }]}
            >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <Form.Item label="Provinsi" name={"provinsi"}
              rules={[{ required: true, message: "required" }]}
            >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <Form.Item label="Nomer Hp" name={"phone"}
              rules={[{ type: 'string', min: 5, max: 13, message: "panjang minimal 5, maksimal 13 karakter" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Alamat Email" name={"email"}
              rules={[{ type: 'email', message: "Format email salah" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Referal Agen" name={"agent_name"} >
              <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
            </Form.Item>

            <div style={{ float: 'right' }}>
              <Button type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Data Jamaah</Button>
            </div>
          </Form>
        </Spin>
      </Card>
    </Layout>
  );
};

export default App;