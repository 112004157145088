import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  Spin, AutoComplete
} from 'antd';
import { } from '@ant-design/icons';

import Layout from "../../../layout"

const { TextArea } = Input;

const App = (props) => {
  const [nik, setNik] = useState("")
  const [ownerPhone, setOwnerPhone] = useState("")

  const {
    // actions
    handleNewBranchSave,
    handleGetRegions,
    changeKecamatanId,
    handleCheckKodeIsTaken,
    handleCheckEmailIsTaken,
    handleCheckPhoneIsTaken,
    handleCheckOwnerPhoneIsTaken,
    handleCheckOwnerNIKIsTaken,
    // data
    isLoading,
    regions,
    kecamatanId,
    isKodeValid,
    isEmailValid,
    isPhoneValid,
    isOwnerPhoneValid,
    isOwnerNIKValid,
  } = props

  const onFinish = (values) => {
    handleNewBranchSave(values)
    return
  };

  const checkKecamatanIsValid = () => (!kecamatanId) ? Promise.reject(new Error('Kecamatan tidak valid!')) : Promise.resolve();
  const checkKodeIsValid = () => (!isKodeValid) ? Promise.reject(new Error('Kode perusahaan telah dipakai!')) : Promise.resolve()
  const checkEmailIsValid = () => (!isEmailValid) ? Promise.reject(new Error('Email telah dipakai!')) : Promise.resolve()
  const checkPhoneIsValid = () => (!isPhoneValid) ? Promise.reject(new Error('Nomer HP telah dipakai!')) : Promise.resolve()

  const stateHandleChangeNik = (nik) => {
    setNik(nik)
    handleCheckOwnerNIKIsTaken(nik)
  }

  const checkOwnerNIKIsValid = () => {
    if (!isOwnerNIKValid) {
      return Promise.reject(new Error(`NIK ${nik} telah dipakai!`))
    }

    if (isNaN(nik)) {
      return Promise.reject(new Error('Format NIK salah!'))
    }

    if (nik.length !== 16) {
      return Promise.reject(new Error('NIK required & harus 16 karakter!'))
    }
    return Promise.resolve()
  }

  const stateHandleChangeOwnerPhone = (ownerPhone) => {
    setOwnerPhone(ownerPhone)
    handleCheckOwnerPhoneIsTaken(ownerPhone)
  }

  const checkOwnerPhoneIsValid = () => {
    if (!isOwnerPhoneValid) {
      return Promise.reject(new Error(`Nomer HP Pemilik ${ownerPhone} telah dipakai!`))
    }
    if (isNaN(ownerPhone)) {
      return Promise.reject(new Error('Format Nomer HP Pemilik salah!'))
    }
    if (ownerPhone.length < 6 || ownerPhone.length > 14) {
      return Promise.reject(new Error('Nomer HP Pemilik required & harus 6 s/d 14 karakter!'))
    }
    return Promise.resolve()
  }

  useEffect(()=>{
    document.title = 'Tambah Branch Baru';
  },[])

  return (
    <Layout>
      <Card>
        <Spin tip="Processing data..." spinning={isLoading}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={onFinish}
          >
            <Form.Item label="Nama Perusahaan" name={"name"}
              rules={[{ required: true }, { type: 'string' }, { type: 'string', min: 2 }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Kode Perusahaan" name={"kode"}
              rules={[{ validator: checkKodeIsValid }, { required: true }, { type: 'string', min: 2 }]}
            >
              <Input
                onChange={(val) => handleCheckKodeIsTaken(val.target.value)}
                onInput={e => e.target.value = e.target.value.toUpperCase()}
              />
            </Form.Item>

            <Form.Item label="Alamat" name={"address"}
              rules={[{ required: true, min: 3, message: 'Alamat HP is required!' }]}
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item label="Kecamatan" name={"region"}
              rules={[{ validator: checkKecamatanIsValid }, { required: true, message: '' }]}
            >
              <AutoComplete
                options={regions}
                onSelect={(val, option) => changeKecamatanId(option.key)}
                onFocus={() => handleGetRegions()}
                onSearch={(name) => handleGetRegions(name)}
                onChange={() => changeKecamatanId("")}
              />
            </Form.Item>

            <Form.Item label="Nomer HP" name={"phone"}
              rules={[{ required: true, min: 6, pattern: new RegExp(/^[0-9]+$/) }, { validator: checkPhoneIsValid }]}
            >
              <Input
                onChange={(val) => handleCheckPhoneIsTaken(val.target.value)}
              />
            </Form.Item>

            <Form.Item label="Alamat Email" name={"email"}
              rules={[{ required: true, type: "email" }, { validator: checkEmailIsValid }]}
            >
              <Input
                onChange={(val) => handleCheckEmailIsTaken(val.target.value)}
                onInput={e => e.target.value = e.target.value.toLowerCase()}
              />
            </Form.Item>

            <Form.Item label="Nama Pemilik" name={"owner_name"}
              rules={[{ required: true, min: 2, max: 50, message: `Nama Pemilik is required` }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Alamat Pemilik" name={"owner_address"}
              rules={[{ required: true, min: 3, message: `Alamat Pemilik is required` }]}
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item label="NIK Pemilik" name={"owner_nik"}
              rules={[{ validator: checkOwnerNIKIsValid }, { required: true, message:""}]}
            >
              <Input
                maxLength={16}
                defaultValue={nik}
                value={nik}
                onChange={(val) => stateHandleChangeNik(val.target.value)}
              />
            </Form.Item>

            <Form.Item label="Nomer HP Pemilik" name={"owner_phone"}
              rules={[{ validator: checkOwnerPhoneIsValid }, { required: true, message:"" }]}
            >
              <Input
                minLength={6}
                maxLength={14}
                defaultValue={ownerPhone}
                value={ownerPhone}
                onChange={(val) => stateHandleChangeOwnerPhone(val.target.value)}
              />
            </Form.Item>

            <Form.Item label="Alamat Email Pemilik" name={"owner_email"}
              rules={[{ required: true, type: "email", message: 'Email pemilik is required!' }]}
            >
              <Input />
            </Form.Item>

            <div style={{ float: 'right' }}>
              <Button type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Data Branch</Button>
            </div>
          </Form>
        </Spin>
      </Card>
    </Layout>
  );
};

export default App;