import React, { useState } from 'react'
import { Button, Modal, Form, Input, InputNumber } from 'antd';

const App = (props) => {
    const [cost, setCost] = useState(0)

    const {
        // data
        packageData, isModalUpdatePackageOpen, isUpdatePackageLoading,
        // actions
        changeModalUpdatePackageOpen, handleStorePackage,
    } = props

    const costIsValid = (cost) => {
        if (!cost) {
            return Promise.reject(new Error('Estimasi biaya harus diisi'))
        }
        if (cost < 1) {
            return Promise.reject(new Error('Estimasi biaya harus positif'))
        }
        return Promise.resolve()
    }

    return (
        <Modal
            title="Update Data Package?"
            open={isModalUpdatePackageOpen}
            footer={null}
            onCancel={() => changeModalUpdatePackageOpen(false)}
        >
            <Form
                layout="vertical"
                onFinish={(data) => handleStorePackage(data, packageData.id)}
            >
                <Form.Item label="Nama Package" name={"name"}
                // rules={[{ required: true, min: 2, max: 50 }]}
                >
                    <Input
                        disabled
                        min={2} max={50}
                        defaultValue={packageData.name}
                        value={packageData.name}
                    />
                </Form.Item>

                <Form.Item label="Kode" name={"kode"}
                // rules={[{ required: true, min: 2, max: 20 }]}
                >
                    <Input
                        disabled
                        min={2} max={50}
                        defaultValue={packageData.kode}
                        value={packageData.kode}
                    />
                </Form.Item>

                <Form.Item
                    label="Estimasi Biaya" name={"estimated_cost"}
                    rules={[{ required: true, message: '' }, { validator: () => costIsValid(cost) }]}
                >
                    <InputNumber
                        disabled={isUpdatePackageLoading}
                        defaultValue={packageData.estimated_cost}
                        value={packageData.estimated_cost}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        style={{ width: 200 }} addonBefore="Rp" onChange={setCost}
                        decimalSeparator={false}
                    />
                </Form.Item>
                <Form.Item>
                    <div style={{ float: 'right' }}>
                        <Button loading={isUpdatePackageLoading} type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Perubahan</Button>
                    </div>
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default App;

