import { Get, Put } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "BRANCH_DETAIL"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_BRANCH_DETAIL: `${key}_CHANGE_BRANCH_DETAIL`,
    CHANGE_BRANCH_OWNER: `${key}_CHANGE_BRANCH_OWNER`,
    CHANGE_MODAL_CONFIRM_OPEN: `${key}_CHANGE_MODAL_CONFIRM_OPEN`,
    CHANGE_MODAL_CONFIRM_LOADING: `${key}_CHANGE_MODAL_CONFIRM_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeBranchDetail = (value) => ({
    type: type.CHANGE_BRANCH_DETAIL,
    value
})

export const changeBranchOwner = (value) => ({
    type: type.CHANGE_BRANCH_OWNER,
    value
})

export const changeModalConfirmOpen = (value) => ({
    type: type.CHANGE_MODAL_CONFIRM_OPEN,
    value
})

export const changeModalConfirmLoading = (value) => ({
    type: type.CHANGE_MODAL_CONFIRM_LOADING,
    value
})

export const handleGetBranchDetail = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeLoading(true))
        const res = await Get(`${BASE_API_URL}/branches/${id}/detail`);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeBranchDetail(res.data || {}))
        dispatch(changeBranchOwner(res.data.branch_owner || {}))
        return
    }
}

export const handleChangeBranchStatus = (id, status) => {
    return async (dispatch, getState) => {
        if (!id || !status) {
            alert("not id or status")
            return
        }
        const params = {
            id, status
        }

        dispatch(changeModalConfirmLoading(true))
        const res = await Put(`${BASE_API_URL}/branches/change-status`, params);
        dispatch(changeModalConfirmLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeModalConfirmOpen(false))
        dispatch(handleGetBranchDetail(id))
        return
    }
}

export default type