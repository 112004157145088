import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_ITEM_DETAIL:
            return {
                ...state,
                itemData: action.value
            }
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_ACTIVE_OR_INACTIVE_ITEM_LOADING:
            return {
                ...state,
                isActiveOrInactiveItemLoading: action.value
            }
        case type.CHANGE_MODAL_ACTIVE_OR_INACTIVE_ITEM_OPEN:
            return {
                ...state,
                isModalActiveOrInactiveItemOpen: action.value
            }
        case type.CHANGE_MODAL_UPDATE_OPEN:
            return {
                ...state,
                isModalUpdateOpen: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    itemData: {},

    isActiveOrInactiveItemLoading: false,
    isModalActiveOrInactiveItemOpen: false,
    isModalUpdateOpen: false,
})

export default main