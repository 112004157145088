import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import {
    Button, Table, Modal, DatePicker, Tooltip,
} from 'antd';
import moment from 'moment';

const App = (props) => {
    const columnsJamaahKloter = [
        {
            title: 'Nama',
            dataIndex: 'name',
            align: 'left',
            render: (text, record) => <Link to={`/kloters-jamaahs/detail/${record.id}`}>{text}</Link>,

        },
        {
            title: 'Tanggal Terbang',
            dataIndex: 'flying_date',
            render: (text, record) => (moment(text).format('DD MMMM YYYY')),
            align: 'center',
        },
        {
            title: 'Paket',
            dataIndex: 'package',
            render: (text, record) => <>{record.package}</>,
            align: 'right'
        },
        {
            title: 'Status',
            dataIndex: 'active',
            render: (text, record) => <>{(record.active) ? "Aktif" : "Tidak Aktif"}</>,
            align: 'right'
        },
    ]

    const {
        // actions
        handleOpenModal,
        handleFetchKloters,
        // data
        isFetchDataLoading, listKloters,
    } = props

    useEffect(() => {
        handleFetchKloters()
    }, [handleFetchKloters])

    return (
        <>
            <Button
                style={{ marginBottom: "10px" }}
                type='primary'
                onClick={() => handleOpenModal(true)}
                disabled={isFetchDataLoading}
            >
                + Daftarkan Ke Kloter
            </Button>
            <Table
                style={{ marginTop: 5 }}
                columns={columnsJamaahKloter}
                dataSource={listKloters}
                loading={isFetchDataLoading}
                rowKey={data => data.id}
                pagination={false}
            />
            <ModalAddToKloter {...props} />
        </>
    )
}

const columnsAvailKloter = [
    {
        title: 'Nama',
        dataIndex: 'name',
        align: 'left',
    },
    {
        title: 'Tanggal Terbang',
        dataIndex: 'flying_date',
        render: (text, record) => (moment(text).format('DD MMMM YYYY')),
        align: 'center',
    },
    {
        title: 'Paket',
        dataIndex: 'package',
        align: 'right'
    },
    {
        title: 'Status',
        dataIndex: 'active',
        align: 'right',
        width: 100,
    },
]

const ModalAddToKloter = (props) => {
    const [selectedKloterId, setSelectedKlotreId] = useState('')
    const {
        // actions
        handleChangePlanDate, handleClosenModal, handleFetchAvailKloters, handleAddToKloterSave,
        // data
        isModalAddToKloterOpen, isAvailKloterListLoading, availKloterList, planDate, isAddToKloterSaveLoading,
    } = props
    const onChangePlan = (date) => {
        handleChangePlanDate(date)
        if (!date) {
            handleFetchAvailKloters()
        }
    }
    return (
        <Modal
            title="Pilih kloter"
            open={isModalAddToKloterOpen}
            closable={false}
            width={1000}
            maskClosable={false}
            destroyOnClose={true}
            footer={[
                <Button
                    key="cancel"
                    onClick={() => {
                        setSelectedKlotreId('')
                        handleClosenModal()
                    }}
                    disabled={isAddToKloterSaveLoading}
                >
                    Calcel
                </Button>,
                <Tooltip title={(selectedKloterId === '') ? "Pilih kloter terlebih dahulu" : ""}>
                    <Button
                        style={{ marginLeft: '10px' }}
                        key="process"
                        type="primary"
                        onClick={() => handleAddToKloterSave(selectedKloterId)}
                        loading={isAddToKloterSaveLoading}
                        disabled={(selectedKloterId !== '') ? false : true}
                    >
                        Proses
                    </Button>
                </Tooltip>,
            ]}
        >
            <DatePicker onChange={onChangePlan} picker="month" placeholder='Bulang Terbang' style={{ marginRight: "5px" }} />
            <Tooltip title="Pilih periode dan tampilkan">
                <Button
                    type='primary' loading={isAvailKloterListLoading} onClick={() => handleFetchAvailKloters()} disabled={(planDate === '' ? true : false)}
                >
                    Tampilkan
                </Button>
            </Tooltip>
            <Table
                rowSelection={{
                    type: 'radio',
                    onChange: (_, selectedRows) => {
                        setSelectedKlotreId(selectedRows[0].id);
                    },
                }}
                scroll={{
                    y: 240,
                }}
                style={{ marginTop: 5 }}
                columns={columnsAvailKloter}
                dataSource={availKloterList}
                loading={isAvailKloterListLoading}
                rowKey={data => data.id}
                pagination={false}
            />
        </Modal>
    )
}

export default App;
