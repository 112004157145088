import { connect } from 'react-redux'
import App from './component'
import {
    handleFetchDataBranch,
    changePage, changeStatus, changeSorter, 
} from './actions'


const mapStateToProps = (state) => ({
    ...state.branchList,
})

const mapDispatchToProps = {
    handleFetchDataBranch,
    changePage, changeStatus, changeSorter,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)