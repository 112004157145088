import React, { useEffect, useState } from 'react';
import {
  Form, Input, InputNumber, Button, Card, Spin,
} from 'antd';

import Layout from "../../../layout"

const App = (props) => {

  const { handleSavePackage, isLoading } = props

  const [cost, setCost] = useState(0)

  useEffect(() => {
    document.title = 'Package New';
  }, []);

  const costIsValid = (cost) => {
    if (!cost) {
      return Promise.reject(new Error('Estimasi biaya harus diisi'))
    }
    if (cost < 1) {
      return Promise.reject(new Error('Estimasi biaya harus positif'))
    }
    return Promise.resolve()
  }

  return (
    <Layout>
      <Card>
        <Spin tip="Processing data..." spinning={isLoading}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={(data) => handleSavePackage(data)}
          >
            <Form.Item label="Nama Package" name={"name"} rules={[{ required: true, min: 2, max: 50 }]}>
              <Input min={2} max={50} />
            </Form.Item>

            <Form.Item label="Kode" name={"kode"} rules={[{ required: true, min: 2, max: 20 }]}>
              <Input min={2} max={50} />
            </Form.Item>

            <Form.Item
              label="Estimasi Biaya" name={"estimated_cost"}
              rules={[{ required: true, message: '' }, { validator: () => costIsValid(cost) }]}
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                style={{ width: 200 }} addonBefore="Rp" onChange={setCost}
                decimalSeparator={false}
              />
            </Form.Item>

            <div style={{ float: 'right' }}>
              <Button type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Data Package</Button>
            </div>
          </Form>
        </Spin>
      </Card>

    </Layout >
  );
};

export default App;