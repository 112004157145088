/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { Card, Table, Button, Modal, Form, Input, InputNumber, Divider } from 'antd';
import {
  EditOutlined, DeleteOutlined,
} from '@ant-design/icons';

import { rupiahFormat, usdFormat } from "../../../../../helpers/currency";

const Textarea = Input.TextArea

const App = (props) => {
  let { id: kloterId } = useParams();
  const [exchangeRate, setExchangeRate] = React.useState(15000)
  const {
    // data
    isFetchLoading, kloterEstimation, modalAddOpen, kloterEstimationUpdate, kloterEstimationDelete, kloterNote,
    // actions
    handleFetchKloterEstimation, changeModalAddOpen, handleSaveKloterEstimation, changeKloterEstimationUpdate,
    handleUpdateKloterEstimation, changeKloterEstimationDelete, handleDeleteKloterEstimation, handleSaveNote,
    handleFetchNote, handleDeleteNote,
  } = props

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Biaya USD',
      dataIndex: 'nominal',
      align: 'right',
      render: (text) => usdFormat(text),
    },
    {
      title: 'Biaya Rupiah',
      dataIndex: 'nominal',
      align: 'right',
      render: (text) => rupiahFormat(exchangeRate * text),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Link onClick={() => changeKloterEstimationUpdate(record)}><EditOutlined /></Link> |
            <Link onClick={() => changeKloterEstimationDelete(record)}><DeleteOutlined /></Link>
          </>
        )
      },
    },
  ];

  React.useEffect(() => {
    handleFetchKloterEstimation(kloterId)
    handleFetchNote(kloterId)
  }, []);

  const handleSetExchageRate = async (data) => {
    await setExchangeRate(data.exchangeRate)
    await handleFetchKloterEstimation(kloterId)
  }

  return (
    <>
      <Card>
        <div>
          <Form
            layout="vertical"
            onFinish={(data) => handleSetExchageRate(data)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Form.Item
              name={"exchangeRate"}
              rules={[{ required: true }]}
              initialValue={exchangeRate}
              style={{ marginRight: 10 }}
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                style={{ width: 200 }}
                addonBefore="1 USD = "
                addonAfter="Rp "
                decimalSeparator={false}
                initialValue={exchangeRate}
              />
            </Form.Item>
            <div>
              <Button type="primary" htmlType="submit"
                style={{ bottom: '12px' }}
              >
                Set
              </Button>
            </div>
          </Form>
        </div>

        <div style={{ marginBottom: "15px" }}>
          <Button
            type='primary' style={{ float: "right" }}
            onClick={() => changeModalAddOpen(true)}
            disabled={false}
          >
            +Tambah Data
          </Button>
        </div>
        <Table
          style={{ marginTop: 5 }}
          columns={columns}
          dataSource={kloterEstimation?.ke}
          loading={isFetchLoading}
          rowKey={data => data.id}
          pagination={false}
        />

        <h1><strong>Total: {usdFormat(kloterEstimation?.sum_nominal)} <br />{rupiahFormat(exchangeRate * kloterEstimation?.sum_nominal)}</strong></h1>
        <Divider />
        <strong><p>Catatan:</p></strong>
        {
          (kloterNote)
            ?
            <>
              <p>{kloterNote.note}</p>
              <Link onClick={() => handleDeleteNote(kloterNote.id)}><DeleteOutlined /> Hapus Catatan</Link>
            </>
            :
            <Form
              layout="horizontal"
              onFinish={(data) => handleSaveNote(kloterId, data)}
            >
              <Form.Item name={"note"} rules={[{ required: true, min: 2, max: 50 }]}>
                <Textarea min={2} max={50} />
              </Form.Item>
              <div style={{ float: 'right' }}>
                <Button type="primary" style={{ top: -20 }} htmlType="submit">Tambah catatan</Button>
              </div>
            </Form>
        }
      </Card>

      <Modal
        title="Tambah Data?"
        open={modalAddOpen}
        footer={null}
        onCancel={() => changeModalAddOpen(false)}
        width={800}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={(data) => handleSaveKloterEstimation(kloterId, data)}
        >
          <Form.Item label="Nama Item" name={"name"} rules={[{ required: true, min: 2, max: 50 }]}>
            <Textarea min={2} max={50} />
          </Form.Item>
          <Form.Item label="Biaya" name={"nominal"} rules={[{ required: true }]}>
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              style={{ width: 200 }} addonBefore="USD"
              decimalSeparator={false}
              size="large"
            />
          </Form.Item>
          <div style={{ float: 'right' }}>
            <Button type="primary" style={{ top: -20 }} htmlType="submit">Simpan Data</Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Update Data?"
        open={(kloterEstimationUpdate.hasOwnProperty('name')) ? true : false}
        footer={null}
        onCancel={() => changeKloterEstimationUpdate({})}
        width={800}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={(data) => handleUpdateKloterEstimation(kloterId, kloterEstimationUpdate.id, data)}
        >
          <Form.Item label="Nama Item" name={"name"} rules={[{ required: true, min: 2, max: 50 }]} initialValue={kloterEstimationUpdate?.name}>
            <Textarea min={2} max={50} />
          </Form.Item>
          <Form.Item label="Biaya" name={"nominal"} rules={[{ required: true }]}>
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              style={{ width: 200 }} addonBefore="USD"
              decimalSeparator={false}
              size="large"
            />
          </Form.Item>
          <div style={{ float: 'right' }}>
            <Button type="primary" style={{ top: -20 }} htmlType="submit">Simpan Data</Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Apakah anda yakin Menghapus item ini?"
        open={(kloterEstimationDelete.hasOwnProperty('name')) ? true : false}
        footer={[
          <Button key="ok" type='danger' onClick={() => handleDeleteKloterEstimation(kloterId, kloterEstimationDelete.id)}>
            Konfirmasi
          </Button>
        ]}
        destroyOnClose
        onCancel={() => changeKloterEstimationDelete({})}
      >
        <p><strong>Nama Item:</strong> {kloterEstimationDelete.name}</p>
        <p><strong>Biaya:</strong> {usdFormat(kloterEstimationDelete?.nominal)}</p>
      </Modal>
    </>
  )
}

export default App;

