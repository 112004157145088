import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_FETCH_ROOM_LOADING:
            return {
                ...state,
                fetchRoomLoading: action.value
            }
        case type.CHANGE_ROOM_DATA:
            return {
                ...state,
                roomData: action.value
            }
        case type.CHANGE_ROOM_DATA_SELECT_OPTION:
            return {
                ...state,
                roomDataSelectOption: action.value
            }
        case type.CHANGE_MODAL_ADD_ROOM_OPEN:
            return {
                ...state,
                modalAddRoomOpen: action.value
            }
        case type.CHANGE_SAVE_ROOM_LOADING:
            return {
                ...state,
                saveRoomLoading: action.value
            }
        case type.CHANGE_MODAL_UPDATE_ROOM_DATA:
            return {
                ...state,
                modalUpdateRoomData: action.value
            }
        case type.CHANGE_UPDATE_ROOM_LOADING:
            return {
                ...state,
                updateRoomLoading: action.value
            }
        case type.CHANGE_MODAL_DELETE_ROOM_DATA:
            return {
                ...state,
                modalDeleteRoomData: action.value
            }
        case type.CHANGE_DELETE_ROOM_LOADING:
            return {
                ...state,
                deleteRoomLoading: action.value
            }
        case type.CHANGE_JAMAAH_DATA:
            return {
                ...state,
                jamaahData: action.value
            }
        case type.CHANGE_FETCH_JAMAAH_DATA_LOADING:
            return {
                ...state,
                fetchJamaahDataLoading: action.value
            }
        case type.CHANGE_ADD_TOO_ROOM_LOADING:
            return {
                ...state,
                addToRoomLoading: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        default:
            return state
    }
}

const defaultState = () => ({
    fetchRoomLoading: false,
    roomData: [],
    roomDataSelectOption: [],

    modalAddRoomOpen: false,
    saveRoomLoading: false,

    modalUpdateRoomData: {},
    updateRoomLoading: false,
    
    modalDeleteRoomData: {},
    deleteRoomLoading: false,

    jamaahData: {},
    fetchJamaahDataLoading: false,

    addToRoomLoading: false,
})

export default main