import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
    Modal, Form, Input, Button, Radio
} from 'antd';

import { checkFormDateIsValid } from './ModalUpdateDataValidator'

const { TextArea } = Input;

export default function App(props) {
    let { id } = useParams();

    const [dob, setDob] = useState(null)
    const [passportValidUntil, setPassportValidUntil] = useState(null)

    const {
        // actions
        changeModalUpdateOpen, handleUpdateJamaahData,
        // data
        jamaah, modalUpdateOpen, isSaveUpdateLoading,
    } = props

    const initialValDate = moment(jamaah?.dob).format("DD-MM-YYYY")
    const initialValPassportValidUntil = (jamaah?.passport_valid_until)?moment(jamaah?.passport_valid_until).format("DD-MM-YYYY"):""

    useEffect(() => {
        setDob(initialValDate)
        setPassportValidUntil(initialValPassportValidUntil)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal
            title="Update Data Jamaah"
            open={modalUpdateOpen}
            onCancel={() => changeModalUpdateOpen(false)}
            width={1000} footer={null}
            destroyOnClose
        >
            <Form
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={(data) => handleUpdateJamaahData(data, id)}
            >
                <Form.Item initialValue={jamaah?.nik} label="NIK" name={"nik"}
                    rules={[{ required: true, message: "required" }, { type: 'string', min: 16, max: 16, message: "harus 16 karakter NIK KTP" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item initialValue={jamaah?.name} label="Nama Jamaah" name={"name"}
                    rules={[{ required: true, message: "required" }, { type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}

                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <Form.Item label="Nomer Passport" name={"passport_number"}
                    initialValue={jamaah?.passport_number} rules={[{ type: 'string', min: 2, max: 16, message: "panjang minimal 2, maksimal 16 karakter" }]}
                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} style={{ width: '50%' }} />
                </Form.Item>

                <Form.Item label="Nama Passport" name={"passport_name"}
                    initialValue={jamaah?.passport_name} rules={[{ type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <Form.Item
                    initialValue={passportValidUntil}
                    label="Masa Berlaku Passport S/D" name={"passport_valid_until"}
                    rules={[{ validator: () => checkFormDateIsValid(passportValidUntil) }]}
                >
                    <Input
                        style={{ width: '50%' }}
                        onChange={(val) => {
                            let dateStr = val.target.value.replace(/\s/g, "")
                            setPassportValidUntil(dateStr)
                        }}
                        placeholder="format: d-m-Y, contoh: 30-01-2002"
                    />
                </Form.Item>

                <Form.Item initialValue={jamaah.father_name} label="Nama Ayah" name={"father_name"}
                    rules={[{ type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <Form.Item label="Jenis Kelamin" name={"gender"} initialValue={jamaah.gender}
                    rules={[{ required: true, message: "required", validateTrigger: "onSubmit" }]}
                >
                    <Radio.Group >
                        <Radio value="male" key={1}>
                            Pria
                        </Radio>
                        <Radio value="female" key={2}>
                            Wanita
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item initialValue={jamaah.pob} label="Tempat Lahir" name={"pob"}
                    rules={[{ required: true, message: "required" }, { type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <Form.Item
                    initialValue={initialValDate}
                    label="Tanggal Lahir" name={"dob"}
                    rules={[{ validator: () => checkFormDateIsValid(dob) }]}
                >
                    <Input
                        style={{ width: '50%' }}
                        onChange={(val) => {
                            let dateStr = val.target.value.replace(/\s/g, "")
                            setDob(dateStr)
                        }}
                        placeholder="format: d-m-Y, contoh: 30-01-2002"
                    />
                </Form.Item>

                <Form.Item initialValue={jamaah.occupation} label="Pekerjaan" name={"occupation"}
                    rules={[{ required: true, message: "required" }, { type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <Form.Item initialValue={jamaah.address} label="Alamat" name={"address"}
                    rules={[{ required: true, message: "required" }, { type: 'string', min: 2, max: 100, message: "panjang minimal 2, maksimal 100 karakter" }]}
                >
                    <TextArea onInput={e => e.target.value = e.target.value.toUpperCase()}></TextArea>
                </Form.Item>

                <Form.Item label="Desa" name={"desa"} initialValue={jamaah.desa}
                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <Form.Item label="Kecamatan" name={"kecamatan"} initialValue={jamaah.kecamatan}
                    rules={[{ required: true, message: "required" }]}
                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <Form.Item label="Kabupaten" name={"kabupaten"} initialValue={jamaah.kabupaten}
                    rules={[{ required: true, message: "required" }]}
                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <Form.Item label="Provinsi" name={"provinsi"} initialValue={jamaah.provinsi}
                    rules={[{ required: true, message: "required" }]}
                >
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <Form.Item initialValue={jamaah.phone} label="Nomer Hp" name={"phone"}
                    rules={[{ type: 'string', min: 5, max: 13, message: "panjang minimal 5, maksimal 13 karakter" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item initialValue={jamaah.email} label="Alamat Email" name={"email"}
                    rules={[{ type: 'email', message: "Format email salah" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item initialValue={jamaah.agent_name} label="Referal Agen" name={"agent_name"}>
                    <Input onInput={e => e.target.value = e.target.value.toUpperCase()} />
                </Form.Item>

                <div style={{ float: 'right' }}>
                    <Button type="primary" style={{ marginRight: 2, bottom: 20 }} htmlType="submit" loading={isSaveUpdateLoading} disabled={isSaveUpdateLoading}>Simpan Data Jamaah</Button>
                </div>
            </Form>
        </Modal>
    )
}
