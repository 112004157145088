import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_SAVE_LOADING:
            return {
                ...state,
                saveLoading: action.value
            }
        case type.CHANGE_MODAL_OPEN:
            return {
                ...state,
                modalOpen: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        default:
            return state
    }
}

const defaultState = () => ({
    saveLoading: false,
    modalOpen: false,
})

export default main