import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_USERS_DATA:
            return {
                ...state,
                userData: action.value
            }
        case type.CHANGE_STATUS:
            return {
                ...state,
                status: action.value
            }
        case type.CHANGE_SORTER:
            return {
                ...state,
                sorter: action.value
            }
        case type.CHANGE_MODAL_NEW_OPEN:
            return {
                ...state,
                modalNewOpen: action.value
            }
        case type.CHANGE_SAVE_LOADING:
            return {
                ...state,
                saveLoading: action.value
            }
        case type.CHANGE_ROLES:
            return {
                ...state,
                roles: action.value
            }
        case type.CHANGE_USER_UPDATE_DATE:
            return {
                ...state,
                userUpdateData: action.value
            }
        case type.CHANGE_UPDATE_LOADING:
            return {
                ...state,
                updateLoading: action.value
            }
        case type.CHANGE_USER_UPDATE_PASSWORD_DATA:
            return {
                ...state,
                userUpdatePasswordData: action.value
            }
        case type.CHANGE_UPDATE_PASSWORD_LOADING:
            return {
                ...state,
                updatePasswordLoading: action.value
            }
        case type.CHANGE_INACTIVE_TOGGLE_DATA:
            return {
                ...state,
                setInactiveToggleData: action.value
            }
        case type.CHANGE_INACTIVE_TOGGLE_LOADING:
            return {
                ...state,
                setInactiveSaveLoading: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    userData: {},

    // filter and sort
    status: "",
    sorter: null,

    roles: [],
    modalNewOpen: false,
    saveLoading: false,

    userUpdateData: {},
    updateLoading: false,

    userUpdatePasswordData: {},
    updatePasswordLoading: false,

    setInactiveToggleData:{},
    setInactiveSaveLoading: false,

})

export default main