import { Get, Post } from "../../../../../helpers/ajax";
import { BASE_API_URL } from "../../../../../config";
import { notification } from 'antd';

const key = "TAB_JAMAAH_DETAIL_LIST_KLOTER"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_FETCH_DATA_LOADING: `${key}_CHANGE_FETCH_DATA_LOADING`,
    CHANGE_LIST_KLOTERS: `${key}_CHANGE_LIST_KLOTERS`,
    CHANGE_PLAN_DATE: `${key}_CHANGE_PLAN_DATE`,
    CHANGE_MODAL_ADD_TO_KLOTER_OPEN: `${key}_CHANGE_MODAL_ADD_TO_KLOTER_OPEN`,
    CHANGE_ADD_TO_KLOTER_SAVE_LOADING: `${key}_CHANGE_ADD_TO_KLOTER_SAVE_LOADING`,
    CHANGE_AVAIL_KLOTER_LIST: `${key}_CHANGE_AVAIL_KLOTER_LIST`,
    CHANGE_FETCH_AVAIL_KLOTER_LOADING: `${key}_CHANGE_FETCH_AVAIL_KLOTER_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeFetchDataLoading = (value) => ({
    type: type.CHANGE_FETCH_DATA_LOADING,
    value
})

export const changeListKloters = (value) => ({
    type: type.CHANGE_LIST_KLOTERS,
    value
})

export const changePlanDate = (value) => ({
    type: type.CHANGE_PLAN_DATE,
    value
})

export const changeModalAddToKloterOpen = (value) => ({
    type: type.CHANGE_MODAL_ADD_TO_KLOTER_OPEN,
    value
})

export const changeAddToKloterSaveLoading = (value) => ({
    type: type.CHANGE_ADD_TO_KLOTER_SAVE_LOADING,
    value
})

export const changeAvailKloterList = (value) => ({
    type: type.CHANGE_AVAIL_KLOTER_LIST,
    value
})

export const changeAvailKloterListLoading = (value) => ({
    type: type.CHANGE_FETCH_AVAIL_KLOTER_LOADING,
    value
})

export const handleFetchKloters = () => {
    return async (dispatch, getState) => {

        const state = getState()
        const { id } = state.jamaahDetail.jamaah

        dispatch(changeFetchDataLoading(true))
        const res = await Get(`${BASE_API_URL}/jamaah/${id}/detail/kloters`);
        dispatch(changeFetchDataLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        let kloters = (res.data) ?? []

        dispatch(changeListKloters(kloters))

        return
    }
}

export const handleOpenModal = () => {
    return async (dispatch, getState) => {
        dispatch(changeModalAddToKloterOpen(true))
        dispatch(handleFetchAvailKloters())
        return
    }
}

export const handleClosenModal = () => {
    return async (dispatch, getState) => {
        dispatch(changeModalAddToKloterOpen(false))
        dispatch(changeAvailKloterList([]))
        dispatch(changePlanDate(''))
        return
    }
}

export const handleChangePlanDate = (val) => {
    return async (dispatch, getState) => {
        if (!val) {
            dispatch(changePlanDate(''))
            return
        }
        let dateString = val._d.toISOString().split('T')[0] || "";
        dispatch(changePlanDate(dateString))
        return
    }
}

export const handleFetchAvailKloters = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const { id } = state.jamaahDetail.jamaah
        const { planDate } = state.tabJamaahDetailListKloter

        const query = (planDate) ? `&period=${planDate}` : ''

        dispatch(changeAvailKloterListLoading(true))
        const res = await Get(`${BASE_API_URL}/jamaah/${id}/detail/avail-kloters?${query}`);
        dispatch(changeAvailKloterListLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        let availKloters = (res.data) ?? []
        dispatch(changeAvailKloterList(availKloters))
        return
    }
}

export const handleAddToKloterSave = (kloterId) => {
    return async (dispatch, getState) => {
        const state = getState()
        const { id } = state.jamaahDetail.jamaah

        const payload = {
            jamaahs_id: [id]
        }

        dispatch(changeAddToKloterSaveLoading(true))
        const res = await Post(`${BASE_API_URL}/kloters-jamaahs/${kloterId}`, payload);
        dispatch(changeAddToKloterSaveLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }
        
        dispatch(handleClosenModal())
        dispatch(handleFetchKloters())
        return
    }
}

export default type