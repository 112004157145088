import { connect } from 'react-redux'
import App from './component'
import {
    changeModalAddRoomOpen, handleSaveRoomData,
    changeModalUpdateRoomData, handleUpdateRoom,
    handleSaveAddToRoom, handleQueue,
    changeModalDeleteRoomData, handleDeleteRoom,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.kloterRoomSetting,
})

const mapDispatchToProps = {
    changeModalAddRoomOpen, handleSaveRoomData,
    changeModalUpdateRoomData, handleUpdateRoom,
    handleSaveAddToRoom, handleQueue,
    changeModalDeleteRoomData, handleDeleteRoom,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)