import React, { useEffect } from 'react';
import {
  Card,
} from 'antd';

import Layout from "../../../layout"
import TableListJournal from "./childComponets/TableListJournal"
import ModalAddJournal from "./childComponets/ModalAddJournal"
import ModalUpdateJournal from "./childComponets/ModalUpdateJournal"
import ModalDeleteJournal from "./childComponets/ModalDeleteJournal"
import ModalUploadBRI from "./childComponets/ModalUploadBRI"

const App = (props) => {
  const {
    //data
    //actions
    handleFetchJournalData, handleFetchDataAccounts,
  } = props


  useEffect(() => {
    document.title = 'Journal List';
    handleFetchJournalData()
    handleFetchDataAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ModalAddJournal {...props} />
      <ModalUpdateJournal {...props} />
      <ModalDeleteJournal {...props} />
      <ModalUploadBRI />
      <Layout>
        <Card>
          <TableListJournal {...props} />
        </Card>
      </Layout>
    </>
  );
};

export default App;
