import React, { } from 'react'
import { Button, Modal, Form, Input, InputNumber } from 'antd';

const Textarea = Input.TextArea

const App = (props) => {

    const {
        itemData, isModalUpdateOpen,
        changeModalUpdateOpen, handleUpdateItem,
    } = props

    return (
        <Modal
            title="Update Data Package?"
            open={isModalUpdateOpen}
            footer={null}
            onCancel={() => changeModalUpdateOpen(false)}
            width={800}
            destroyOnClose
        >
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={(data) => handleUpdateItem(itemData.id, data)}
            >
                <Form.Item label="Nama Item" name={"name"} rules={[{ required: true, min: 2, max: 50 }]} initialValue={itemData.name}>
                    <Input min={2} max={50} />
                </Form.Item>

                <Form.Item label="Keterangan" name={"description"} rules={[{ required: false, min: 2, max: 100 }]} initialValue={itemData.description}>
                    <Textarea min={2} max={50} />
                </Form.Item>

                <Form.Item label="Supporting files" name={"supporting_files_number"} initialValue={itemData.supporting_files_number}>
                    <InputNumber max={9} />
                </Form.Item>

                <div style={{ float: 'right' }}>
                    <Button type="primary" style={{ top: -20 }} htmlType="submit">Simpan Data Item</Button>
                </div>
            </Form>
        </Modal>
    )
}

export default App;

