import React from 'react';
import { Space, Input, Modal, Button, Table, Descriptions, InputNumber, Divider } from 'antd';
import { useSearchJamaah, useProsesbayar } from "./BayarCepatActionHook"
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { bilanganIndonesia } from "../../../../helpers/pembilangAngka";
import { generateRandomKey } from "../../../../helpers/randomThings";

const App = (props) => {
    const { data: dataJamaah, loading: loadingSerchJamaah, searchJamaah, setData } = useSearchJamaah();
    const { loading: loadingSimpan, prosesBayar } = useProsesbayar();
    const [keyword, setKeyword] = useState(null)
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [nominal, setNominal] = useState(0)
    const [description, setDescription] = useState("")

    const columnsJamaah = [
        {
            title: `Kode`,
            dataIndex: 'kode',
            width: "50px",
        },
        {
            title: `NIK`,
            dataIndex: 'nik',
            width: "50px",
        },
        {
            title: 'Nama Jamaah',
            dataIndex: 'name',
            width: "260px",
        },
        {
            title: 'Kloter',
            dataIndex: 'kloter',
            render: (text, record) => {
                if (!record.kloters_jamaahs_id) {
                    return (<>Belum Terdaftar</>)
                }
                return text
            }
        },
        {
            title: 'Aksi',
            dataIndex: 'kloter',
            render: (text, record) => {
                if (record.active) {
                    return (<Link onClick={() => setSelectedRecord(record)}>Pilih</Link>)
                }
                if (!record.kloters_jamaahs_id) {
                    return (<></>)
                }
                if (!record.active) {
                    return (<>Kloter Tidak Aktif</>)
                }
            },
        },
    ]

    const handleProsesBayar = async () => {
        if (nominal < 10000) {
            alert("Nominal Terlalu Sedikit")
            return
        }
        const payload = {
            "date": localStorage.getItem("dateNow"),
            "nominal": nominal,
            "description": description,
            "payment_method_id": 1
        }
        await prosesBayar(selectedRecord.kloters_jamaahs_id, payload)
        await setSelectedRecord(null)
        await setNominal(0)
        await setData([])
        await props.closeBayarModal()
        return
    }

    return (
        <>
            <Modal
                bodyStyle={{
                    top: "-30px",
                    height: '500px',
                    overflow: 'auto',
                }}
                title="Bayar Cepat"
                open={props.modalOpen}
                footer={null}
                onCancel={() => {
                    setSelectedRecord(null)
                    setNominal(0)
                    setData([])
                    props.closeBayarModal()
                }}
                width={900}
                destroyOnClose
            >
                {
                    (selectedRecord)
                        ?
                        <>
                            <Descriptions title="Info Pembayaran Jamaah">
                                <Descriptions.Item label="Kode Jamaah"><strong>{selectedRecord.kode}</strong></Descriptions.Item>
                                <Descriptions.Item label="Name"><strong>{selectedRecord.name}</strong></Descriptions.Item>
                                <Descriptions.Item label="NIK"><strong>{selectedRecord.nik}</strong></Descriptions.Item>
                                <Descriptions.Item label="Kloter"><strong>{selectedRecord.kloter}</strong></Descriptions.Item>
                            </Descriptions>
                            <strong>Tanggal Pembayaran</strong>
                            <br />

                            <Input
                                style={{ width: '50%' }}
                                onChange={(val) => {
                                    localStorage.setItem("dateNow", val.target.value)
                                }
                                }
                                defaultValue={localStorage.getItem("dateNow")}
                                placeholder="format: d-m-Y, contoh: 30-01-2002"
                            />

                            <br />

                            <strong>Nominal Pembayaran</strong>
                            <br />
                            <InputNumber
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                style={{ width: 200 }} addonBefore="Rp"
                                decimalSeparator={false}
                                onChange={setNominal}
                                size="large"
                            />
                            <br />
                            {(nominal) ? bilanganIndonesia(nominal) : "Nol"} Rupiah

                            <br />

                            <strong>Deskripsi</strong>
                            <br />
                            <Input.TextArea
                                onChange={(e) => {
                                    setDescription(e.target.value)
                                }}
                            >{description}</Input.TextArea>

                            <Divider />


                            <>

                                <Button
                                    style={{ float: 'right' }}
                                    type="primary"
                                    loading={loadingSimpan}
                                    disabled={loadingSimpan}
                                    onClick={handleProsesBayar}
                                >
                                    Proses Pembayaran
                                </Button>
                                <Button
                                    onClick={() => {
                                        setSelectedRecord(null)
                                        setNominal(0)
                                        setSelectedRecord(null)
                                    }}
                                    style={{ float: 'right', marginRight: "10px" }}
                                    loading={loadingSimpan}
                                    disabled={loadingSimpan}
                                >
                                    Hapus & Kembali Ke Pencarian
                                </Button>
                            </>
                        </>
                        :
                        <>
                            <Space.Compact
                                style={{
                                    width: '50%',
                                }}
                            >
                                <Input placeholder='Kode/NIK/nama jamaah'
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                                <Button type="primary"
                                    loading={loadingSerchJamaah}
                                    disabled={loadingSerchJamaah}
                                    onClick={() => searchJamaah(keyword)}
                                >
                                    Cari
                                </Button>
                            </Space.Compact>
                            <Table
                                size="small"
                                style={{ marginTop: 5 }}
                                columns={columnsJamaah}
                                dataSource={dataJamaah || []}
                                loading={loadingSerchJamaah}
                                rowKey={() => generateRandomKey()}
                                pagination={false}
                            />
                        </>

                }

            </Modal>
        </>
    )
};
export default App;