import { connect } from 'react-redux'
import App from './component'
import {
    handleFetchDataUser,
    changeSorter,
    handleFetchDataRole, changeModalNewOpen, handleSaveUser,
    changeUserUpdateData, handleUpdateUser,
    changeUserUpdatePasswordData, handleUpdatePassword,
    changeInactiveToggleData, handleActiveOrInactiveUser,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.userList,
})

const mapDispatchToProps = {
    handleFetchDataUser,
    changeSorter,
    handleFetchDataRole, changeModalNewOpen, handleSaveUser,
    changeUserUpdateData, handleUpdateUser,
    changeUserUpdatePasswordData, handleUpdatePassword,
    changeInactiveToggleData, handleActiveOrInactiveUser,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)