import React, { } from 'react'
import { Modal, Descriptions, Button } from 'antd';
import { rupiahFormat } from "../../../../helpers/currency";
import moment from 'moment';

const App = (props) => {
    const {
        // data
        modalDeleteData,
        // actions
        changeModalDeleteData, handleDeleteData,
    } = props

    return (
        <Modal
            open={(modalDeleteData.hasOwnProperty('id') ? true : false)}
            onCancel={() => changeModalDeleteData({})}
            destroyOnClose
            width={600}
            footer={[
                <Button key="back" type='danger' onClick={() => handleDeleteData(modalDeleteData.id)}>
                    Proses Delete
                </Button>
            ]}
        >
            <Descriptions title="Hapus Pembayaran Ini?" layout="vertical">
                <Descriptions.Item label="Nominal"><strong>{rupiahFormat(modalDeleteData.nominal)}</strong></Descriptions.Item>
                <Descriptions.Item label="Metode Pembayaran"><strong>{modalDeleteData.payment_method}</strong></Descriptions.Item>
                <Descriptions.Item label="Tanggal Bayar"><strong>{(moment(modalDeleteData.created_at).format('DD MMMM YYYY'))}</strong></Descriptions.Item>
                <Descriptions.Item label="PaymentMethods"><strong>{modalDeleteData.kloter_name}</strong></Descriptions.Item>
                <Descriptions.Item label="Nama jamaah"><strong>{modalDeleteData.jamaah_name}</strong></Descriptions.Item>
            </Descriptions>

        </Modal>
    )
}

export default App;

