import { connect } from 'react-redux'
import App from './component'
import {
    handleFetchDataKas, handleFetchDataAccounts, handleSaveKasMasuk,
    changeModalKasKeluarOpen, changeModalKasMasukOpen, handleSaveKasKeluar,
    reset,
    changePage, changeType, changeSorter, changeFilterDescription, changeFilterNoBukti, changeFilterDate,
    changeKasDeleteData, handleDeleteKasSave,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.kasList,
})

const mapDispatchToProps = {
    handleFetchDataKas, handleFetchDataAccounts, handleSaveKasMasuk,
    changeModalKasKeluarOpen, changeModalKasMasukOpen, handleSaveKasKeluar,
    reset,
    changePage, changeType, changeSorter, changeFilterDescription, changeFilterNoBukti, changeFilterDate,
    changeKasDeleteData, handleDeleteKasSave,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)