import React, { useState } from 'react'
import { message, Upload, Button, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { BASE_API_URL } from "../../../../config";

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const App = (props) => {
    const { handleUploadFoto, handleDeleteImage, isImageUploadLoading, jamaah } = props

    const [imageTempUrl, setImageTempUrl] = useState("");

    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            getBase64(info.file.originFileObj, (url) => {
                setImageTempUrl(url);
            });
        }
        if (info.file.status === "removed") {
            setImageTempUrl("");
        }
    };

    if (jamaah.image) {
        const imgSrc = (jamaah.image) ? `${BASE_API_URL}/jamaah/${jamaah.id}/detail/show-foto` : ''
        return (
            <Spin tip="Processing data..." spinning={isImageUploadLoading}>
                <div style={{
                    verticalAlign: "middle",
                    width: "200px",
                }}>
                    <img src={imgSrc} alt="avatar" style={{ width: '100%' }} />

                    <Button
                        style={{ marginTop: "5px" }}
                        block type='danger' loading={isImageUploadLoading} onClick={() => handleDeleteImage(jamaah.id)}
                    >
                        Hapus
                    </Button>

                </div>
            </Spin>
        )
    }

    return (
        <div style={{
            width: "210px",
        }}>
            <Upload
                multiple={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                showUploadList={false}
            >
                {
                    (imageTempUrl)
                        ?
                        <div style={{
                            verticalAlign: "middle",
                            width: "200px",
                        }}>
                            <img src={imageTempUrl} alt="avatar" style={{ width: '100%' }} />
                        </div>
                        :
                        <div>
                            <p>Belum mengisi data foto.</p>
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </div>
                }

            </Upload>
            {
                (imageTempUrl) &&
                <Button
                    block type='primary' loading={isImageUploadLoading} onClick={() => handleUploadFoto(imageTempUrl, jamaah.id)}
                >
                    Simpan Foto
                </Button>
            }
        </div>
    );
}

export default App;
