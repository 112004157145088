import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_LOADING_KLOTER_JAMAAH_DATA:
            return {
                ...state,
                isLoadingKloterJamaahData: action.value
            }
        case type.CHANGE_KLOTER_JAMAAH_DATA:
            return {
                ...state,
                kloterJamaahData: action.value
            }
        case type.CHANGE_LOADING_PAYMENT_SUMARY:
            return {
                ...state,
                isLoadingPaymentSumary: action.value
            }
        case type.CHANGE_PAYMENT_SUMARY:
            return {
                ...state,
                paymentSumary: action.value
            }
        case type.CHANGE_LOADING_PAYMENT_HISTORY:
            return {
                ...state,
                isLoadingPaymentHistory: action.value
            }
        case type.CHANGE_PAYMENT_HISTORY:
            return {
                ...state,
                paymentHistory: action.value
            }
        case type.CHANGE_PAYMENT_HISTORY_SORTER:
            return {
                ...state,
                paymentHistorySorter: action.value
            }
        case type.CHANGE_MODAL_ADD_PAYMENT_SHOW:
            return {
                ...state,
                isModalAddPaymentShow: action.value
            }
        case type.CHANGE_PAYMENT_METHODS_DATA:
            return {
                ...state,
                paymentMethodsData: action.value
            }
        case type.CHANGE_PAYMENT_METHODS_LOADING:
            return {
                ...state,
                isPaymentMethodLoading: action.value
            }
        case type.CHANGE_LOADING_SAVE:
            return {
                ...state,
                isLoadingSave: action.value
            }
        case type.CHANGE_ITEMS:
            return {
                ...state,
                items: action.value
            }
        case type.CHANGE_FETCH_ITEMS_LOADING:
            return {
                ...state,
                fetchItemsLoading: action.value
            }
        case type.CHANGE_MODAL_ITEM_DATA:
            return {
                ...state,
                modalItemData: action.value
            }
        case type.CHANGE_SAVE_ITEM_LOADING:
            return {
                ...state,
                saveItemLoading: action.value
            }
        case type.CHANGE_MODAL_ITEM_DETAIL:
            return {
                ...state,
                modalItemDetail: action.value
            }
        case type.CHANGE_ADDITIONAL_ITEMS:
            return {
                ...state,
                additionalItems: action.value
            }
        case type.CHANGE_ADDITIONAL_ITEMS_CANDIDATE:
            return {
                ...state,
                additionalItemsCandidate: action.value
            }
        case type.CHANGE_FETCH_ADDITIONAL_ITEMS_CANDIDATE_LOADING:
            return {
                ...state,
                additionalItemsCandidateLoading: action.value
            }
        case type.CHANGE_FETCH_ADDITIONAL_ITEMS_LOADING:
            return {
                ...state,
                fetchAdditionalItemsLoading: action.value
            }
        case type.CHANGE_MODAL_ADD_ADDITIONAL_ITEM:
            return {
                ...state,
                modalAdditionalItem: action.value
            }
        case type.CHANGE_ADDITIONAL_ITEM_TO_DELETE:
            return {
                ...state,
                additionalItemToDelete: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        default:
            return state
    }
}

const defaultState = () => ({
    isLoadingKloterJamaahData: true,
    kloterJamaahData: {},

    isLoadingPaymentSumary: true,
    paymentSumary: {},

    isLoadingPaymentHistory: true,
    paymentHistory: [],
    paymentHistorySorter: null,

    isModalAddPaymentShow: false,

    paymentMethodsData:[],
    isPaymentMethodLoading:false,

    isLoadingSave : false,

    items : [],
    fetchItemsLoading : true,
    modalItemData:{},
    saveItemLoading:false,

    modalItemDetail:{},

    additionalItems : [],
    fetchAdditionalItemsLoading : true,
    modalAdditionalItem : false,

    additionalItemsCandidate : [],
    additionalItemsCandidateLoading : false,

    additionalItemToDelete:{},

})

export default main