import { Delete, Get, Post, Put } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "KLOTER_ROOM_SETTING"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_FETCH_ROOM_LOADING: `${key}_CHANGE_FETCH_ROOM_LOADING`,
    CHANGE_ROOM_DATA: `${key}_CHANGE_ROOM_DATA`,
    CHANGE_ROOM_DATA_SELECT_OPTION: `${key}_CHANGE_ROOM_DATA_SELECT_OPTION`,
    CHANGE_MODAL_ADD_ROOM_OPEN: `${key}_CHANGE_MODAL_ADD_ROOM_OPEN`,
    CHANGE_SAVE_ROOM_LOADING: `${key}_CHANGE_SAVE_ROOM_LOADING`,
    CHANGE_MODAL_UPDATE_ROOM_DATA: `${key}_CHANGE_MODAL_UPDATE_ROOM_DATA`,
    CHANGE_UPDATE_ROOM_LOADING: `${key}_CHANGE_UPDATE_ROOM_LOADING`,
    CHANGE_JAMAAH_DATA: `${key}_CHANGE_JAMAAH_DATA`,
    CHANGE_FETCH_JAMAAH_DATA_LOADING: `${key}_CHANGE_FETCH_JAMAAH_DATA_LOADING`,
    CHANGE_MODAL_DELETE_ROOM_DATA: `${key}_CHANGE_MODAL_DELETE_ROOM_DATA`,
    CHANGE_DELETE_ROOM_LOADING: `${key}_CHANGE_DELETE_ROOM_LOADING`,
    CHANGE_ADD_TOO_ROOM_LOADING: `${key}_CHANGE_ADD_TOO_ROOM_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeFetchRoomLoading = (value) => ({
    type: type.CHANGE_FETCH_ROOM_LOADING,
    value
})

export const changeRoomData = (value) => ({
    type: type.CHANGE_ROOM_DATA,
    value
})

export const changeRoomDataSelectOption = (value) => ({
    type: type.CHANGE_ROOM_DATA_SELECT_OPTION,
    value
})

export const changeModalAddRoomOpen = (value) => ({
    type: type.CHANGE_MODAL_ADD_ROOM_OPEN,
    value
})

export const changeSaveRoomLoading = (value) => ({
    type: type.CHANGE_SAVE_ROOM_LOADING,
    value
})

export const changeModalUpdateRoomData = (value) => ({
    type: type.CHANGE_MODAL_UPDATE_ROOM_DATA,
    value
})

export const changeUpdateRoomLoading = (value) => ({
    type: type.CHANGE_UPDATE_ROOM_LOADING,
    value
})

export const changeJamaahData = (value) => ({
    type: type.CHANGE_JAMAAH_DATA,
    value
})

export const changeFetchJamaahDataLoading = (value) => ({
    type: type.CHANGE_FETCH_JAMAAH_DATA_LOADING,
    value
})

export const changeModalDeleteRoomData = (value) => ({
    type: type.CHANGE_MODAL_DELETE_ROOM_DATA,
    value
})

export const changeDeleteRoomLoading = (value) => ({
    type: type.CHANGE_DELETE_ROOM_LOADING,
    value
})

export const changeAddToRoomLoading = (value) => ({
    type: type.CHANGE_ADD_TOO_ROOM_LOADING,
    value
})

export const handleFetchRoomData = (kloter_id) => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/kloter-rooms/${kloter_id}`

        dispatch(changeFetchRoomLoading(true))
        const res = await Get(url);
        dispatch(changeFetchRoomLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        await dispatch(changeRoomData(res?.data || []))

        const room_data_select = [];
        if (res.data) {
            res.data.forEach(data => {
                const temp_val = {
                    label: `${data.name} (Terisi ${data.occupancy})`,
                    value: data.id,
                }
                room_data_select.push(temp_val);
            });
        }

        await dispatch(changeRoomDataSelectOption(room_data_select))
        return
    }
}

export const handleSaveRoomData = (kloter_id, data) => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/kloter-rooms/${kloter_id}`

        dispatch(changeSaveRoomLoading(true))
        const res = await Post(url, data);
        dispatch(changeSaveRoomLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeModalAddRoomOpen(false))
        dispatch(handleFetchRoomData(kloter_id))
        return
    }
}

export const handleUpdateRoom = (id, data, kloter_id) => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/kloter-rooms/update/${id}`

        dispatch(changeUpdateRoomLoading(true))
        const res = await Put(url, data);
        dispatch(changeUpdateRoomLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }
        await dispatch(changeModalUpdateRoomData({}))
        await dispatch(handleQueue(kloter_id))
        return
    }
}

export const handleDeleteRoom = (id, kloter_id) => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/kloter-rooms/delete/${id}`

        await dispatch(changeDeleteRoomLoading(true))
        const res = await Delete(url);
        await dispatch(changeDeleteRoomLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeModalDeleteRoomData({}))
        dispatch(handleFetchRoomData(kloter_id))
        return
    }
}

export const handleFetchJamaahData = (kloterId) => {
    return async (dispatch, getState) => {

        let url = `${BASE_API_URL}/kloters-jamaahs/${kloterId}/list-jamaah?per_page=1000`

        await dispatch(changeFetchJamaahDataLoading(true))
        const res = await Get(url);
        await dispatch(changeFetchJamaahDataLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeJamaahData(res || {}))
        return
    }
}

export const handleSaveAddToRoom = (kloter_room_id, payload, kloter_id) => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/kloter-room-jamaah/${kloter_room_id}`

        await dispatch(changeAddToRoomLoading(true))
        const res = await Post(url, payload);
        await dispatch(changeAddToRoomLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(handleQueue(kloter_id))
        return
    }
}

export const handleQueue = (kloter_id) => {
    return async (dispatch, getState) => {
        await dispatch(handleFetchRoomData(kloter_id))
        await dispatch(handleFetchJamaahData(kloter_id))
    }
}

export default type