import React, {
    useState,
} from 'react';
import {
    Table, Input,
    Row, Col, Button, Modal, Select
} from 'antd';
import { Link, useParams, } from "react-router-dom";


import { truncateText } from "../../../../helpers/randomThings";

export default function App(props) {
    const [selectedRows, setSelectedRows] = useState([])
    const [modalAddToRoomOpen, setModalAddToRoomOpen] = useState(false)
    const {
        // actions
        fetchJamaahDataLoading,
        // data
        jamaahData,
    } = props

    const columnsJamaah = [
        {
            title: 'Kode',
            dataIndex: 'kode',
            filterSearch: true,
            width: "100px",
        },
        {
            title: 'Nama Jamaah',
            dataIndex: 'name',
            width: "250px",
            render: (text, record) => <Link to={`/kloters-jamaahs/detail/${record.kloters_jamaahs_id}`}>
                {truncateText(text, 25)}
            </Link>,
        },
        {
            title: `J/K`,
            dataIndex: 'gender',
            className: "gender",
            width: "20px",
            render: (text) => <>{(text === 'male') ? "Pria" : "Wanita"}</>,
        },
        {
            title: `Kamar`,
            dataIndex: 'room',
        },
        {
            title: `Keterangan`,
            dataIndex: 'room_description',
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <div>
            <ModalAddToRoom {...props}
                jamaahs={selectedRows}
                modalOpen={modalAddToRoomOpen}
                modalClose={() => setModalAddToRoomOpen(false)}
            />
            <Row>
                <Col xs={24} sm={24} md={8} lg={{ span: 4, offset: 20 }} style={{ textAlign: 'right' }}>
                    <Button
                        type="primary" onClick={() => setModalAddToRoomOpen(true)}
                        disabled={
                            (selectedRows.length > 0) ? false : true
                        }
                    >
                        Daftarkan Ke Kamar
                    </Button>
                </Col>
            </Row>
            <Table
                rowSelection={{
                    type: "Checkbox",
                    ...rowSelection,
                }}
                style={{ marginTop: 5 }}
                columns={columnsJamaah}
                dataSource={jamaahData.data}
                loading={fetchJamaahDataLoading}
                rowKey={data => data.kloters_jamaahs_id}
                pagination={false}
                size="small"
            />
        </div>
    )
}

const ModalAddToRoom = (props) => {
    const [selectKloterRoomId, setSelectedKloterRoomId] = useState(null)
    const [description, setDescription] = useState('')
    const { kloter_id } = useParams()

    const {
        roomDataSelectOption, handleSaveAddToRoom, addToRoomLoading,
    } = props

    const handleSaveAddToRoomClick = async () => {
        if(description===''){
            alert("Keterangan harus diisi")
            return
        }
        const kloters_jamaahs_id_arr = props.jamaahs.map(entry => entry.kloters_jamaahs_id);
        const payload = {
            kloters_jamaahs_id: kloters_jamaahs_id_arr,
            description
        }
        await handleSaveAddToRoom(selectKloterRoomId, payload, kloter_id)
        onCancel()
        return
    }

    const onCancel = () => {
        setSelectedKloterRoomId(null)
        setDescription('')
        props.modalClose()
        return
    }

    return (
        <>
            <Modal
                title="Daftarkan Ke Kamar"
                open={props.modalOpen}
                onCancel={onCancel}
                width={700}
                destroyOnClose
                footer={[
                    <Button key="cancel" type='default' onClick={onCancel} loading={addToRoomLoading}>
                        Batalkan
                    </Button>,
                    <Button key="ok" type='primary' loading={addToRoomLoading}
                        disabled={(selectKloterRoomId === null) ? true : false}
                        onClick={handleSaveAddToRoomClick}
                    >
                        Konfirmasi
                    </Button>,
                ]}
            >
                <Table
                    style={{ marginTop: 5 }}
                    columns={
                        [
                            {
                                title: 'Kode',
                                dataIndex: 'kode',
                                filterSearch: true,
                                width: "50px",
                            },
                            {
                                title: 'Nama Jamaah',
                                dataIndex: 'name',
                                width: "220px",
                                render: (text, record) => <>{truncateText(text, 22)}</>,
                            },
                            {
                                title: `J/K`,
                                dataIndex: 'gender',
                                className: "gender",
                                width: "20px",
                                render: (text) => <>{(text === 'male') ? "Pria" : "Wanita"}</>,
                            },
                        ]
                    }
                    dataSource={props.jamaahs}
                    rowKey={data => data.kloters_jamaahs_id}
                    pagination={false}
                    size="small"
                />
                <Select
                    style={{
                        zIndex: "1000", // Sesuaikan z-index sesuai kebutuhan
                        width: "100%",
                    }}
                    placeholder={"Pilih Kamar"}
                    options={roomDataSelectOption}
                    onChange={setSelectedKloterRoomId}
                />
                Keterangan:
                <Input.TextArea
                    style={{
                        width: "100%",
                    }}
                    onInput={e => e.target.value = e.target.value.toUpperCase()}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Contoh: Keluarga"
                />
            </Modal>
        </>
    )
};
