import { Get, Post } from "../../../helpers/ajax";
import { BASE_API_URL, BASE_URL } from "../../../config";

import { notification } from 'antd';

const key = "JAMAAH_NEW"

export const gender = {
    male: 'male',
    female: 'female'
}

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING_SAVE: `${key}_CHANGE_LOADING_SAVE`,
    CHANGE_NIK_OPTIONS: `${key}_CHANGE_NIK_OPTIONS`,
    CHANGE_PROVINSI_OPTIONS: `${key}_CHANGE_PROVINSI_OPTIONS`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoadingSave = (value) => ({
    type: type.CHANGE_LOADING_SAVE,
    value
});

export const changeNikOptions = (value) => ({
    type: type.CHANGE_NIK_OPTIONS,
    value
})

export const changeProvinsiOptions = (value) => ({
    type: type.CHANGE_PROVINSI_OPTIONS,
    value
})

export const handleSaveNewJamaah = (data) => {
    return async (dispatch, getState) => {

        await dispatch(changeLoadingSave(true))
        const res = await Post(`${BASE_API_URL}/jamaah/new`, data);
        await dispatch(changeLoadingSave(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        window.location.replace(`${BASE_URL}/jamaah/detail/${res.data.id}`);
        return
    }
}

export const handleSearchNik = (q) => {
    return async (dispatch, getState) => {
        const res = await Get(`${BASE_API_URL}/jamaah/short?q=${q}`);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        let options = []
        if (res?.data.length > 0) {
            options = await res?.data.map((data, index) => {
                return {
                    value: data.nik,
                    label: (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span>
                                <a
                                    href={`${BASE_URL}/jamaah/detail/${data.id}`}
                                >
                                    {data.nik} - {data.name}
                                </a>
                            </span>
                        </div>
                    ),
                };
            })
        } else {
            options = [
                {
                    value: q,
                    label: (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span>
                                {q} - Jamaah Baru
                            </span>
                        </div>
                    ),
                }
            ]
        }

        await dispatch(changeNikOptions(options));
        return
    }
}

export const handleExample = () => {
    return async (dispatch, getState) => {
        return
    }
}

export default type