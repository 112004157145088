import { Post } from "../../../helpers/ajax";
import { BASE_API_URL, BASE_URL } from "../../../config";

import { notification } from 'antd';

const key = "PACKAGE_NEW"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const handleSavePackage = (data) => {
    return async (dispatch, getState) => {
        dispatch(changeLoading(true))
        const res = await Post(`${BASE_API_URL}/packages`, data);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        window.location.replace(`${BASE_URL}/packages/detail/${res.data.id}`);

        return
    }
}

export default type