import React, { } from 'react';
import { useNavigate } from "react-router-dom";
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import moment from "moment";
import jwt_decode from "jwt-decode";

import './bukti-pendaftaran.css';
import { BASE_URL } from "../../../../../config";

import { useFetchDataToPrint } from "./PrintBuktiPendaftaranActionHook"
import { getAccessToken } from "../../../../../library/userAuth";
const accessToken = getAccessToken();

const App = () => {
    let { id } = useParams();
    const { data, loading, fetchData } = useFetchDataToPrint();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!accessToken) {
            navigate("/login")
        }

        fetchData(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        jamaah_kode, jamaah_name, jamaah_nik, jamaah_pob, jamaah_dob,
        jamaah_address, desa, kecamatan, kabupaten, provinsi,
        jamaah_gender, jamaah_phone,
        kloter_name, flying_date
    } = data

    return (
        <>
            <Spin spinning={loading}>
                <div className="book">
                    <div className="page">
                        <div className="row justify">
                            <div className="logo">
                                <img src={`${BASE_URL}/logo.png`} alt="Logo" />
                            </div>
                            <div className="identitas">
                                <div className="judul">
                                    PT.ZAM ZAM TOUR & TRAVEL
                                </div>
                                <div className="alamat">
                                    Jl. Brigjend Katamso No. 90<br />
                                    Pandean, Jetis, Sukoharjo, Jawa Tengah 57511, INDONESIA <br />
                                    No. Telp : (0271) 593730<br />
                                    Email : zamzam.sukoharjo@gmail.com
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="shapeshort"></div>
                            <div className="shapeshort2"></div>
                            <div className="shapeshort3"></div>
                        </div>
                        <div className="row">
                            <div className="form-judul">
                                Bukti Pendaftaran Umrah
                            </div>
                        </div>
                        <div className="row">
                            <div className="nomor">
                                Nomor : {jamaah_kode}
                            </div>
                        </div>
                        <div className="row">
                            <div className="shape">
                                <div className="paket">
                                    A. PAKET PILIHAN
                                </div>
                            </div>
                        </div>
                        {/* Pilihan Paket */}
                        <div className="row">
                            <div className="paketumrah">
                                <table>
                                    <tr>
                                        <td width="200px">Paket Umrah</td>
                                        <td>:</td>
                                        <td>{kloter_name}</td>
                                    </tr>
                                    <tr
                                        style={{
                                            verticalAlign: 'top',
                                        }}
                                    >
                                        <td width="200px">Estimasi Tanggal Terbang</td>
                                        <td>:</td>
                                        <td>{moment(flying_date).format('DD MMMM YYYY')}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="shape">
                                <div className="datacalon">
                                    B. DATA CALON JAMAAH UMRAH
                                </div>
                            </div>
                        </div>

                        {/* Isi Data Jamaah */}
                        <div className="row">
                            <div className="datacalonumroh">
                                <table>
                                    <tr>
                                        <td width="200px">Nama Lengkap</td>
                                        <td>:</td>
                                        <td>{jamaah_name}</td>
                                    </tr>
                                    <tr>
                                        <td width="200px">No Hp/WA</td>
                                        <td>:</td>
                                        <td>{jamaah_phone}</td>
                                    </tr>

                                    <tr>
                                        <td width="200px">NIK</td>
                                        <td>:</td>
                                        <td>{jamaah_nik}</td>
                                    </tr>

                                    <tr>
                                        <td width="200px">Jenis Kelamin</td>
                                        <td>:</td>
                                        <td>{(jamaah_gender === "male") && "Pria"} {(jamaah_gender === "female") && "Wanita"}</td>
                                    </tr>

                                    <tr>
                                        <td width="200px">Tempat, Tanggal Lahit</td>
                                        <td>:</td>
                                        <td>{jamaah_pob}, {moment(jamaah_dob).format('DD MMMM YYYY')}</td>
                                    </tr>

                                    <tr
                                        style={{
                                            verticalAlign: 'top',
                                        }}
                                    >
                                        <td width="200px">Alamat</td>
                                        <td>:</td>
                                        <td>
                                            {jamaah_address} <br />
                                            {desa}   {(kecamatan) && ", "} {kecamatan} {kecamatan} {(kabupaten) && ", "} {kabupaten} {(provinsi) && ", "} {provinsi}

                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </div>

                        <div className="row">
                            <div className="shape">
                                <div className="paket">
                                    C. DATA PETUGAS
                                </div>
                            </div>
                        </div>
                        {/* Pilihan Paket */}
                        <div className="row">
                            <div className="paketumrah">
                                <table>
                                    <tr>
                                        <td width="200px">Nama Petugas</td>
                                        <td>:</td>
                                        <td>{jwt_decode(accessToken).name}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        {/* Signature */}
                        <table style={{ float: 'right', textAlign: 'center' }}>
                            <tr>
                                <td></td>
                                <td>
                                    <div style={{ textAlign: "right" }}>
                                        {moment().format('DD MMMM YYYY HH:mm:ss')}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td width="250px">Calon Jamaah Umrah</td>
                                <td width="250px">Petugas Zam Zam Tour & Travel</td>
                            </tr>
                            <tr height="200px">
                                <td>(...............................)</td>
                                <td>Admin - {jwt_decode(accessToken).name} <br /></td>
                            </tr>
                        </table>

                    </div>
                </div>
            </Spin>
        </>

    );
}

export default App;
