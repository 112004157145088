import { Delete, Get, Post, Put } from "../../../../../helpers/ajax";
import { BASE_API_URL } from "../../../../../config";

import { notification } from 'antd';

const key = "KLOTER_DETAIL_ADDITIONAL_ITEM"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_FETCH_LOADING: `${key}_CHANGE_FETCH_LOADING`,
    CHANGE_ADDITIONAL_ITEMS: `${key}_CHANGE_ADDITIONAL_ITEMS`,
    CHANGE_MODAL_ADD_OPEN: `${key}_CHANGE_MODAL_ADD_OPEN`,
    CHANGE_ADDITIONAL_ITEM_UPDATE: `${key}_CHANGE_ADDITIONAL_ITEM_UPDATE`,
    CHANGE_ADDITIONAL_ITEM_DELETE: `${key}_CHANGE_ADDITIONAL_ITEM_DELETE`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeFetchLoading = (value) => ({
    type: type.CHANGE_FETCH_LOADING,
    value
})

export const changeAdditionalItems = (value) => ({
    type: type.CHANGE_ADDITIONAL_ITEMS,
    value
})

export const changeModalAddOpen = (value) => ({
    type: type.CHANGE_MODAL_ADD_OPEN,
    value
})

export const changeAdditionalItemUpdate = (value) => ({
    type: type.CHANGE_ADDITIONAL_ITEM_UPDATE,
    value
})

export const changeAdditionalItemDelete = (value) => ({
    type: type.CHANGE_ADDITIONAL_ITEM_DELETE,
    value
})

export const handleFetchAdditionalItems = (kloterId) => {
    return async (dispatch, getState) => {
        dispatch(changeFetchLoading(true))
        const res = await Get(`${BASE_API_URL}/additional-items/${kloterId}`);
        dispatch(changeFetchLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeAdditionalItems(res?.data || []))
        return
    }
}

export const handleSaveAdditionalItem = (kloterId, data) => {
    return async (dispatch, getState) => {
        const res = await Post(`${BASE_API_URL}/additional-items/${kloterId}`, data);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeModalAddOpen(false))
        await dispatch(handleFetchAdditionalItems(kloterId))
        return
    }
}

export const handleUpdateAdditionalItem = (kloterId, id, data) => {
    return async (dispatch, getState) => {
        const res = await Put(`${BASE_API_URL}/additional-items/${id}/update`, data);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeAdditionalItemUpdate({}))
        await dispatch(handleFetchAdditionalItems(kloterId))
        return
    }
}

export const handleDeleteAdditionalItem = (kloterId, id) => {
    return async (dispatch, getState) => {
        const res = await Delete(`${BASE_API_URL}/additional-items/${id}/delete`);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        await dispatch(changeAdditionalItemDelete({}))
        await dispatch(handleFetchAdditionalItems(kloterId))
        return
    }
}

export default type