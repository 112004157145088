/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useEffect, useState,
    // useRef
} from 'react';
import { Table, Card, Select, Button } from 'antd';
// import moment from 'moment';
import { Link } from "react-router-dom";

import { rupiahFormat } from "../../../../helpers/currency";
import { useRecapPayments, useFetchKloters } from "./RecapPaymentsActionHook"

const App = () => {
    const [selectKloterId, setSelectedKloterId] = useState(null)
    const { data, loading, fetchRecapPayments } = useRecapPayments();
    const { kloters, fetchKlotersLoading, fetchKloters } = useFetchKloters();

    useEffect(() => {
        fetchKloters()
    }, [])

    const handleFetchRecapPayments = () => {
        if (selectKloterId === null) {
            return
        }
        fetchRecapPayments(selectKloterId)
        return
    }

    const columns = [
        {
            title: 'Nama/Kode',
            dataIndex: 'kode',
            align: 'left',
            render: (text, record) => {
                return (
                    <Link to={`/kloters-jamaahs/detail/${record.kloters_jamaah_id}`}>{record.jamaah_name} / {record.jamaah_kode}</Link>
                )
            },
        },
        {
            title: 'Total Biaya',
            dataIndex: 'total_cost',
            align: 'left',
            // width: "50px",
            render: (text, record) => rupiahFormat(text),
        },
        {
            title: 'Terbayar',
            dataIndex: 'verified_paid',
            align: 'left',
            render: (text, record) => rupiahFormat(text),
        },
        {
            title: 'Kekurangan',
            dataIndex: 'shortfall',
            align: 'left',
            render: (text, record) => rupiahFormat(text),
        },
    ]

    return (
        <>
            <Card title="Rekap Summary Pembayaran">
                <span>
                    <Select
                        loading={fetchKlotersLoading}
                        style={{
                            width: 300,
                        }}
                        options={kloters}
                        onChange={setSelectedKloterId}
                    />
                    <Button type='primary' onClick={() => handleFetchRecapPayments()}>Set</Button>
                </span>

                <Table
                    style={{ marginTop: 5 }}
                    columns={columns}
                    dataSource={data?.recap || []}
                    loading={loading}
                    rowKey={items => items.id}
                    pagination={false}
                    size="small"
                />
                {
                    (data) &&
                    <>
                        <h1><strong>Total Terbayar: {rupiahFormat(data?.total_verified_paid)}</strong></h1>
                        <h1><strong>Total Kekurangan: {rupiahFormat(data?.total_shortfall)}</strong></h1>
                    </>
                }

            </Card>
        </>
    );
};


export default App;