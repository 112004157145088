import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_ACCOUNTS_DATA:
            return {
                ...state,
                accountData: action.value
            }
        case type.CHANGE_STATUS:
            return {
                ...state,
                status: action.value
            }
        case type.CHANGE_SORTER:
            return {
                ...state,
                sorter: action.value
            }
        case type.CHANGE_PAGE:
            return {
                ...state,
                page: action.value
            }
        case type.CHANGE_ACCOUNT_UPDATE_DATA:
            return {
                ...state,
                accountUpdateData: action.value
            }
        case type.CHANGE_UPDATE_LOADING:
            return {
                ...state,
                updateLoading: action.value
            }
        case type.CHANGE_ACCOUNT_DELETE_DATA:
            return {
                ...state,
                accountDeleteData: action.value
            }
        case type.CHANGE_DELETE_LOADING:
            return {
                ...state,
                deleteLoading: action.value
            }
        case type.CHANGE_MODAL_NEW_OPEN:
            return {
                ...state,
                modalNewOpen: action.value
            }
        case type.CHANGE_SAVE_LOADING:
            return {
                ...state,
                saveLoading: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    accountData: {},

    // pagination
    page: 1,
    perPage: 200,

    // filter and sort
    status: "",
    sorter: null,

    accountUpdateData: {},
    updateLoading: false,

    accountDeleteData: {},
    deleteLoading: false,

    modalNewOpen: false,
    saveLoading: false,
})

export default main