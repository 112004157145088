import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import './style.css';
import { getAccessToken } from "../../library/userAuth";

const RegisterForm = (props) => {
    const [password, setPassword] = useState(null)
    const [rePassword, setRePassword] = useState(null)
    const navigate = useNavigate();

    const { isLoading, handleRegister } = props

    const onFinish = values => {
        handleRegister(values)
    };

    useEffect(() => {
        document.title = 'Register Form';
        const accessToken = getAccessToken();
        if (accessToken) {
            navigate("/")
        }

    }, [navigate]);

    const rePasswordValid = () => {
        if (rePassword === null) {
            return Promise.reject(new Error("'re-password' is required"))
        }
        if (rePassword !== password) {
            return Promise.reject(new Error('Konfirmasi password salah!'))
        }
        return Promise.resolve()
    }

    return (
        <div className='register-container'>
            <Form
                name="normal_register"
                className="register-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Nama" />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            min: 8, max: 12,
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        defaultValue={password}
                        value={password}
                        onChange={(val) => setPassword(val.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    name="rePassword"
                    rules={[{ validator: rePasswordValid }]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Ulangi Password"
                        defaultValue={rePassword}
                        value={rePassword}
                        onChange={(val) => setRePassword(val.target.value)}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="register-form-button" loading={isLoading} disabled={isLoading}>
                        Daftar
                    </Button>
                    Or <Link to={"/login"}>Login now!</Link>
                </Form.Item>
            </Form>
        </div>
    );
};



export default RegisterForm;