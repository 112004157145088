import {
    type
} from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_LOADING_SAVE:
            return {
                ...state,
                isLoadingSave: action.value
            }
        case type.CHANGE_NIK_OPTIONS:
            return {
                ...state,
                nikOptions: action.value
            }
        case type.CHANGE_PROVINSI_OPTIONS:
            return {
                ...state,
                provinsiOptions: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoadingSave: false,
    nikOptions: [],
    provinsiOptions: [],
})

export default main