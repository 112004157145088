import { Post, Get } from "../../../helpers/ajax";
import { BASE_API_URL, BASE_URL } from "../../../config";
import { convert } from "../../../helpers/date";

import { notification } from 'antd';

const key = "KLOTER_NEW"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_PACKAGES: `${key}_CHANGE_PACKAGES`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changePackages = (value) => ({
    type: type.CHANGE_PACKAGES,
    value
})

export const handleSaveKloter = (data) => {
    return async (dispatch, getState) => {
        data.flying_date = convert(data.flying_date)

        dispatch(changeLoading(true))
        const res = await Post(`${BASE_API_URL}/kloters`, data);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        window.location.replace(`${BASE_URL}/kloters/detail/${res.data.id}`);

        return
    }
}

export const handleGetPackages = () => {
    return async (dispatch, getState) => {
        const res = await Get(`${BASE_API_URL}/packages`);
        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }


        let packages = []
        res.data.forEach((val) => {
            let temp = {
                title: val.name,
                value: val.id,
            };
            packages.push(temp)
        });

        dispatch(changePackages(packages))

        return
    }
}

export default type