import { Get, Post } from "../../../helpers/ajax";
import { BASE_API_URL, BASE_URL } from "../../../config";

import { notification } from 'antd';

const key = "BRANCH_NEW"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_LOADING_REGION: `${key}_CHANGE_LOADING_REGION`,
    CHANGE_REGION: `${key}_CHANGE_REGION`,
    CHANGE_KECAMATAN_ID: `${key}_CHANGE_KECAMATAN_ID`,
    CHANGE_IS_KODE_VALID: `${key}_CHANGE_IS_KODE_VALID`,
    CHANGE_IS_EMAIL_VALID: `${key}_CHANGE_IS_EMAIL_VALID`,
    CHANGE_IS_PHONE_VALID: `${key}_CHANGE_IS_PHONE_VALID`,
    CHANGE_IS_OWNER_PHONE_VALID: `${key}_CHANGE_IS_OWNER_PHONE_VALID`,
    CHANGE_IS_OWNER_NIK_VALID: `${key}_CHANGE_IS_OWNER_NIK_VALID`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeLoadingRegion = (value) => ({
    type: type.CHANGE_LOADING_REGION,
    value
})

export const changeRegion = (value) => ({
    type: type.CHANGE_REGION,
    value
})

export const changeKecamatanId = (value) => ({
    type: type.CHANGE_KECAMATAN_ID,
    value
})

export const changeIsKodeValid = (value) => ({
    type: type.CHANGE_IS_KODE_VALID,
    value
})

export const changeIsEmailValid = (value) => ({
    type: type.CHANGE_IS_EMAIL_VALID,
    value
})

export const changeIsPhoneValid = (value) => ({
    type: type.CHANGE_IS_PHONE_VALID,
    value
})

export const changeIsOwnerPhoneValid = (value) => ({
    type: type.CHANGE_IS_OWNER_PHONE_VALID,
    value
})

export const changeIsOwnerNIKValid = (value) => ({
    type: type.CHANGE_IS_OWNER_NIK_VALID,
    value
})

export const handleGetRegions = (name = "") => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/region/kecamatans`;

        if (name) {
            url = `${BASE_API_URL}/region/kecamatans?name=${name}`;
        }

        dispatch(changeLoadingRegion(true))
        const res = await Get(url);
        dispatch(changeLoadingRegion(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        let kecamatans = res.data || [];
        let regions = [{
            disabled: true,
            value: "Pilih Kecamatan",
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    Pilih Kecamatan
                </div>
            ),
        }];

        kecamatans.map((kecamatan, index) => {
            return regions.push(renderRegionItem(kecamatan.id, kecamatan.name, kecamatan.kabupaten.name, kecamatan.kabupaten.provinsi.name))
        })

        if (kecamatans.length < 1) {
            regions = [{
                disabled: true,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        Kecamatan tidak ditemukan
                    </div>
                ),
            }]
        }

        dispatch(changeRegion(regions))

        return
    }
}

export const handleNewBranchSave = (value) => {
    return async (dispatch, getState) => {

        const state = getState()
        const { kecamatanId } = state.branchNew

        value.kecamatan_id = kecamatanId

        dispatch(changeLoading(true))
        const res = await Post(`${BASE_API_URL}/branches/new`, value);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        window.location.replace(`${BASE_URL}/branch/detail/${res.data.id}`);

        return
    }
}

export const handleCheckKodeIsTaken = (kode) => {
    return async (dispatch, getState) => {
        if(!kode){
            return
        }

        const res = await Get(`${BASE_API_URL}/branches/is-kode-taken?kode=${kode}`);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        if(res.data.is_taken){
            dispatch(changeIsKodeValid(false))
            return
        }

        dispatch(changeIsKodeValid(true))
        return;
    }
}

export const handleCheckEmailIsTaken = (email) => {
    return async (dispatch, getState) => {
        if(!email){
            return
        }

        const res = await Get(`${BASE_API_URL}/branches/is-email-taken?email=${email}`);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        if(res.data.is_taken){
            dispatch(changeIsEmailValid(false))
            return
        }

        dispatch(changeIsEmailValid(true))
        return;
    }
}

export const handleCheckPhoneIsTaken = (phone) => {
    return async (dispatch, getState) => {
        if(!phone){
            return
        }

        if(isNaN(phone)){
            return
        }

        const res = await Get(`${BASE_API_URL}/branches/is-phone-taken?phone=${phone}`);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        if(res.data.is_taken){
            dispatch(changeIsPhoneValid(false))
            return
        }

        dispatch(changeIsPhoneValid(true))
        return;
    }
}

export const handleCheckOwnerPhoneIsTaken = (phone) => {
    return async (dispatch, getState) => {
        if(!phone){
            return
        }

        if(isNaN(phone)){
            return
        }

        const res = await Get(`${BASE_API_URL}/branches/is-owner-phone-taken?phone=${phone}`);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        if(res.data.is_taken){
            dispatch(changeIsOwnerPhoneValid(false))
            return
        }

        dispatch(changeIsOwnerPhoneValid(true))
        return;
    }
}

export const handleCheckOwnerNIKIsTaken = (nik) => {
    return async (dispatch, getState) => {
        if(!nik){
            return
        }

        if(isNaN(nik)){
            return
        }

        const res = await Get(`${BASE_API_URL}/branches/is-owner-nik-taken?nik=${nik}`);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        if(res.data.is_taken){
            dispatch(changeIsOwnerNIKValid(false))
            return
        }

        dispatch(changeIsOwnerNIKValid(true))
        return;
    }
}

const renderRegionItem = (id, kecamatan, kabupaten, propinsi) => ({
    key: id,
    value: `${kecamatan}`,
    label: (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            {kecamatan} <strong>{kabupaten}, {propinsi}</strong>
        </div>
    ),
});

export default type