import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_FETCH_LOADING:
            return {
                ...state,
                isFetchLoading: action.value
            }
        case type.CHANGE_KLOTER_ESTIMATION:
            return {
                ...state,
                kloterEstimation: action.value
            }
        case type.CHANGE_KLOTER_NOTE:
            return {
                ...state,
                kloterNote: action.value
            }
        case type.CHANGE_MODAL_ADD_OPEN:
            return {
                ...state,
                modalAddOpen: action.value
            }
        case type.CHANGE_KLOTER_ESTIMATION_UPDATE:
            return {
                ...state,
                kloterEstimationUpdate: action.value
            }
        case type.CHANGE_KLOTER_ESTIMATION_DELETE:
            return {
                ...state,
                kloterEstimationDelete: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_KLOTER_ESTIMATION_NOTE:
            return {
                ...state,
                kloterEstimationNote: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isFetchLoading: false,
    kloterEstimation: {},
    kloterNote: null,
    modalAddOpen: false,
    kloterEstimationUpdate: {},
    kloterEstimationDelete: {},
    kloterEstimationNote: null,
})

export default main