/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import { Modal, Button, Input, Image } from 'antd';
const Textarea = Input.TextArea

const App = (props) => {
    const [description, setDescription] = useState(null)
    const {
        // data
        modalVerificationData, verifyLoading,
        // actions
        changeModalVerificationData, verifyProcess,
    } = props

    const handleVerify = (status) => {
        if (status === 2 && !description) {
            alert("Saat reject deskripsi catatan harus diisi")
            return
        }
        let data = {
            status
        }

        if (description) {
            data.description = description
        }

        verifyProcess(modalVerificationData.target_id, data)
        return
    }

    return (
        <Modal
            open={(modalVerificationData.hasOwnProperty('target_id') ? true : false)}
            onCancel={() => changeModalVerificationData({})}
            destroyOnClose
            width={600}
            footer={[
                <Button key="reject" type='danger'
                    onClick={() => handleVerify(2)}
                    loading={verifyLoading}
                    disabled={(modalVerificationData?.status == 1 || modalVerificationData?.status == 2) ? true : false}
                >
                    Reject
                </Button>,
                <Button key="ok" type='primary'
                    onClick={() => handleVerify(1)}
                    loading={verifyLoading}
                    disabled={(modalVerificationData?.status == 1 || modalVerificationData?.status == 2) ? true : false}
                >
                    Approve
                </Button>
            ]}
        >
            <h1>
                {modalVerificationData?.itemName}
            </h1>
            {
                (modalVerificationData?.files)
                &&
                <Image.PreviewGroup
                    preview={{
                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                    }}
                >
                    {
                        modalVerificationData.files.map((image) => (
                            <Image width={200} src={image} />
                        ))
                    }
                </Image.PreviewGroup>
            }
            <h3>
                Status:
                {
                    // eslint-disable-next-line eqeqeq
                    (modalVerificationData.status == 0) &&
                    <span>Menunggu Approve</span>
                }
                {
                    // eslint-disable-next-line eqeqeq
                    (modalVerificationData.status == 1) &&
                    <span>ok</span>
                }
                {
                    // eslint-disable-next-line eqeqeq
                    (modalVerificationData.status == 2) &&
                    <span>Rejected</span>
                }
            </h3>
            <p>
                Catatan: {modalVerificationData.description}
            </p>
            {
                // eslint-disable-next-line eqeqeq
                (modalVerificationData?.status == 0 || modalVerificationData?.status == null) &&
                <>
                    Catatan: <br />
                    <Textarea
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                </>
            }
        </Modal>
    )
}

export default App;

