import { useState } from 'react';
import { notification } from 'antd';
import { BASE_API_URL } from "../../../config";
import { Get } from "../../../helpers/ajax";


export const useFetchKloter = () => {
    const [kloter, setKloter] = useState(null);
    const [fetchKloterLoading, setFetchKloterLoading] = useState(false);

    const fetchKloter = async (id) => {
        setFetchKloterLoading(true)
        const res = await Get(`${BASE_API_URL}/kloters/${id}`);
        if (!res) {
            setFetchKloterLoading(false)
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            setFetchKloterLoading(false)
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        setFetchKloterLoading(false)
        setKloter(res?.data)
        return
    };

    return { kloter, fetchKloterLoading, fetchKloter };
};