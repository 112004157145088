import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_JAMAAHS_DATA:
            return {
                ...state,
                jamaahData: action.value
            }
        case type.CHANGE_SORTER:
            return {
                ...state,
                sorter: action.value
            }
        case type.CHANGE_PAGE:
            return {
                ...state,
                page: action.value
            }
        case type.CHANGE_PER_PAGE:
            return {
                ...state,
                perPage: action.value
            }
        case type.CHANGE_FILTER_KODE:
            return {
                ...state,
                filterKode: action.value
            }
        case type.CHANGE_FILTER_NAME:
            return {
                ...state,
                filterName: action.value
            }
        case type.CHANGE_FILTER_NIK:
            return {
                ...state,
                filterNIK: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        default:
            return state
    }
}

const defaultState = () => ({
    jamaahData: {},
    // loader
    isLoading: false,
    // pagination
    page: 1,
    perPage: 50,

    // filter and sort
    status: "",
    sorter: null,

    filterKode: null,
    filterName: null,
    filterNIK: null,
})

export default main