import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Card, Table
} from 'antd';
import moment from 'moment';

import Layout from "../../../layout"

const App = (props) => {
  const {
    // data
    itemData, isLoading,
    // actions
    handleFetchDataItem,
  } = props

  useEffect(() => {
    handleFetchDataItem()
  }, [handleFetchDataItem])

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      align:'left',
      render: (text, record) => <Link to={`/items/detail/${record.id}`}>{text}</Link>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      align:'left',
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      align:'left',
      render: (text, record) => <>{(text)?"Aktif":"Tidak Aktif"}</>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      align:'left',
      render: (text, record) => <>{moment(text).format('DD MMMM YYYY')}</>,
    },
  ]

  console.log(itemData);

  return (
    <Layout>
      <Card>
        <Table
          style={{ marginTop: 5 }}
          columns={columns}
          dataSource={itemData.data || []}
          loading={isLoading}
          rowKey={items => items.id}
          pagination={false}
        />
      </Card>

    </Layout>
  );
};

export default App;