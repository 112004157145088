import React from 'react'
import { Button, Modal } from 'antd';
import moment from 'moment';

const App = (props) => {
    const {
        // data
        kloterData, isModalActiveKloterOpen, isActiveInactiveKloterLoading,
        // actions
        changeModalActiveKloterOpen, handleActiveInactiveKloter,
    } = props
    return (
        <Modal
            title="Apakah anda yakin mengaktifkan kloter ini?"
            open={isModalActiveKloterOpen}
            confirmLoading={() => alert()}
            disabled
            closable={false}
            footer={[
                <Button key="cancel" type='default' onClick={() => changeModalActiveKloterOpen(false)} loading={isActiveInactiveKloterLoading}>
                    Batalkan
                </Button>,
                <Button key="ok" type='primary' onClick={() => handleActiveInactiveKloter(kloterData.id)} loading={isActiveInactiveKloterLoading}>
                    Konfirmasi
                </Button>,
            ]}
        >
            <p><strong>Nama Kloter:</strong> {kloterData.name}</p>
            <p><strong>Tanggal Terbang:</strong> {moment(kloterData.flying_date).format('DD MMMM YYYY')}</p>
            <p><strong>Paket:</strong> {(kloterData.package)&&kloterData.package.name}</p>
        </Modal>
    )
}

export default App;

