import React, { useState, useRef } from 'react'
import { Link } from "react-router-dom";
import {
    Table, Button, Input, Space, Row, Col,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import Highlighter from 'react-highlight-words';

import { numeratorFormatID } from "../../../../helpers/currency";
import { truncateText } from "../../../../helpers/randomThings";

const App = (props) => {

    const [tableKey, setTableKey] = useState(0);
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const {
        //actions
        handleFetchJournalData, reset, changeModalAddJournalOpen,
        changePage, changeSorter,
        changeFilterType, changeFilterNoBukti, changeFilterDescription, changeFilterNoRef,
        changeFilterAccount, changeFilterAccountGroup, changeJournalUpdateData, changeJournalDeleteData,
        handleChangeModalBRIOpen,
        //data
        fetchJournalLoading, journalData,
        page, perPage,
        filterNoBukti, filterNoRef, filterAccount,
        filterAccountGroup, filterDescription, filterType
    } = props

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setTableKey(tableKey => tableKey + 1);
        setSearchText('');
        setSearchedColumn('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
            const dataIndexValue = record[dataIndex];
            if (dataIndexValue && dataIndexValue.toString) {
                return dataIndexValue.toString().toLowerCase().includes(value.toLowerCase());
            }
            return false;
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleTableChange = (pagination, filters, sorter) => {
        changeFilterDescription((filters?.description) ? filters?.description : null)
        changeFilterNoBukti((filters?.no_bukti) ? filters?.no_bukti : null)
        changeFilterNoRef((filters?.no_ref) ? filters?.no_ref : null)
        changeFilterAccount((filters?.account_name) ? filters?.account_name : null)
        changeFilterAccountGroup((filters?.account_group) ? filters?.account_group : null)

        const { type: typeFilter } = filters
        // set filter type
        if (typeFilter) {
            changeFilterType(typeFilter[0])
        } else {
            changeFilterType(null)
        }

        changePage(pagination.current)

        // get sort colum and order
        if (sorter.hasOwnProperty("column")) {
            if (typeof sorter.order === "undefined") {
                changeSorter(null)
            } else {
                changeSorter({
                    field: sorter.field,
                    order: (sorter.order === "ascend") ? "asc" : "desc",
                })
            }
        }

        handleFetchJournalData()
        return
    }

    const columns = [
        {
            title: 'Tanggal',
            dataIndex: 'date',
            align: "left",
            width: "100px",
            render: (text, record) => <>{moment(text).format('DD/MM/YYYY')}</>,
            // ...getColumnSearchProps('date', 'Format DD-MM-YYYY 20-12-2023'), // Add placeholder here
        },
        {
            title: 'No Bukti',
            dataIndex: 'no_bukti',
            render: (text, record) => <>{text}</>,
            ...getColumnSearchProps('no_bukti'),
        },
        {
            title: 'No Ref',
            dataIndex: 'no_ref',
            width: "100px",
            render: (text, record) => <>{text}</>,
            ...getColumnSearchProps('no_ref'),
        },
        {
            title: 'Akun',
            dataIndex: 'account_name',
            width: "100px",
            render: (text, record) => <>{text}</>,
            ...getColumnSearchProps('account_name'),
        },
        {
            title: 'Akun Group',
            dataIndex: 'account_group',
            width: "100px",
            render: (text, record) => <>{text}</>,
            ...getColumnSearchProps('account_group'),
        },
        {
            title: 'Deskripsi',
            dataIndex: 'description',
            width: "300px",
            ...getColumnSearchProps('description'),
            render: (text, record) => <>{truncateText(text, 80)}</>,
        },
        {
            title: 'Nominal (Rp)',
            dataIndex: 'nominal',
            align: "right",
            render: (text, record) => {
                const numericValue = numeratorFormatID(text);
                const color = record.type === 'd' ? '#52c41a' : '#f5222d';

                return (
                    <span style={{ color }}>{numericValue}</span>
                );
            },
        },
        {
            title: 'Keterangan',
            dataIndex: 'type',
            align: "center",
            filterSearch: true,
            filterMultiple: false,
            filters: [
                {
                    text: 'Debit',
                    value: 'd',
                },
                {
                    text: 'Kredit',
                    value: 'c',
                },
            ],
            render: (text, record) => {
                const color = record.type === 'd' ? '#52c41a' : '#f5222d';

                if (text === "d") {
                    return <span style={{ color }}>Debit</span>
                }
                if (text === "c") {
                    return <span style={{ color }}>Kredit</span>
                }
            },
        },
        {
            title: 'Action',
            dataIndex: 'id',
            align: "center",
            render: (text, record) => {
                return (
                    <>
                        <Link onClick={() => changeJournalUpdateData(record)}>update</Link>&nbsp;|&nbsp;
                        <Link onClick={() => changeJournalDeleteData(record)}>hapus</Link>
                    </>
                )
            },
        },
    ];

    const resetFilters = () => {
        const clearFilters = () => { };
        handleReset(clearFilters);
        reset() // reset redux
        handleFetchJournalData()
    };

    return (
        <div>
            <Row>
                <Col xs={24} sm={24} md={8} lg={8}>
                    {`Ditemukan: ${journalData.total_data} data`}
                    <Button
                        onClick={resetFilters}
                        size="small"
                        style={{ marginLeft: "5px" }}
                        disabled={fetchJournalLoading ? true : false}
                    >
                        Reset Filter
                    </Button> <br />
                    <small>
                        {
                            filterNoBukti !== null &&
                            <><strong>No Bukti:</strong>{filterNoBukti}&nbsp;</>
                        }
                        {
                            filterNoRef !== null &&
                            <><strong>No Ref:</strong>{filterNoRef}&nbsp;</>
                        }
                        {
                            filterAccount !== null &&
                            <><strong>Akun:</strong>{filterAccount}&nbsp;</>
                        }
                        {
                            filterAccountGroup !== null &&
                            <><strong>Akun Group:</strong>{filterAccountGroup}&nbsp;</>
                        }
                        {
                            filterDescription !== null &&
                            <><strong>Deskripsi:</strong>{filterDescription}&nbsp;</>
                        }
                        {
                            filterType !== null &&
                            <>
                                <strong>Tipe:</strong>
                                {(filterType === "d") && "debit"}
                                {(filterType === "c") && "kredit"}&nbsp;
                            </>
                        }
                    </small>
                </Col>
                <Col xs={24} sm={24} md={8} lg={{ span: 5, offset: 19 }} style={{ textAlign: 'right' }}>
                    <Row>
                        <Button
                            type="primary"
                            size='small'
                            onClick={() => changeModalAddJournalOpen(true)}
                            disabled={fetchJournalLoading ? true : false}
                        >
                            Tambah Data
                        </Button>

                        <Button
                            type="primary"
                            size='small'
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleChangeModalBRIOpen(true)}
                            disabled={fetchJournalLoading ? true : false}
                        >
                            Import From BRI
                        </Button>
                    </Row>
                </Col>
            </Row>
            <Table
                key={tableKey}
                onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
                style={{ marginTop: 5 }}
                columns={columns}
                dataSource={journalData?.data}
                loading={fetchJournalLoading}
                rowKey={data => data.id}
                pagination={{
                    simple: true,
                    current: page,
                    total: journalData?.total_data,
                    pageSize: perPage,
                }}
                size="small"
            />
        </div>
    )
}

export default App;
