import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Card, Table, Tag,
} from 'antd';

import Layout from "../../../layout"

const App = (props) => {

  const {
    // actions 
    handleFetchDataBranch,
    changePage, changeStatus, changeSorter,
    // data
    branchData,
    isLoading,
    page, perPage, sorter, status,
  } = props

  useEffect(() => {
    document.title = 'Branch List';
    handleFetchDataBranch()
  }, [handleFetchDataBranch]);

  const handleTableChange = (pagination, filters, sorter) => {

    const { status: statusFilter } = filters

    // set filter status
    if (statusFilter) {
      changeStatus(statusFilter[0])
    } else {
      changeStatus("")
    }

    changePage(pagination.current)

    // get sort colum and order
    if (sorter.hasOwnProperty("column")) {
      if (typeof sorter.order === "undefined") {
        changeSorter(null)
      } else {
        changeSorter({
          field: sorter.field,
          order: (sorter.order === "ascend") ? "asc" : "desc",
        })
      }
    }

    handleFetchDataBranch()
    return
  }

  const columns = [
    {
      title: 'Kode',
      dataIndex: 'kode',
      filterSearch: true,
      render: (text, record) => <Link to={`/branch/detail/${record.id}`}>{record.kode}</Link>,
      sorter: true,
    },
    {
      title: `Nama Cabang`,
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      width: '15%',
      render: (text, record) => {
        let color = "success"
        if (record.status === "awaiting") { color = "warning" }
        if (record.status === "suspended") { color = "error" }
        if (record.status === "inactive") { color = "default" }
        return <Tag color={color}>{text}</Tag>
      },
      filterSearch: true,
      filterMultiple: false,
      filters: [
        {
          text: 'Menunggu Konfirmasi',
          value: 'awaiting',
        },
        {
          text: 'Aktif',
          value: 'active',
        },
        {
          text: 'Disuspen',
          value: 'suspended',
        },
        {
          text: 'Tidak Aktif',
          value: 'inactive',
        },
      ],
    },
  ];

  return (
    <Layout>
      <Card>
        {
          sorter !== null &&
          <>Sort By:{sorter.field}({sorter.order})&nbsp;</>
        }
        {
          status !== "" &&
          <>Filter By:Status({status})&nbsp;</>
        }
        {`Ditemukan: ${branchData.total_data} data`}
        <Table
          style={{ marginTop: 5 }}
          onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
          columns={columns}
          dataSource={branchData.data || []}
          loading={isLoading}
          rowKey={branches => branches.id}
          pagination={{
            simple: true,
            // defaultCurrent: 1,
            current: page,
            total: branchData.total_data,
            pageSize: perPage,
          }}
        />
      </Card>
    </Layout>
  );
};

export default App;