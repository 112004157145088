import { Get, Post, Put } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "USER_LIST"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_USERS_DATA: `${key}_CHANGE_USERS_DATA`,
    CHANGE_SORTER: `${key}_CHANGE_SORTER`,
    CHANGE_ROLES: `${key}_CHANGE_ROLES`,
    CHANGE_MODAL_NEW_OPEN: `${key}_CHANGE_MODAL_NEW_OPEN`,
    CHANGE_SAVE_LOADING: `${key}_CHANGE_SAVE_LOADING`,
    CHANGE_USER_UPDATE_DATE: `${key}_CHANGE_USER_UPDATE_DATE`,
    CHANGE_UPDATE_LOADING: `${key}_CHANGE_UPDATE_LOADING`,
    CHANGE_USER_UPDATE_PASSWORD_DATA: `${key}_CHANGE_USER_UPDATE_PASSWORD_DATA`,
    CHANGE_UPDATE_PASSWORD_LOADING: `${key}_CHANGE_UPDATE_PASSWORD_LOADING`,

    CHANGE_INACTIVE_TOGGLE_DATA: `${key}_CHANGE_INACTIVE_TOGGLE_DATA`,
    CHANGE_INACTIVE_TOGGLE_LOADING: `${key}_CHANGE_INACTIVE_TOGGLE_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeUsersData = (value) => ({
    type: type.CHANGE_USERS_DATA,
    value
})

export const changeSorter = (value) => ({
    type: type.CHANGE_SORTER,
    value
})

export const changeRoles = (value) => ({
    type: type.CHANGE_ROLES,
    value
})

export const changeModalNewOpen = (value) => ({
    type: type.CHANGE_MODAL_NEW_OPEN,
    value
})

export const changeSaveLoading = (value) => ({
    type: type.CHANGE_SAVE_LOADING,
    value
})

export const changeUserUpdateData = (value) => ({
    type: type.CHANGE_USER_UPDATE_DATE,
    value
})

export const changeUpdateLoading = (value) => ({
    type: type.CHANGE_UPDATE_LOADING,
    value
})

export const changeUserUpdatePasswordData = (value) => ({
    type: type.CHANGE_USER_UPDATE_PASSWORD_DATA,
    value
})

export const changeUpdatePasswordLoading = (value) => ({
    type: type.CHANGE_UPDATE_PASSWORD_LOADING,
    value
})

export const changeInactiveToggleData = (value) => ({
    type: type.CHANGE_INACTIVE_TOGGLE_DATA,
    value
})

export const changeInactiveToggleLoading = (value) => ({
    type: type.CHANGE_INACTIVE_TOGGLE_LOADING,
    value
})

export const handleFetchDataUser = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const { sorter } = state.userList

        let sorterQuery = (sorter !== null) ? `&sort_by=${sorter.field}&order=${sorter.order}` : ``

        let url = `${BASE_API_URL}/users?${sorterQuery}`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeUsersData(res || []))
        return
    }
}

export const handleFetchDataRole = () => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/roles`

        const res = await Get(url);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        const roles = [];
        if (res.data) {
            res.data.forEach(data => {
                const temp_val = {
                    label: data.name,
                    value: data.id,
                }
                roles.push(temp_val);
            });
        }

        dispatch(changeRoles(roles || []))
        return
    }
}

export const handleSaveUser = (data) => {
    return async (dispatch, getState) => {
        dispatch(changeSaveLoading(true))
        const res = await Post(`${BASE_API_URL}/users`, data);
        dispatch(changeSaveLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }
        await dispatch(changeModalNewOpen(false))
        await dispatch(handleFetchDataUser())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })

    }
}

export const handleUpdateUser = (userId, data) => {
    return async (dispatch, getState) => {
        dispatch(changeSaveLoading(true))
        const res = await Put(`${BASE_API_URL}/users/${userId}`, data);
        dispatch(changeSaveLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }
        await dispatch(changeUserUpdateData({}))
        await dispatch(handleFetchDataUser())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })

    }
}

export const handleUpdatePassword = (userId, data) => {
    return async (dispatch, getState) => {
        dispatch(changeUpdatePasswordLoading(true))
        const res = await Put(`${BASE_API_URL}/users/${userId}/update-password`, data);
        dispatch(changeUpdatePasswordLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }
        await dispatch(changeUserUpdatePasswordData({}))
        await dispatch(handleFetchDataUser())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })

    }
}

export const handleActiveOrInactiveUser = (userId) => {
    return async (dispatch, getState) => {
        dispatch(changeInactiveToggleLoading(true))
        const res = await Put(`${BASE_API_URL}/users/${userId}/in-active-toggle`);
        dispatch(changeInactiveToggleLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }
        await dispatch(changeInactiveToggleData({}))
        await dispatch(handleFetchDataUser())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })

    }
}

export default type