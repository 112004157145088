import React, { } from 'react';
import { Card, Table, Button, DatePicker } from 'antd';
import moment from 'moment';
import { Link } from "react-router-dom";
import {
  FileExcelOutlined,
} from '@ant-design/icons';

import Layout from "../../../layout"
import { BASE_API_URL } from '../../../config';
import { rupiahFormat } from "../../../helpers/currency";

import ModalVerification from "./childComponets/ModalVerification"
import ModalDeletePayment from "./childComponets/ModalDeletePayment"

const { RangePicker } = DatePicker;

const App = (props) => {
  const {
    fetchPaymentsLoading, payments, selectedDateArr, paymentMethods, filterPaymentMethodId,
    // actions
    handleFetchPaymentsData, changeModalVerificationData, changeSorter, changeSelectedDateArr, handleFetchDataPaymentMethods,
    changeFilterPaymentMethodId, changeModalDeleteData,
  } = props

  React.useEffect(() => {
    handleFetchDataPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const download_excel = () => {
    let filterPaymentMethodIdQry = (filterPaymentMethodId) ? `&payment_method_id=${filterPaymentMethodId[0]}` : ``
    let start_date = (moment(selectedDateArr[0]).format('YYYY-MM-DD'))
    let end_date = (moment(selectedDateArr[1]).format('YYYY-MM-DD'))
    let filterDateQuery = `&start_date=${start_date}&end_date=${end_date}`

    const url = `${BASE_API_URL}/payments/excel/true?${filterDateQuery}${filterPaymentMethodIdQry}`

    const newTab = window.open(url, '_blank');
    newTab.focus();
    return
  }

  const columns = [
    {
      title: 'Tanggal Bayar',
      dataIndex: 'created_at',
      render: (text, record) => (moment(text).format('DD MMMM YYYY')),
      align: 'center',
      sorter: true,
    },
    {
      title: 'Nominal',
      dataIndex: 'nominal',
      align: 'right',
      render: (text, record) => rupiahFormat(text),
      sorter: true,
    },
    {
      title: 'Kloter',
      dataIndex: 'kloter_name',
      align: 'center',
    },
    {
      title: 'Kode Jamaah',
      dataIndex: 'jamaah_kode',
      align: 'left',
    },
    {
      title: 'Nama Jamaah',
      dataIndex: 'jamaah_name',
      align: 'left',
    },
    {
      title: 'Metode Bayar',
      dataIndex: 'payment_method',
      align: 'center',
      sorter: true,
      filterSearch: true,
      filterMultiple: false,
      filters: paymentMethods,
    },
    {
      title: 'Status',
      dataIndex: 'is_verified',
      align: 'center',
      sorter: true,
      render: (text, record) => {
        if (text === 1) {
          return (
            <Link>Terverikasi</Link>
          )
        }
        return (
          <Link onClick={() => changeModalVerificationData(record)}>Belum Terverikasi</Link>
        )
      },
    },
    {
      title: 'Aksi',
      dataIndex: 'id',
      align: 'center',
      render: (text, record) => {
        return (
          <Link onClick={() => changeModalDeleteData(record)}>Hapus</Link>
        )
      },
    },
  ]

  const handleTableChange = (pagination, filters, sorter) => {
    // changePage(pagination.current)
    changeFilterPaymentMethodId((filters?.payment_method) ? filters?.payment_method : null)
    // get sort colum and order
    if (sorter.hasOwnProperty("column")) {
      if (typeof sorter.order === "undefined") {
        changeSorter(null)
      } else {
        changeSorter({
          field: sorter.field,
          order: (sorter?.order === "ascend") ? "asc" : "desc",
        })
      }
    }
    handleFetchPaymentsData(selectedDateArr)
    return
  }

  const changeSelectedDate = (date) => {
    if (date) {
      let start_date = ((date[0]) ? date[0]._d : null)
      let end_date = ((date[1]) ? date[1]._d : null)

      changeSelectedDateArr([start_date, end_date])
      return
    }
    changeSelectedDateArr(null)
    return
  }

  function disabledDate(current) {
    return current && current > moment().endOf('day');
  }

  let totalNominal = 0
  if (payments.data) {
    totalNominal = payments.data.reduce((sum, item) => sum + parseFloat(item.nominal), 0);
  }

  return (
    <>
      <ModalVerification {...props} />
      <ModalDeletePayment {...props} />
      <Layout>
        <Card>
          <span>
            <RangePicker
              onChange={(dateArr) => changeSelectedDate(dateArr)} disabledDate={disabledDate}
            />
            <Button type='primary' onClick={() => {
              if (!selectedDateArr) {
                return
              }
              handleFetchPaymentsData(selectedDateArr)
              return
            }}>Tampikan Data</Button>
          </span>
          <p>Total data: {payments?.total_data}</p>
          <Table
            style={{ marginTop: 15 }}
            columns={columns}
            dataSource={payments?.data}
            loading={fetchPaymentsLoading}
            rowKey={items => items.id}
            onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
            pagination={false}
          />

          {
            (payments?.data) &&
            <>
              <h1><strong>Total Terbayar: {rupiahFormat(totalNominal)}</strong></h1>
              {
                (totalNominal > 0)
                  ?
                  <Button type="success" onClick={() => download_excel()}><FileExcelOutlined />Download Recap Excel</Button>
                  :
                  <></>
              }
            </>
          }
        </Card>

      </Layout>
    </>

  );
};

export default App;