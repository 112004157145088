import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Skeleton, Tabs, Divider, Button,
} from 'antd';
import moment from 'moment';

import Layout from "../../../layout"
import './style.css';

import TabHeir from "./childComponents/TabHeir";
import TabFoto from "./childComponents/TabFoto";
import TabJamaahDetailListKloter from "./childComponents/TabJamaahDetailListKloter";
import ModalAddHeir from "./childComponents/ModalAddHeir";
import ModalUpdateData from "./childComponents/ModalUpdateData";

const App = (props) => {
  let { id } = useParams();

  const {
    // actions
    handleGetJamaahDetail,
    handleGetJamaahHeir,
    changeModalUpdateOpen,
    // data
    isLoading,
    jamaah,
  } = props

  useEffect(() => {
    document.title = 'Jamaah Detail';
    handleGetJamaahDetail(id)
  }, [handleGetJamaahDetail, id]);

  const hangleOnChange = (key) => {
    if (key === 'heir') {
      handleGetJamaahHeir(jamaah.id)
    }
  }

  if (isLoading) {
    return (
      <Layout>
        <Card>
          <Skeleton active />
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <ModalAddHeir {...props} />
      <ModalUpdateData {...props} />
      {
        (!jamaah.hasOwnProperty('name'))
          ?
          <Card>
            Data tidak ditemukan
          </Card>
          :
          <Card>
            <h3>Detail Jamaah</h3>
            <table>
              <tbody>
                <tr>
                  <th className='myTh'>Kode Jamaah</th>
                  <td className='myTd'>{jamaah.kode}</td>
                </tr>
                <tr>
                  <th className='myTh'>Nama Jamaah</th>
                  <td className='myTd'>{jamaah.name}</td>
                </tr>
                <tr>
                  <th className='myTh'>Jenis Kelamin</th>
                  <td className='myTd'>{(jamaah.gender === "male") && "Pria"} {(jamaah.gender === "female") && "Wanita"}</td>
                </tr>
                <tr>
                  <th className='myTh'>Nama Ayah</th>
                  <td className='myTd'>{jamaah.father_name}</td>
                </tr>
                <tr>
                  <th className='myTh'>Tempat, Tanggal Lahir</th>
                  <td className='myTd'>{jamaah.pob}, {moment(jamaah.dob).format('DD MMMM YYYY')}</td>
                </tr>
                <tr>
                  <th className='myTh'>NIK</th>
                  <td className='myTd'>{jamaah.nik}</td>
                </tr>
                <tr>
                  <th className='myTh'>Nomer Passport</th>
                  <td className='myTd'>{jamaah.passport_number}</td>
                </tr>
                <tr>
                  <th className='myTh'>Nama Passport</th>
                  <td className='myTd'>{jamaah.passport_name}</td>
                </tr>
                <tr>
                  <th className='myTh'>Passport Berlaku S/D</th>
                  <td className='myTd'>{(jamaah.passport_valid_until)&&moment(jamaah.passport_valid_until).format('DD MMMM YYYY')}</td>
                </tr>
                <tr>
                  <th className='myTh'>Pekerjaan</th>
                  <td className='myTd'>{jamaah.occupation}</td>
                </tr>
                <tr>
                  <th className='myTh'>Alamat</th>
                  <td className='myTd'>
                    {jamaah.address} <br />
                    {jamaah.desa} {(jamaah.kecamatan) && ", "} {jamaah.kecamatan} {(jamaah.kabupaten) && ", "} {jamaah.kabupaten} {(jamaah.provinsi) && ", "} {jamaah.provinsi}
                  </td>
                </tr>
                <tr>
                  <th className='myTh'>Hp/Telp</th>
                  <td className='myTd'>{jamaah.phone}</td>
                </tr>
                <tr>
                  <th className='myTh'>Email</th>
                  <td className='myTd'>{jamaah.email}</td>
                </tr>
                <tr>
                  <th className='myTh'>Referal Agen</th>
                  <td className='myTd'>{jamaah.agent_name}</td>
                </tr>
                <tr>
                  <th className='myTh'>Cabang Daftar</th>
                  <td className='myTd'>
                    <strong>{(jamaah.hasOwnProperty('branch')) ? jamaah.branch.kode : ""}</strong> ({(jamaah.hasOwnProperty('branch')) ? jamaah.branch.name : ""})
                  </td>
                </tr>
              </tbody>
            </table>

            <Button onClick={() => changeModalUpdateOpen(true)}>Update</Button>

            <Divider />
            <Tabs
              onChange={(key) => hangleOnChange(key)}
              type="card"
              items={[
                {
                  label: `Pas Foto`,
                  key: `photo`,
                  children: <TabFoto {...props} />
                },
                {
                  label: `Ahli Waris`,
                  key: `heir`,
                  children: <TabHeir {...props} />
                },
                {
                  label: `List Kloter`,
                  key: `kloters`,
                  children: <TabJamaahDetailListKloter />
                },
              ]}
            />
          </Card>
      }
    </Layout>
  );
};

export default App;