import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Button,
  Card, Table,
} from 'antd';

import Layout from "../../../layout"
import ModalUpdate from "./childComponets/ModalUpdate"
import ModalDelete from "./childComponets/ModalDelete"
import ModalNew from "./childComponets/ModalNew"

const App = (props) => {
  const {
    // actions 
    handleFetchDataAccount,
    changePage, changeStatus, changeSorter, changeAccountUpdateData, changeAccountDeleteData, changeModalNewOpen,
    // data
    accountData,
    isLoading,
    page, perPage, sorter, status,
  } = props

  useEffect(() => {
    document.title = 'Account List';
    handleFetchDataAccount()
  }, [handleFetchDataAccount]);

  const handleTableChange = (pagination, filters, sorter) => {

    const { status: statusFilter } = filters

    // set filter status
    if (statusFilter) {
      changeStatus(statusFilter[0])
    } else {
      changeStatus("")
    }

    changePage(pagination.current)

    // get sort colum and order
    if (sorter.hasOwnProperty("column")) {
      if (typeof sorter.order === "undefined") {
        changeSorter(null)
      } else {
        changeSorter({
          field: sorter.field,
          order: (sorter.order === "ascend") ? "asc" : "desc",
        })
      }
    }

    handleFetchDataAccount()
    return
  }

  const columns = [
    {
      title: 'Grup',
      dataIndex: 'group',
      filterSearch: true,
      render: (text, record) => <>{text}</>,
      sorter: true,
    },
    {
      title: `Nama`,
      dataIndex: 'name',
      sorter: true,
      render: (text, record) => <>{text}</>,
    },
    {
      title: `Tipe`,
      dataIndex: 'type',
      sorter: true,
      render: (text, record) => <>{text}</>,
    },
    {
      title: `Header`,
      dataIndex: 'header',
      sorter: true,
      render: (text, record) => <>{text}</>,
    },
    {
      title: `Level`,
      dataIndex: 'level',
      sorter: true,
      render: (text, record) => <>{text}</>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center',
      render: (text, record) => (
        <>
          <Link onClick={() => changeAccountUpdateData(record)}>edit</Link> |&nbsp;
          <Link style={{ color: '#f5222d' }} onClick={() => changeAccountDeleteData(record)}>delete</Link>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <ModalUpdate {...props} />
      <ModalDelete {...props} />
      <ModalNew {...props} />
      <Button type='primary' onClick={() => changeModalNewOpen(true)}>Tambah Data Akun</Button>
      <Card>
        {
          sorter !== null &&
          <>Sort By:{sorter.field}({sorter.order})&nbsp;</>
        }
        {
          status !== "" &&
          <>Filter By:Status({status})&nbsp;</>
        }
        {`Ditemukan: ${accountData.total_data} data`}
        <Table
          style={{ marginTop: 5 }}
          onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
          columns={columns}
          dataSource={accountData.data || []}
          loading={isLoading}
          rowKey={accountes => accountes.id}
          pagination={{
            simple: true,
            // defaultCurrent: 1,
            current: page,
            total: accountData.total_data,
            pageSize: perPage,
          }}
        />
      </Card>
    </Layout>
  );
};

export default App;