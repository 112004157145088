import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Skeleton, Divider, Button, Tabs
} from 'antd';
import moment from 'moment';

import Layout from "../../../layout"

import { rupiahFormat } from "../../../helpers/currency";

import ModalInactiveConfirm from "./childComponets/ModalInactiveConfirm"
import ModalActiveConfirm from "./childComponets/ModalActiveConfirm"
import ModalUpdate from "./childComponets/ModalUpdate"
import AdditionalItem from "./childComponets/AdditionalItem"
import RAB from "./childComponets/RAB"
import KloterEstimation from "./childComponets/KloterEstimation"

import KloterDetailJamaahList from "./childComponets/KloterDetailJamaahList"

const App = (props) => {
  let { id } = useParams();

  const {
    // actions
    handleGetKloterDetail, changeModalInactiveKloterOpen, changeModalUpdateKloterOpen, changeModalActiveKloterOpen,
    // data 
    isLoading, kloterData,
  } = props

  useEffect(() => {
    document.title = 'Kloter Detail';
    handleGetKloterDetail(id)
  }, [handleGetKloterDetail, id]);

  if (isLoading) {
    return (
      <Layout>
        <Card>
          <Skeleton active />
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <>
        <ModalInactiveConfirm {...props} />
        <ModalActiveConfirm {...props} />
        <ModalUpdate {...props} />

        <Divider orientation="left" orientationMargin="0">
          <strong>Detail Kloter</strong>
        </Divider>
        <Card>
          <p><strong>Nama Kloter:</strong> {kloterData.name}</p>
          <p><strong>Tanggal Terbang:</strong> {moment(kloterData.flying_date).format('DD MMMM YYYY')}</p>
          <p><strong>Diskripsi:</strong> {kloterData.description}</p>
          <p>
            <strong>Quota:</strong> {kloterData.quota} 
            {
              (kloterData.quota < kloterData.jamaah_terdaftar)
              ?
              <><strong> Kelebihan:</strong> {kloterData.jamaah_terdaftar - kloterData.quota}</>
              :
              <><strong> Tersedia:</strong> {kloterData.quota - kloterData.jamaah_terdaftar < 0 ? 0 : kloterData.quota - kloterData.jamaah_terdaftar}</>
            }
          </p>
          <p><strong>Biaya:</strong> {rupiahFormat(kloterData.basic_cost)}</p>
          <p><strong>Status:</strong> {(kloterData.active) ? "Aktif" : "Tidak Aktif"}</p>
          <br />

          <Button
            type="default"
            style={{ marginRight: 5 }}
            onClick={() => changeModalUpdateKloterOpen(true)}
          >
            Update Kloter
          </Button>

          {
            kloterData.active
              ?
              <Button
                type="danger"
                onClick={() => changeModalInactiveKloterOpen(true)}
              >
                Nonaktifkan
              </Button>
              :
              <Button
                type="primary"
                onClick={() => changeModalActiveKloterOpen(true)}
              >
                Aktifkan
              </Button>
          }
        </Card>
      </>

      <Divider orientation="left" orientationMargin="0" />

      <Card>
        <Tabs
          type="card"
          items={[
            {
              label: `RAB`,
              key: `RAB`,
              children: <RAB {...props} />
            },
            {
              label: `Item Tambahan`,
              key: `AdditionalItem`,
              children: <AdditionalItem {...props} />
            },
            {
              label: `Jamaah Terdaftar`,
              key: `KloterDetailJamaahList`,
              children: <KloterDetailJamaahList {...props} />
            },
            {
              label: `Estimasi Perhitungan`,
              key: `KloterDetailEstimations`,
              children: <KloterEstimation {...props} />
            },
          ]}
        />
      </Card>
    </Layout>
  );
};

export default App;