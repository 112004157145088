import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Card, Table, Button,
} from 'antd';

import Layout from "../../../layout"
import ModalNew from "./childComponets/ModalNew"
import ModalUpdate from "./childComponets/ModalUpdate"
import ModalRegeneratePassword from "./childComponets/ModalRegeneratePassword"
import ModalActiveOrInactiveConfirm from "./childComponets/ModalActiveOrInactiveConfirm"

const App = (props) => {

  const {
    // actions 
    handleFetchDataUser, handleFetchDataRole, changeStatus, changeSorter,
    changeModalNewOpen, changeUserUpdateData, changeUserUpdatePasswordData,
    changeInactiveToggleData,
    // data
    userData,
    isLoading, sorter, status,
  } = props

  useEffect(() => {
    document.title = 'User';
    handleFetchDataUser()
    handleFetchDataRole()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {

    const { status: statusFilter } = filters

    // set filter status
    if (statusFilter) {
      changeStatus(statusFilter[0])
    } else {
      changeStatus("")
    }

    // get sort colum and order
    if (sorter.hasOwnProperty("column")) {
      if (typeof sorter.order === "undefined") {
        changeSorter(null)
      } else {
        changeSorter({
          field: sorter.field,
          order: (sorter.order === "ascend") ? "asc" : "desc",
        })
      }
    }

    handleFetchDataUser()
    return
  }

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      filterSearch: true,
      render: (text, record) => <Link onClick={() => {
        changeUserUpdateData(record)
      }
      }>{text}</Link>,
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      filterSearch: true,
      render: (text, record) => <>{text}</>,
    },
    {
      title: 'Role',
      dataIndex: 'role_name',
      filterSearch: true,
      render: (text, record) => <>{text}</>,
    },
    {
      title: 'Status',
      dataIndex: 'active',
      filterSearch: true,
      render: (text, record) => <>{(record.active) ? "Aktif" : "Tidak Aktif"}</>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      filterSearch: true,
      render: (text, record) => (
        <>
          <Link onClick={() => changeUserUpdatePasswordData(record)}>Update Password</Link> | &nbsp;
          <Link onClick={() => changeInactiveToggleData(record)}>Ubah Status</Link>
        </>
      ),
    },
  ];

  return (
    <>
      <ModalNew {...props} />
      <ModalUpdate {...props} />
      <ModalRegeneratePassword {...props} />
      <ModalActiveOrInactiveConfirm {...props} />
      <Layout>
        <Button type='primary' onClick={() => {
          changeModalNewOpen(true)
        }}>
          Tambah User
        </Button>
        <Card>
          {`Ditemukan: ${userData.total_data} data`}
          <br />
          {
            sorter !== null &&
            <>Sort By:{sorter.field}({sorter.order})&nbsp;</>
          }
          {
            status !== "" &&
            <>Filter By:Status({status})&nbsp;</>
          }
          <Table
            style={{ marginTop: 5 }}
            onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
            columns={columns}
            dataSource={userData.data || []}
            loading={isLoading}
            rowKey={users => users.id}
            pagination={false}
          />
        </Card>
      </Layout>
    </>

  );
};

export default App;