import React, { } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { useParams } from "react-router-dom";


const Textarea = Input.TextArea
const App = (props) => {
    const { kloter_id } = useParams()

    const {
        changeModalUpdateRoomData, handleUpdateRoom,
        modalUpdateRoomData, updateRoomLoading,
    } = props
    return (
        <>
            <Modal
                title="Update Room"
                open={(modalUpdateRoomData.hasOwnProperty("id")) ? true : false}
                footer={null}
                onCancel={() => {
                    changeModalUpdateRoomData({})
                }}
                destroyOnClose
                bodyStyle={{
                    top: "-30px",
                    height: '300px',
                    overflow: 'auto',
                }}
            >
                <Form
                    layout="vertical"
                    onFinish={(data) => handleUpdateRoom(modalUpdateRoomData.id, data, kloter_id)}
                >
                    <Form.Item
                        label="Nama" name={"name"} rules={[{ required: true, max: 50 }]}
                        initialValue={modalUpdateRoomData.name}
                    >
                        <Input max={50} onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>

                    <Form.Item
                        label="Keterangan" name={"description"} rules={[{ required: false, max: 100 }]}
                        initialValue={modalUpdateRoomData.description}

                    >
                        <Textarea max={50} onInput={e => e.target.value = e.target.value.toUpperCase()}  ></Textarea>
                    </Form.Item>


                    <div style={{ float: 'right' }}>
                        <Button type="primary" style={{ marginRight: 2 }} htmlType="submit"
                            loading={updateRoomLoading}
                            disabled={updateRoomLoading}
                        >
                            Simpan Data
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    )
};
export default App;