import React from 'react'
import { Button, Modal } from 'antd';

const App = (props) => {
    const {
        // data
        packageData, isModalDeletePackageOpen, isDeletePackageLoading,
        // actions
        changeModalDeletePackageOpen, handleDeletePackage,
    } = props
    return (
        <Modal
            title="Apakah anda yakin menghapus package ini?"
            open={isModalDeletePackageOpen}
            confirmLoading={() => alert()}
            disabled
            closable={false}
            footer={[
                <Button key="cancel" type='default' onClick={() => changeModalDeletePackageOpen(false)} loading={isDeletePackageLoading}>
                    Batalkan
                </Button>,
                <Button key="ok" type='danger' onClick={() => handleDeletePackage(packageData.id)} loading={isDeletePackageLoading}>
                    Konfirmasi Hapus
                </Button>,
            ]}
        >
            <p><strong>Nama Package:</strong> {packageData.name}</p>
            <p><strong>Kode:</strong> {packageData.kode}</p>
            <p><strong>Estimasi Biaya:</strong> Rp {packageData.estimated_cost}</p>
        </Modal>
    )
}

export default App;

