import React, { useEffect } from 'react';
import {
  Form, Input, Button, Card, Spin, DatePicker, InputNumber,
} from 'antd';

import Layout from "../../../layout"

const App = (props) => {
  const {
    // actions
    handleSaveKloter,
    // data
    isLoading,
  } = props

  useEffect(() => {
    document.title = 'Kloter New';
  }, []);

  return (
    <Layout>
      <Card>
        <Spin tip="Processing data..." spinning={isLoading}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={(data) => handleSaveKloter(data)}
          >
            {/* <Form.Item label="Package" name={"package_id"} rules={[{ required: true }]}>
              <TreeSelect treeData={packages} />
            </Form.Item> */}

            <Form.Item label="Nama Kloter" name={"name"} rules={[{ required: true, min: 2, max: 50 }]}>
              <Input min={2} max={50} />
            </Form.Item>

            <Form.Item label="Tanggal Terbang" name={"flying_date"} rules={[{ required: true }]} >
              <DatePicker />
            </Form.Item>

            <Form.Item label="Estimasi Biaya" name={"basic_cost"} rules={[{ required: true, message: "'Estimasi Biaya', required" }]} >
              <InputNumber style={{ width: "30%" }} min={10000000} />
            </Form.Item>

            <Form.Item label="Quota" name={"quota"} rules={[{ required: true }]} >
              <InputNumber min={1} max={1000} />
            </Form.Item>

            <Form.Item label="Deskripsi" name={"description"} rules={[{ min: 2, max: 100 }]}>
              <Input.TextArea />
            </Form.Item>

            <div style={{ float: 'right' }}>
              <Button type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Data Kloter</Button>
            </div>
          </Form>
        </Spin>
      </Card>

    </Layout >
  );
};

export default App;