import { connect } from 'react-redux'
import App from './component'
import {
    // place actions
    changeLoadingKloterJamaahData, changeLoadingPaymentHistory, changeLoadingPaymentSumary, fetchQueue, changePaymentHistorySorter,
    handleGetKloterJamaahPaymentHistory, handleOpenModalAddPayment, handleCloseModalAddPayment, handleFetchPaymentMethods, handleSavePayment,
    changeModalItemData, habdleAddItemSave, changeModalItemDetail, habdleDeleteItem, habdleModalAddAddtionalItemOpen, habdleAddAddtionalItemSave,
    changeAdditionalItemToDelete, habdleDeleteAddtionalItemSave, handleDeleteKloterJamaah,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.kloterDetailJamaahDetail,
})

const mapDispatchToProps = {
    // place actions
    changeLoadingKloterJamaahData, changeLoadingPaymentHistory, changeLoadingPaymentSumary, fetchQueue, changePaymentHistorySorter,
    handleGetKloterJamaahPaymentHistory, handleOpenModalAddPayment, handleCloseModalAddPayment, handleFetchPaymentMethods, handleSavePayment,
    changeModalItemData, habdleAddItemSave, changeModalItemDetail, habdleDeleteItem, habdleModalAddAddtionalItemOpen, habdleAddAddtionalItemSave,
    changeAdditionalItemToDelete, habdleDeleteAddtionalItemSave, handleDeleteKloterJamaah,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)