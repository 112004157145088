import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import {
    Modal, Button, Checkbox, Row, Col, Skeleton
} from 'antd';
import { rupiahFormat } from "../../../../helpers/currency";

function App(props) {
    const [selectedAdditionalItems, setSelectedAdditionalItems] = useState([])
    let { id:klotersJamaahsId } = useParams();

    const {
        modalAdditionalItem, additionalItemsCandidate, habdleModalAddAddtionalItemOpen, additionalItemsCandidateLoading,
        habdleAddAddtionalItemSave,
    } = props

    const onChange = (checkedValues) => {
        setSelectedAdditionalItems(checkedValues)
    };

    return (
        <>
            <Modal
                title={"Tambah Biaya Tambahan"}
                open={modalAdditionalItem}
                onCancel={() => {
                    habdleModalAddAddtionalItemOpen(1, "close")
                }
                }
                disabled
                destroyOnClose
                footer={[
                    <Button
                        key="back" type='primary'
                        onClick={() => habdleAddAddtionalItemSave(klotersJamaahsId, selectedAdditionalItems)}
                        disabled={(additionalItemsCandidate.length < 1) ? true : false}
                    >
                        Proses
                    </Button>
                ]}
                width={700}
            >
                {
                    additionalItemsCandidateLoading
                        ?
                        <div style={{ marginBottom: 20, marginTop: 20 }}><Skeleton active /></div>
                        :
                        <Checkbox.Group
                            style={{
                                width: '100%',
                            }}
                            onChange={onChange}
                        >
                            <Row>
                                {
                                    additionalItemsCandidate.map((val) => {
                                        return (
                                            <Col span={16}>
                                                <Checkbox value={val.id} key={val.id}>{val.name} ({rupiahFormat(val.cost)})</Checkbox>
                                            </Col>
                                        )
                                    })
                                }

                            </Row>
                            {
                                (additionalItemsCandidate.length < 1) &&
                                <>Semua item telah dipilih</>
                            }
                        </Checkbox.Group>
                }

            </Modal>
        </>
    )
}

export default App;
