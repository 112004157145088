import { Post } from "../../helpers/ajax";
import { BASE_API_URL } from "../../config";

import { notification } from 'antd';

const key = "AUTH_REGISTER"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const handleRegister = (data) => {
    return async (dispatch, getState) => {
        const payload = {
            "name": data?.name,
            "email": data?.email,
            "password": data?.password,
        }

        dispatch(changeLoading(true))
        const res = await Post(`${BASE_API_URL}/register`, payload);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        alert("sukses, silahkan ke halaman login :)")

        window.location.reload();
        return
    }
}

export const handleChangeLoading = (event) => {
    return async (dispatch, getState) => {
        dispatch(changeLoading(true))
        return
    }
}

export default type