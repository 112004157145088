import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_LOADING_FETCH_DATA:
            return {
                ...state,
                isLoadingFetchData: action.value
            }
        case type.CHANGE_JAMAAHS_DATA:
            return {
                ...state,
                jamaahData: action.value
            }
        case type.CHANGE_MODAL_JAMAAH_ADD_TO_KLOTER_OPEN:
            return {
                ...state,
                isModalJamaahAddToKloterOpen: action.value
            }
        case type.CHANGE_SELECTED_NIK:
            return {
                ...state,
                selectedNik: action.value
            }
        case type.CHANGE_SELECTED_JAMAAHS:
            return {
                ...state,
                selectedJamaahs: action.value
            }
        case type.CHANGE_LOADING_SAVE_JAMAAH_TO_KLOTER:
            return {
                ...state,
                isLoadingSaveJamaahsToKloter: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        default:
            return state
    }
}

const defaultState = () => ({
    jamaahData: {},
    // loader
    isLoadingFetchData: false,

    // filter and sort
    selectedNik: '',

    // add jamaah to kloter
    isModalJamaahAddToKloterOpen: false,

    // selectedJamaahs
    selectedJamaahs: [],
    isLoadingSaveJamaahsToKloter: false,
})

export default main