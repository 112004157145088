export const delay = ms => new Promise(res => setTimeout(res, ms));

export const generateRandomKey = () => {
    return Math.random().toString(36).substr(2, 9); // Generate a random string of 9 characters
};

export function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength) + '...';
    }
}

export function generateRandomNumber(digit = 5) {
    if (typeof digit !== 'number' || digit < 1) {
        throw new Error('Invalid input: digit should be a positive number.');
    }

    // Calculate the lower and upper bounds for the random number
    const lowerBound = 10 ** (digit - 1);
    const upperBound = 10 ** digit - 1;

    // Generate a random number within the specified range
    const randomNumber = Math.floor(Math.random() * (upperBound - lowerBound + 1)) + lowerBound;
    return randomNumber;
}
