
import { getAccessToken } from "./library/userAuth";
import jwt_decode from "jwt-decode";

import Home from "./pages/Home"
import AuthLogin from "./pages/AuthLogin"
import AuthRegister from "./pages/AuthRegister"
import ErrorPage from "./pages/error-page";

import JamaahList from "./pages/Jamaah/JamaahList"
import JamaahNew from "./pages/Jamaah/JamaahNew"
import JamaahDetail from "./pages/Jamaah/JamaahDetail"

import BranchList from "./pages/Branch/BranchList"
import BranchNew from "./pages/Branch/BranchNew"
import BranchDetail from "./pages/Branch/BranchDetail"

import KloterDetail from "./pages/Kloter/KloterDetail"
import KloterDetailJamaahDetail from "./pages/Kloter/KloterDetailJamaahDetail"
import PrintBuktiPendaftaran from "./pages/Kloter/KloterDetailJamaahDetail/childComponents/PrintBuktiPendaftaran"
import KloterList from "./pages/Kloter/KloterList"
import KloterNew from "./pages/Kloter/KloterNew"

import ItemList from "./pages/Item/ItemList"
import ItemDetail from "./pages/Item/ItemDetail"
import ItemNew from "./pages/Item/ItemNew"
import KloterJamaahsItems from "./pages/Item/KloterJamaahsItems"

import PaymentList from "./pages/Payment/PaymentList"

import KasList from "./pages/Kas/KasList"
import JournalList from "./pages/Journal/JournalList"
import AccountList from "./pages/Account/AccountList"

import UserList from "./pages/Setting/UserList"

import ManifestPerKloter from "./pages/Manifest/ManifestPerKloter"
import KloterRoomWelcome from "./pages/Manifest/KloterRoomWelcome"
import KloterRoomSetting from "./pages/Manifest/KloterRoomSetting"

const protectedRouters = [
    {
        label: "Home",
        path: "/",
        auth: "*",
        element: <Home />,
        children: "",
    },
    {
        label: "Home",
        path: "home",
        auth: "*",
        element: <Home />,
        children: "",
    },
    {
        label: "Branch",
        path: "branch",
        auth: "*",
        children: [
            {
                label: "Branch New",
                path: "new",
                element: <BranchNew />,
                children: "",
            },
            {
                label: "Branch List",
                path: "list",
                element: <BranchList />,
                children: "",
            },
            {
                label: "Branch Detail",
                path: "detail/:id",
                element: <BranchDetail />,
                children: "",
            },
        ],
    },
    {
        label: "Jamaah",
        path: "jamaah",
        auth: "*",
        children: [
            {
                label: "Jamaah List",
                path: "",
                element: <JamaahList />,
                children: "",
            },
            {
                label: "Jamaah List",
                path: "new",
                element: <JamaahNew />,
                children: "",
            },
            {
                label: "Jamaah List",
                path: "list",
                element: <JamaahList />,
                children: "",
            },
            {
                label: "Jamaah Detail",
                path: "detail/:id",
                element: <JamaahDetail />,
                children: "",
            },
        ],
    },
    {
        path: "kloters-jamaahs",
        auth: "*",
        children: [
            {
                path: "detail/:id",
                element: <KloterDetailJamaahDetail />,
            },
            {
                path: "detail/:id/print",
                element: <PrintBuktiPendaftaran />,
            },
        ],
    },
    {
        label: "Kloters",
        path: "kloters",
        auth: "*",
        children: [
            {
                path: "new",
                element: <KloterNew />,
                children: "",
            },
            {
                path: "list",
                element: <KloterList />,
                children: "",
            },
            {
                path: "detail/:id",
                children: [
                    {
                        path: "",
                        element: <KloterDetail />,
                        children: "",
                    },
                ],
            },
        ],
    },
    {
        label: "Items",
        path: "items",
        auth: "*",
        children: [
            {
                path: "new",
                element: <ItemNew />,
            },
            {
                path: "list",
                element: <ItemList />,
            },
            {
                path: "kloter-jamaahs-items",
                element: <KloterJamaahsItems />,
            },
            {
                path: "detail/:id",
                element: <ItemDetail />,
            },
        ],
    },
    {
        path: "payments",
        auth: "*",
        children: [
            {
                path: "list",
                element: <PaymentList />,
                children: "",
            },
        ],
    },
    {
        path: "kas",
        auth: "*",
        children: [
            {
                path: "list",
                element: <KasList />,
                children: "",
            },
        ],
    },
    {
        path: "journal",
        auth: "*",
        children: [
            {
                path: "list",
                element: <JournalList />,
                children: "",
            },
        ],
    },
    {
        path: "account",
        auth: "*",
        children: [
            {
                path: "list",
                element: <AccountList />,
                children: "",
            },
        ],
    },
    {
        path: "setting",
        auth: "*",
        children: [
            {
                path: "users",
                element: <UserList />,
                children: "",
            },
        ],
    },
    {
        path: "manifest",
        auth: "*",
        children: [
            {
                path: "kloter-manifest",
                element: <ManifestPerKloter />,
                children: "",
            },
            {
                path: "kloter-room",
                element: <KloterRoomWelcome />,
                children: "",
            },
            {
                path: "kloter-room/:kloter_id",
                element: <KloterRoomSetting />,
                children: "",
            },
        ],
    }
];

const authRouters = [
    {
        path: "/",
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <Home />,
                children: "",
            },
        ]
    },
    {
        path: "Login",
        element: <AuthLogin />,
        children: "",
    },
    {
        path: "Register",
        element: <AuthRegister />,
        children: "",
    }
]


const accessToken = getAccessToken()
let protectedRoutersFiltered = []

if (accessToken) {
    const role = jwt_decode(accessToken)?.role_name
    if (role) {
        if (role === "manager" || role === "superadmin") {
            protectedRoutersFiltered = protectedRouters
        }
        // if (role === "superadmin") {
        //     protectedRoutersFiltered = protectedRouters.filter(element => element.auth === "superadmin")
        //     protectedRoutersFiltered = protectedRoutersFiltered.concat(protectedRouters.filter(element => element.auth === "manager"))
        // }
        // if (role === "manager") protectedRoutersFiltered = protectedRouters.filter(element => element.auth === "manager")
    }
}

export const myRouters = authRouters.concat(protectedRoutersFiltered);