import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_FETCH_DATA_LOADING:
            return {
                ...state,
                isFetchDataLoading: action.value
            }
        case type.CHANGE_LIST_KLOTERS:
            return {
                ...state,
                listKloters: action.value
            }
        case type.CHANGE_PLAN_DATE:
            return {
                ...state,
                planDate: action.value
            }
        case type.CHANGE_MODAL_ADD_TO_KLOTER_OPEN:
            return {
                ...state,
                isModalAddToKloterOpen: action.value
            }
        case type.CHANGE_ADD_TO_KLOTER_SAVE_LOADING:
            return {
                ...state,
                isAddToKloterSaveLoading: action.value
            }
        case type.CHANGE_AVAIL_KLOTER_LIST:
            return {
                ...state,
                availKloterList: action.value
            }
        case type.CHANGE_FETCH_AVAIL_KLOTER_LOADING:
            return {
                ...state,
                isAvailKloterListLoading: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        default:
            return state
    }
}

const defaultState = () => ({
    isFetchDataLoading: false,
    listKloters:[],
    planDate: '',
    isModalAddToKloterOpen: false,
    isAddToKloterSaveLoading: false,
    availKloterList: [],
    isAvailKloterListLoading: false,
})

export default main