import { Get, Post, Delete, Put } from "../../../helpers/ajax";
import { convert } from "../../../helpers/date";
import { BASE_API_URL, BASE_URL } from "../../../config";

import { notification } from 'antd';

const key = "JAMAAH_DETAIL"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_JAMAAH_DETAIL: `${key}_CHANGE_JAMAAH_DETAIL`,
    CHANGE_HEIR_LOADING: `${key}_CHANGE_HEIR_LOADING`,
    CHANGE_JAMAAH_HEIR: `${key}_CHANGE_JAMAAH_HEIR`,
    CHANGE_MODAL_ADD_HEIR: `${key}_CHANGE_MODAL_ADD_HEIR`,
    CHANGE_HEIR_SAVE_LOADING: `${key}_CHANGE_HEIR_SAVE_LOADING`,
    CHANGE_IMAGE_UPLOAD_LOADING: `${key}_CHANGE_IMAGE_UPLOAD_LOADING`,
    CHANGE_MODAL_UPDATE_OPEN: `${key}_CHANGE_MODAL_UPDATE_OPEN`,
    CHANGE_SAVE_UPDATE_LOADING: `${key}_CHANGE_SAVE_UPDATE_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeJamaahDetail = (value) => ({
    type: type.CHANGE_JAMAAH_DETAIL,
    value
})

export const changeHeirLoading = (value) => ({
    type: type.CHANGE_HEIR_LOADING,
    value
})

export const changeHeirSaveLoading = (value) => ({
    type: type.CHANGE_HEIR_SAVE_LOADING,
    value
})

export const changeJamaahHeir = (value) => ({
    type: type.CHANGE_JAMAAH_HEIR,
    value
})

export const changeModalAddHeir = (value) => ({
    type: type.CHANGE_MODAL_ADD_HEIR,
    value
})

export const changeImageUploadLoading = (value) => ({
    type: type.CHANGE_IMAGE_UPLOAD_LOADING,
    value
})

export const changeModalUpdateOpen = (value) => ({
    type: type.CHANGE_MODAL_UPDATE_OPEN,
    value
})

export const changeSaveUpdateLoading = (value) => ({
    type: type.CHANGE_SAVE_UPDATE_LOADING,
    value
})

export const handleGetJamaahDetail = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeLoading(true))
        const res = await Get(`${BASE_API_URL}/jamaah/${id}/detail`);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeJamaahDetail(res.data || {}))
        return
    }
}

export const handleGetJamaahHeir = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeHeirLoading(true))
        const res = await Get(`${BASE_API_URL}/jamaah/${id}/detail/show-heir`);
        dispatch(changeHeirLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeJamaahHeir(res.data || {}))
        return
    }
}

export const handleSaveJamaahHeir = (data, id) => {
    return async (dispatch, getState) => {
        data.dob = convert(data.dob)
        dispatch(changeHeirSaveLoading(true))
        const res = await Post(`${BASE_API_URL}/jamaah/${id}/detail/add-heir`, data);
        dispatch(changeHeirSaveLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeModalAddHeir())
        dispatch(handleGetJamaahHeir(id))
        return
    }
}

export const handleUploadFoto = (urlBase64, id) => {
    return async (dispatch, getState) => {
        const data = {
            "image": urlBase64
        }

        dispatch(changeImageUploadLoading(true))
        const res = await Post(`${BASE_API_URL}/jamaah/${id}/detail/upload-foto`, data);
        dispatch(changeImageUploadLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeJamaahDetail(res.data || {}))
    }
}

export const handleDeleteImage = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeImageUploadLoading(true))
        const res = await Delete(`${BASE_API_URL}/jamaah/${id}/detail/remove-foto`);
        dispatch(changeImageUploadLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        window.location.replace(`${BASE_URL}/jamaah/detail/${res.data.id}`);
    }
}

export const handleUpdateJamaahData = (data, id) => {
    return async (dispatch, getState) => {
        dispatch(changeSaveUpdateLoading(true))
        const res = await Put(`${BASE_API_URL}/jamaah/${id}/detail`, data);
        dispatch(changeSaveUpdateLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeModalUpdateOpen(false))
        dispatch(handleGetJamaahDetail(id))
        return
    }
}

export default type