import React, {
    useEffect, useState,
} from 'react';
import {
    Table, Card, Select, Button,
    Row, Col, Input, notification
} from 'antd';
import moment from 'moment';
import { Link } from "react-router-dom";

import Layout from "../../../layout"
import { useManifestData, useFetchKloters } from "./ManifestPerKloterActionHook"
import { BASE_API_URL } from '../../../config';

const InputGroup = Input.Group

const App = () => {
    const [selectKloterId, setSelectedKloterId] = useState(null)
    const { data, loading, fetchManifestData } = useManifestData();
    const { kloters, fetchKlotersLoading, fetchKloters } = useFetchKloters();

    useEffect(() => {
        document.title = 'Kloter Manifest';
        fetchKloters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFetchManifestData = () => {
        if (selectKloterId === null) {
            return notification.warning({
                message: 'Pilih Kloter Terlebih Dahulu',
            })
        }
        fetchManifestData(selectKloterId)
        return
    }

    const columns = [
        {
            title: 'Kode',
            dataIndex: 'kode',
            align: 'left',
            fixed: 'left',
            width: 100,
            render: (text, record) => <>{text}</>
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            align: 'left',
            fixed: 'left',
            width: 200,
            render: (text, record) => <Link to={`/jamaah/detail/${record.id}`}>{text}</Link>,
        },
        {
            title: 'Nama Passport',
            dataIndex: 'passport_name',
            align: 'left',
            width: 200,
        },
        {
            title: 'Nomer Passport',
            dataIndex: 'passport_number',
            align: 'left',
            width: 100,
        },
        {
            title: 'Nama Ayah',
            dataIndex: 'father_name',
            align: 'left',
            width: 100,
        },
        {
            title: 'Jenis Identitas',
            dataIndex: 'nik',
            align: 'left',
            width: 100,
            render: (text, record) => <>{'NIK'}</>
        },
        {
            title: 'Nomer Identitas',
            dataIndex: 'nik',
            align: 'left',
            width: 150,
        },
        {
            title: 'Jenis Kelamin',
            dataIndex: 'nik',
            align: 'left',
            width: 70,
            render: (text, record) => <>{(record.gender === "male") && "Pria"} {(record.gender === "female") && "Wanita"}</>
        },
        {
            title: 'Tempat Lahir',
            dataIndex: 'pob',
            align: 'left',
            width: 150,
        },
        {
            title: 'Tanggal Lahir',
            dataIndex: 'dob',
            align: 'left',
            width: 150,
            render: (text, record) => <>{moment(record.dob).format('DD MMMM YYYY')}</>
        },
        {
            title: 'Alamat',
            dataIndex: 'address',
            align: 'left',
            width: 250,
        },
        {
            title: 'Desa',
            dataIndex: 'desa',
            align: 'left',
            width: 250,
        },
        {
            title: 'Kecamatan',
            dataIndex: 'kecamatan',
            align: 'left',
            width: 250,
        },
        {
            title: 'Kabupaten',
            dataIndex: 'kabupaten',
            align: 'left',
            width: 250,
        },
        {
            title: 'Propinsi',
            dataIndex: 'provinsi',
            align: 'left',
            width: 250,
        },
        {
            title: 'Kewarganegaraan',
            dataIndex: 'provinsi',
            align: 'left',
            width: 100,
            render: (text, record) => <>{'WNI'}</>
        },
        {
            title: 'No HP',
            dataIndex: 'phone',
            align: 'left',
            width: 110,
        },
        {
            title: 'Pendidikan',
            align: 'left',
            width: 100,
        },
        {
            title: 'Pekerjaan',
            dataIndex: 'occupation',
            align: 'left',
            width: 100,
        },
    ]

    return (
        <>
            <Layout>
                <Card title="Kloter Manifest">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <InputGroup compact>
                                <Select
                                    placeholder="Pilih Kloter"
                                    loading={fetchKlotersLoading}
                                    style={{
                                        width: "80%",
                                    }}
                                    options={kloters}
                                    onChange={setSelectedKloterId}
                                />
                                <Button
                                    style={{ width: '20%' }} type="primary" onClick={() => handleFetchManifestData()}
                                >
                                    Set
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={{ span: 4, offset: 12 }} style={{ textAlign: 'right' }}>
                            <Button
                                style={{
                                    width: '100%',
                                    backgroundColor: '#73d13d',
                                    borderColor: '#73d13d',
                                    color: 'white', // Sesuaikan warna teks sesuai kebutuhan
                                }}
                                type="success"
                                onClick={() => {
                                    if (selectKloterId === null) {
                                        return notification.warning({
                                            message: "Pilih Kloter Terlebih Dahulu",
                                        })
                                    }
                                    const newTab = window.open(`${BASE_API_URL}/manifest/per-kloter-excel/${selectKloterId}`, '_blank');
                                    newTab.focus();
                                }}
                            >
                                Download Excel
                            </Button>
                        </Col>
                    </Row>

                    <Table
                        style={{ marginTop: 5 }}
                        columns={columns}
                        dataSource={data || []}
                        loading={loading}
                        rowKey={items => items.id}
                        pagination={false}
                        size="small"
                        scroll={{
                            x: 1240,
                        }}
                    />
                </Card>
            </Layout>

        </>
    );
};


export default App;