import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import moment from 'moment';
import { Link } from "react-router-dom";

import { truncateText } from "../../../../helpers/randomThings";
import { rupiahFormat } from "../../../../helpers/currency";
import { useListLastPayments } from "./LastPaymentsActionHook"
import ModalPrintInvoice from '../../../Kloter/KloterDetailJamaahDetail/childComponents/ModalPrintInvoice';


const App = () => {

    const [modalInvoiceOpen, setModalInvoiceOpen] = useState(false);
    const [paymentData, setPaymentData] = useState(null);

    const { data, loading, fetchLastPayments } = useListLastPayments();


    useEffect(() => {
        fetchLastPayments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: 'Tanggal Bayar',
            dataIndex: 'date',
            render: (text, record) => (moment(text).format('DD MMMM YYYY')),
            align: 'center',
        },
        {
            title: 'Nominal',
            dataIndex: 'nominal',
            align: 'right',
            width: "50px",
            render: (text, record) => rupiahFormat(text),
        },
        {
            title: 'Nama Jamaah',
            dataIndex: 'jamaah_name',
            align: 'center',
            width: "200px",
            render: (text, record) => <small>{truncateText(text, 20)}</small>,
        },
        {
            title: 'Kloter',
            dataIndex: 'kloter_name',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'is_verified',
            align: 'center',
            render: (text, record) => {
                if (text === 1) {
                    return (
                        <>Terverikasi</>
                    )
                }
                return (
                    < >Belum Terverikasi</>
                )
            },
        },
        {
            title: 'Aksi',
            align: 'center',
            render: (text, record) => <>
            <Link onClick={() => {
                setModalInvoiceOpen(true)
                setPaymentData(record)
            }}>Cetak</Link>
            &nbsp;|&nbsp;
            <Link onClick={() => {
                window.open(`https://app.zamzamsolo.com/print/?id=${record.id}`, '_blank');
                // setModalInvoiceOpen(true)
                // setPaymentData(record)
            }}>Cetak Baru</Link>
            </>,
        },
    ]

    return (
        <>
            <ModalPrintInvoice handleModalClose={() => setModalInvoiceOpen(false)} modalInvoiceOpen={modalInvoiceOpen} kloterJamaahData={paymentData} paymentData={[]} />
            <Button onClick={fetchLastPayments}>Refresh Data</Button>
            <Table
                style={{ marginTop: 5 }}
                columns={columns}
                dataSource={data || []}
                loading={loading}
                rowKey={items => items.id}
                pagination={false}
                size="small"
            />
        </>
    );
};

export default App;