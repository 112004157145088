import React, { } from 'react'
import { Button, Modal, Spin } from 'antd';

const App = (props) => {

    const {
        // data
        accountDeleteData, deleteLoading,
        // actions
        handleDeleteAccount, changeAccountDeleteData,
    } = props


    return (
        <Modal
            title="Apakah anda yakin menghapus akun ini?"
            open={(accountDeleteData.hasOwnProperty('id') ? true : false)}
            layout="horizontal"
            style={{ top: 20 }}
            width={700}
            closable={false}
            destroyOnClose
            footer={[
                <Button key="back" onClick={() => changeAccountDeleteData({})} disabled={deleteLoading}>
                    Cancel
                </Button>,
                <Button key="submit" type="danger" onClick={() => handleDeleteAccount(accountDeleteData.id)} disabled={deleteLoading}>
                    Proses Delete
                </Button>
            ]}
        >
            <Spin spinning={deleteLoading}>
                <p>Nama Akun: {accountDeleteData.name}</p>
                <p>Group: {accountDeleteData.group}</p>
            </Spin>

        </Modal>
    )
}

export default App;

