import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import {
    Button, Modal, Form, Input, TreeSelect, InputNumber
} from 'antd';
const Textarea = Input.TextArea

function App(props) {
    const [cost, setCost] = useState(0)
    let { id: klotersJamaahsId } = useParams();

    const {
        isModalAddPaymentShow, isPaymentMethodLoading, paymentMethodsData, isLoadingSave,
        handleCloseModalAddPayment, handleFetchPaymentMethods, handleSavePayment,
    } = props

    const nominalIsValid = (cost) => {
        if (!cost) {
            return Promise.reject(new Error('Nominal harus diisi'))
        }
        if (cost.toString().length < 5) {
            return Promise.reject(new Error('Nominal minimal 6 digit'))
        }
        if (cost < 1) {
            return Promise.reject(new Error('Nominal harus positif'))
        }
        return Promise.resolve()
    }

    return (
        <>
            <Modal
                title="Tambah pembayaran"
                open={isModalAddPaymentShow}
                onCancel={() => handleCloseModalAddPayment()}
                style={{ top: 20 }}
                bodyStyle={{
                    height: "100%",
                }}
                footer={null}
                disabled
                destroyOnClose
                width={"700px"}
            >
                <Form
                    labelCol={{ span: 5 }}
                    layout="horizontal"
                    onFinish={(data) => handleSavePayment(data, klotersJamaahsId)}
                >
                    <Form.Item label="Tanggal Bayar" name={"date"} initialValue={localStorage.getItem("dateNow")}
                        rules={[{ required: true, message: 'Date harus diisi' }]}
                    >
                        <Input
                            onChange={(val) => { localStorage.setItem("dateNow", val.target.value) }}
                            placeholder="format: d-m-Y, contoh: 30-01-2002"
                        />
                    </Form.Item>

                    <Form.Item label="Method" name={"payment_method_id"}
                        rules={[{ required: true, message: "Metode harus dipilih" }]}
                    >
                        <TreeSelect treeData={paymentMethodsData} onDropdownVisibleChange={() => handleFetchPaymentMethods()} loading={isPaymentMethodLoading} />
                    </Form.Item>
                    <Form.Item label="Nominal" name={"nominal"}
                        rules={[{ required: true, message: 'Nominal harus diisi' }, { validator: () => nominalIsValid(cost) }]}
                    >
                        <InputNumber
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            style={{ width: 200 }} addonBefore="Rp"
                            onChange={setCost}
                            decimalSeparator={false}
                        />
                    </Form.Item>
                    <Form.Item label="Deskripsi" name={"description"} rules={[{ min: 2, max: 100 }]}>
                        <Textarea min={2} max={100} ></Textarea>
                    </Form.Item>
                    <Form.Item>
                        <div style={{ float: 'right' }}>
                            <Button loading={isLoadingSave} type="primary" style={{ marginRight: 2 }} htmlType="submit">Proses</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default App;
