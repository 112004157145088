import React from 'react'
import { Modal, Button, Row, Col, } from "antd";
import moment from "moment";
import { rupiahFormat } from '../../../../helpers/currency';

const rowInput = {
    marginBottom: 5,
}

const App = (
    {
        kasDeleteData, saveKasMasukLoading,
        changeKasDeleteData, handleDeleteKasSave,
    }
) => {
    const hadleSimpanBtnClick = () => {
        handleDeleteKasSave(kasDeleteData.no_bukti)
        return
    }

    const color = kasDeleteData.type === 'd' ? '#52c41a' : '#f5222d';

    return (
        <div>
            <Modal
                title={`Hapus Data Kas?`}
                open={kasDeleteData.hasOwnProperty('id')}
                onCancel={() => {
                    changeKasDeleteData({});
                }}
                style={{ top: 20 }}
                bodyStyle={{
                    height: "100%",
                }}
                width={"800px"}
                footer={[
                    <Button
                        key={1}
                        onClick={() => {
                            changeKasDeleteData({})
                        }}
                        loading={saveKasMasukLoading}
                        disabled={saveKasMasukLoading}
                    >
                        cancel
                    </Button>,
                    <Button
                        key={2} type='danger' onClick={hadleSimpanBtnClick}
                        loading={saveKasMasukLoading}
                        disabled={saveKasMasukLoading}
                    >
                        Porses Hapus
                    </Button>
                ]}
                disabled
                destroyOnClose
            >
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>No Bukti</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {kasDeleteData?.no_bukti}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Nominal</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            <span style={{ color }}>{rupiahFormat(kasDeleteData.nominal)}</span>
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Type</label>
                    </Col>
                    <Col span={18}>
                        {
                            (kasDeleteData.type === "d")
                                ?
                                <span style={{ color }}>Debit</span>
                                :
                                <span style={{ color }}>Kredit</span>
                        }
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Tanggal</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {moment(kasDeleteData?.date).format('DD MMMM YYYY')}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Tanggal Input</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {moment(kasDeleteData?.created_at).format('DD MMMM YYYY')}
                        </span>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} style={rowInput}>
                    <Col span={6}>
                        <label style={{ fontWeight: 'bold' }}>Deskripsi</label>
                    </Col>
                    <Col span={18}>
                        <span>
                            {kasDeleteData?.description}
                        </span>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default App