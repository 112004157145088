import React, {
    useEffect,
} from 'react';
import {
    Card, Table, Row, Col, Button, notification,
} from 'antd';
import { Link, useParams } from "react-router-dom";

import Layout from "../../../layout"


import ModalAddRoom from "./ChildComponents/ModalAddRoom"
import ModalUpdateRoom from "./ChildComponents/ModalUpdateRoom"
import ModalDeleteRoom from "./ChildComponents/ModalDeleteRoom"
import TableJamaahInKloter from "./ChildComponents/TableJamaahInKloter"

import { useFetchKloter } from "./actionHook"

const App = (props) => {
    const { kloter_id } = useParams()
    const { kloter, fetchKloter } = useFetchKloter();

    const {
        // actions
        changeModalAddRoomOpen, changeModalUpdateRoomData, handleQueue, changeModalDeleteRoomData,
        // data
        roomData, fetchRoomLoading,
    } = props

    useEffect(() => {
        document.title = 'Kloter Room Setting';
        fetchKloter(kloter_id)
        handleQueue(kloter_id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [

        {
            title: 'Nama',
            dataIndex: 'name',
            align: 'left',
            fixed: 'left',
            width: 200,
            render: (text, record) => <>{text}</>,
        },
        {
            title: 'Keterangan',
            dataIndex: 'description',
            align: 'left',
            width: 200,
            render: (text, record) => <>{text}</>,
        },
        {
            title: 'Terisi',
            dataIndex: 'occupancy',
            align: 'left',
            width: 200,
            render: (text, record) => <>{text}</>,
        },
        {
            title: 'Action',
            align: 'center',
            width: 200,
            render: (text, record) => {
                return (
                    <>
                        <Link onClick={() => changeModalUpdateRoomData(record)}>Update</Link>
                        &nbsp;|&nbsp;
                        <Link style={{ color: '#f5222d' }} onClick={() => {
                            if (record.occupancy !== 0) {
                                return notification.warning({
                                    message: 'Kamar sudah terisi, Pindahkan Jamaah Dulu',
                                })
                            }
                            changeModalDeleteRoomData(record)
                            return
                        }}>Hapus</Link>
                    </>
                )
            },
        },
    ]

    return (
        <>
            <ModalAddRoom {...props} />
            <ModalUpdateRoom {...props} />
            <ModalDeleteRoom {...props} />
            <Layout>
                <Card title={kloter?.name}>
                    <Row>
                        <Col xs={24} sm={24} md={8} lg={{ span: 4, offset: 20 }} style={{ textAlign: 'right' }}>
                            <Button
                                type="primary" onClick={() => changeModalAddRoomOpen(true)}
                            >
                                + Buat Kamar Baru
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        style={{ marginTop: 5 }}
                        columns={columns}
                        dataSource={roomData || []}
                        loading={fetchRoomLoading}
                        rowKey={data => data.id}
                        pagination={false}
                        size="small"
                    />
                </Card>

                <Card title={"List Jamaah"}
                    style={{ marginTop: 5 }}
                >
                    <TableJamaahInKloter {...props} />
                </Card>
            </Layout>
        </>
    );
};


export default App;