import { Get, Put } from "../../../helpers/ajax";
import { BASE_URL, BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "ITEM_DETAIL"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_ITEM_DETAIL: `${key}_CHANGE_ITEM_DETAIL`,
    CHANGE_ACTIVE_OR_INACTIVE_ITEM_LOADING: `${key}_CHANGE_ACTIVE_OR_INACTIVE_ITEM_LOADING`,
    CHANGE_MODAL_ACTIVE_OR_INACTIVE_ITEM_OPEN: `${key}_CHANGE_MODAL_ACTIVE_OR_INACTIVE_ITEM_OPEN`,
    CHANGE_MODAL_UPDATE_OPEN: `${key}_CHANGE_MODAL_UPDATE_OPEN`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeItemDetail = (value) => ({
    type: type.CHANGE_ITEM_DETAIL,
    value
})

export const changeActiveOrInactiveItemLoading = (value) => ({
    type: type.CHANGE_ACTIVE_OR_INACTIVE_ITEM_LOADING,
    value
})

export const changeModalActiveOrInactiveItemOpen = (value) => ({
    type: type.CHANGE_MODAL_ACTIVE_OR_INACTIVE_ITEM_OPEN,
    value
})

export const changeModalUpdateOpen = (value) => ({
    type: type.CHANGE_MODAL_UPDATE_OPEN,
    value
})

export const handleGetItemDetail = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeLoading(true))
        const res = await Get(`${BASE_API_URL}/items/${id}`);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeItemDetail(res.data || {}))
        return
    }
}

export const handleActiveOrInactiveItem = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeActiveOrInactiveItemLoading(true))
        const res = await Get(`${BASE_API_URL}/items/${id}/active-toggle`);
        dispatch(changeActiveOrInactiveItemLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        window.location.replace(`${BASE_URL}/items/detail/${id}`);
        return
    }
}

export const handleUpdateItem = (id, data) => {
    return async (dispatch, getState) => {
        const res = await Put(`${BASE_API_URL}/items/${id}`, data);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeModalUpdateOpen(false))
        window.location.replace(`${BASE_URL}/items/detail/${id}`);
        return
    }
}


export default type