import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { Card, Table, Button, Modal, Form, Input, InputNumber } from 'antd';
import {
  EditOutlined, DeleteOutlined,
} from '@ant-design/icons';

import { rupiahFormat } from "../../../../../helpers/currency";

const Textarea = Input.TextArea

const App = (props) => {
  let { id: kloterId } = useParams();
  const {
    // data
    isFetchLoading, additionalItems, modalAddOpen, additionalItemUpdate, additionalItemDelete,
    // actions
    handleFetchAdditionalItems, changeModalAddOpen, handleSaveAdditionalItem, changeAdditionalItemUpdate,
    handleUpdateAdditionalItem, changeAdditionalItemDelete, handleDeleteAdditionalItem,
  } = props
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Biaya',
      dataIndex: 'cost',
      align: 'right',
      render: (text) => rupiahFormat(text),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Link onClick={() => changeAdditionalItemUpdate(record)}><EditOutlined /></Link> |
            <Link onClick={() => changeAdditionalItemDelete(record)}><DeleteOutlined /></Link>
          </>
        )
      },
    },
  ];

  React.useEffect(() => {
    handleFetchAdditionalItems(kloterId)
  }, [handleFetchAdditionalItems, kloterId]);

  return (
    <>
      <Card>
        <div style={{ marginBottom: "15px" }}>
          <Button
            type='primary' style={{ float: "right" }}
            onClick={() => changeModalAddOpen(true)}
            disabled={false}
          >
            +Tambah Data
          </Button>
        </div>
        <Table
          style={{ marginTop: 5 }}
          columns={columns}
          dataSource={additionalItems?.additional_items}
          loading={isFetchLoading}
          rowKey={data => data.id}
          pagination={false}
        />
      </Card>
      
      <Modal
        title="Tambah Data?"
        open={modalAddOpen}
        footer={null}
        onCancel={() => changeModalAddOpen(false)}
        width={800}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={(data) => handleSaveAdditionalItem(kloterId, data)}
        >
          <Form.Item label="Nama Item" name={"name"} rules={[{ required: true, min: 2, max: 50 }]}>
            <Textarea min={2} max={50} />
          </Form.Item>
          <Form.Item label="Biaya" name={"cost"} rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <div style={{ float: 'right' }}>
            <Button type="primary" style={{ top: -20 }} htmlType="submit">Simpan Data</Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Update Data?"
        open={(additionalItemUpdate.hasOwnProperty('name')) ? true : false}
        footer={null}
        onCancel={() => changeAdditionalItemUpdate({})}
        width={800}
        destroyOnClose
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          onFinish={(data) => handleUpdateAdditionalItem(kloterId, additionalItemUpdate.id, data)}
        >
          <Form.Item label="Nama Item" name={"name"} rules={[{ required: true, min: 2, max: 50 }]} initialValue={additionalItemUpdate?.name}>
            <Textarea min={2} max={50} />
          </Form.Item>
          <Form.Item label="Biaya" name={"cost"} rules={[{ required: true }]} initialValue={additionalItemUpdate?.cost}>
            <InputNumber />
          </Form.Item>
          <div style={{ float: 'right' }}>
            <Button type="primary" style={{ top: -20 }} htmlType="submit">Simpan Data</Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Apakah anda yakin Menghapus item ini?"
        open={(additionalItemDelete.hasOwnProperty('name')) ? true : false}
        footer={[
          <Button key="ok" type='danger' onClick={() => handleDeleteAdditionalItem(kloterId, additionalItemDelete.id)}>
            Konfirmasi
          </Button>
        ]}
        destroyOnClose
        onCancel={() => changeAdditionalItemDelete({})}
      >
        <p><strong>Nama Item:</strong> {additionalItemDelete.name}</p>
        <p><strong>Biaya:</strong> {rupiahFormat(additionalItemDelete?.cost)}</p>
      </Modal>
    </>
  )
}

export default App;

