import React, {
    useEffect,
} from 'react';
import {
    Card, Modal, Spin, Button
} from 'antd';
import { useNavigate } from "react-router-dom";

import Layout from "../../../layout"
import { useFetchKloters } from "./KloterRoomActionHook"

const App = () => {
    const { kloters, fetchKlotersLoading, fetchKloters } = useFetchKloters();

    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Kloter Manifest';
        fetchKloters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Layout>
                <Card title="Room Terdaftar">
                </Card>
                <Modal
                    title="Pilih Kloter"
                    open={true}
                    confirmLoading={() => alert()}
                    disabled
                    closable={false}
                    footer={false}
                >
                    <Spin spinning={fetchKlotersLoading}>
                        {
                            (kloters) &&
                            kloters.map((kloter, index) => {
                                return (
                                    <Button
                                        key={index}
                                        style={{ width: "100%", margin: 3 }}
                                        onClick={() => navigate(`/manifest/kloter-room/${kloter.value}`)}
                                    >
                                        {kloter.label}
                                    </Button>
                                )
                            })
                        }
                    </Spin>
                </Modal>
            </Layout>

        </>
    );
};


export default App;