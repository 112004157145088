import { useState } from 'react';
import { notification } from 'antd';
import { BASE_API_URL } from "../../../../../config";
import { Get } from "../../../../../helpers/ajax";

export const useFetchDataToPrint = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchData = async (klotersJamaahsId) => {
        setLoading(true)
        const res = await Get(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/detail`);
        if (!res) {
            setLoading(false)
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            setLoading(false)
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        setLoading(false)
        setData(res.data)
    };

    return { data, loading, fetchData };
};