import React, { } from 'react'
import { Modal, Descriptions, Button } from 'antd';
import { rupiahFormat } from "../../../../helpers/currency";
import moment from 'moment';

const App = (props) => {
    const {
        // data
        modalVerificationData,
        // actions
        changeModalVerificationData, handleVerifyPayment,
    } = props

    return (
        <Modal
            open={(modalVerificationData.hasOwnProperty('id') ? true : false)}
            onCancel={() => changeModalVerificationData({})}
            destroyOnClose
            width={600}
            footer={[
                <Button key="back" type='danger' onClick={() => handleVerifyPayment(modalVerificationData.id)}>
                    Proses Verifikasi
                </Button>
            ]}
        >
            <Descriptions title="Verifikasi Pembayaran Ini?" layout="vertical">
                <Descriptions.Item label="Nominal"><strong>{rupiahFormat(modalVerificationData.nominal)}</strong></Descriptions.Item>
                <Descriptions.Item label="Metode Pembayaran"><strong>{modalVerificationData.payment_method}</strong></Descriptions.Item>
                <Descriptions.Item label="Tanggal Bayar"><strong>{(moment(modalVerificationData.created_at).format('DD MMMM YYYY'))}</strong></Descriptions.Item>
                <Descriptions.Item label="PaymentMethods"><strong>{modalVerificationData.kloter_name}</strong></Descriptions.Item>
                <Descriptions.Item label="Nama jamaah"><strong>{modalVerificationData.jamaah_name}</strong></Descriptions.Item>
            </Descriptions>

        </Modal>
    )
}

export default App;

