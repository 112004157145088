import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_KAS_DATA:
            return {
                ...state,
                kasData: action.value
            }
        case type.CHANGE_MODAL_KAS_MASUK_OPEN:
            return {
                ...state,
                modalKasMasukOpen: action.value
            }
        case type.CHANGE_SAVE_KAS_MASUK_LOADING:
            return {
                ...state,
                saveKasMasukLoading: action.value
            }
        case type.CHANGE_MODAL_KAS_KELUAR_OPEN:
            return {
                ...state,
                modalKasKeluarOpen: action.value
            }
        case type.CHANGE_SAVE_KAS_KELUAR_LOADING:
            return {
                ...state,
                saveKasKeluarLoading: action.value
            }
        case type.CHANGE_ACCOUNT_DATA:
            return {
                ...state,
                accountData: action.value
            }
        case type.CHANGE_TYPE:
            return {
                ...state,
                type: action.value
            }
        case type.CHANGE_SORTER:
            return {
                ...state,
                sorter: action.value
            }
        case type.CHANGE_FILTER_DESCRIPTION:
            return {
                ...state,
                filterDescription: action.value
            }
        case type.CHANGE_FILTER_NO_BUKTI:
            return {
                ...state,
                filterNoBukti: action.value
            }
        case type.CHANGE_FILTER_DATE:
            return {
                ...state,
                filterDate: action.value
            }
        case type.CHANGE_PAGE:
            return {
                ...state,
                page: action.value
            }
        case type.CHANGE_KAS_DELETE_DATA:
            return {
                ...state,
                kasDeleteData: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    kasData: {},

    modalKasMasukOpen: false,
    saveKasMasukLoading: false,
    modalKasKeluarOpen: false,
    saveKasKeluarLoading: false,
    accountData: [],

    // pagination
    page: 1,
    perPage: 50,

    // filter and sort
    type: null,
    sorter: null,
    filterDescription: null,
    filterNoBukti: null,
    filterDate: null,

    kasDeleteData: {},
})

export default main