import { connect } from 'react-redux'
import App from './component'
import {
    handleNewBranchSave,
    handleGetRegions, changeKecamatanId,
    handleCheckKodeIsTaken,
    handleCheckEmailIsTaken,
    handleCheckPhoneIsTaken,

    handleCheckOwnerPhoneIsTaken,
    handleCheckOwnerNIKIsTaken,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.branchNew,
})

const mapDispatchToProps = {
    handleNewBranchSave,
    handleGetRegions, changeKecamatanId,
    handleCheckKodeIsTaken,
    handleCheckEmailIsTaken,
    handleCheckPhoneIsTaken,

    handleCheckOwnerPhoneIsTaken,
    handleCheckOwnerNIKIsTaken,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)