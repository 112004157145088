import React, { } from 'react';
import { Modal, Button } from 'antd';
import { useParams } from "react-router-dom";

const App = (props) => {
    const { kloter_id } = useParams()
    const {
        modalDeleteRoomData, deleteRoomLoading,
        changeModalDeleteRoomData, handleDeleteRoom,
    } = props
    const handleDeleteBtnClick = async () => {
        await handleDeleteRoom(modalDeleteRoomData.id, kloter_id)
        await changeModalDeleteRoomData({})
    }
    return (
        <>
            <Modal
                title="Delete Room"
                open={(modalDeleteRoomData.hasOwnProperty("id")) ? true : false}
                onCancel={() => {
                    changeModalDeleteRoomData({})
                }}
                destroyOnClose
                footer={[
                    <Button key="cancel" type='default' onClick={() => changeModalDeleteRoomData({})} loading={deleteRoomLoading}>
                        Batalkan
                    </Button>,
                    <Button key="ok" type='primary' onClick={() => handleDeleteBtnClick()} loading={deleteRoomLoading}>
                        Proses Delete
                    </Button>,
                ]}
            >
                <p>Delete Kamar {modalDeleteRoomData.name}</p>
                <p>Terisi {modalDeleteRoomData.occupancy} Jamaah</p>
            </Modal>
        </>
    )
};
export default App;