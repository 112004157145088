import { Delete, Get, Post, Put } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "ACCOUNT_LIST"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_ACCOUNTS_DATA: `${key}_CHANGE_ACCOUNTS_DATA`,

    CHANGE_PAGE: `${key}_CHANGE_PAGE`,

    CHANGE_STATUS: `${key}_CHANGE_STATUS`,
    CHANGE_SORTER: `${key}_CHANGE_SORTER`,

    CHANGE_ACCOUNT_UPDATE_DATA: `${key}_CHANGE_ACCOUNT_UPDATE_DATA`,
    CHANGE_UPDATE_LOADING: `${key}_CHANGE_UPDATE_LOADING`,

    CHANGE_ACCOUNT_DELETE_DATA: `${key}_CHANGE_ACCOUNT_DELETE_DATA`,
    CHANGE_DELETE_LOADING: `${key}_CHANGE_DELETE_LOADING`,

    CHANGE_MODAL_NEW_OPEN: `${key}_CHANGE_MODAL_NEW_OPEN`,
    CHANGE_SAVE_LOADING: `${key}_CHANGE_SAVE_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeAccountsData = (value) => ({
    type: type.CHANGE_ACCOUNTS_DATA,
    value
})

export const changePage = (value) => ({
    type: type.CHANGE_PAGE,
    value
})

export const changeStatus = (value) => ({
    type: type.CHANGE_STATUS,
    value
})

export const changeSorter = (value) => ({
    type: type.CHANGE_SORTER,
    value
})

export const changeAccountUpdateData = (value) => ({
    type: type.CHANGE_ACCOUNT_UPDATE_DATA,
    value
})

export const changeUpdateLoading = (value) => ({
    type: type.CHANGE_UPDATE_LOADING,
    value
})

export const changeAccountDeleteData = (value) => ({
    type: type.CHANGE_ACCOUNT_DELETE_DATA,
    value
})

export const changeDeleteLoading = (value) => ({
    type: type.CHANGE_DELETE_LOADING,
    value
})

export const changeModalNewOpen = (value) => ({
    type: type.CHANGE_MODAL_NEW_OPEN,
    value
})

export const changeSaveLoading = (value) => ({
    type: type.CHANGE_SAVE_LOADING,
    value
})

export const handleFetchDataAccount = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const { status, sorter, page, perPage } = state.accountList

        let statusStringQuery = (status !== "") ? `&status=${status}` : ``
        let statusSorterQuery = (sorter !== null) ? `&sort_by=${sorter.field}&order=${sorter.order}` : ``

        let url = `${BASE_API_URL}/accounts?page=${page}&per_page=${perPage}${statusStringQuery}${statusSorterQuery}`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeAccountsData(res || []))
        return
    }
}

export const handleUpdateAccount = (id, data) => {
    return async (dispatch, getState) => {
        dispatch(changeUpdateLoading(true))
        const res = await Put(`${BASE_API_URL}/accounts/${id}`, data);
        dispatch(changeUpdateLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        await dispatch(changeAccountUpdateData({}))
        await dispatch(handleFetchDataAccount())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })

    }
}

export const handleSaveAccount = (data) => {
    return async (dispatch, getState) => {
        dispatch(changeSaveLoading(true))
        const res = await Post(`${BASE_API_URL}/accounts`, data);
        dispatch(changeSaveLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }
        await dispatch(changeModalNewOpen(false))
        await dispatch(handleFetchDataAccount())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })

    }
}

export const handleDeleteAccount = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeDeleteLoading(true))
        const res = await Delete(`${BASE_API_URL}/accounts/${id}`);
        dispatch(changeDeleteLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        await dispatch(changeAccountDeleteData({}))
        await dispatch(handleFetchDataAccount())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })

    }
}

export default type