import React, { } from 'react'
import { useParams } from 'react-router-dom';
import {
    Modal, Button
} from 'antd';
import { rupiahFormat } from "../../../../helpers/currency"


function App(props) {
    const {
        additionalItemToDelete, changeAdditionalItemToDelete, habdleDeleteAddtionalItemSave
    } = props
    let { id: klotersJamaahsId } = useParams();

    return (
        <>
            <Modal
                title={"Hapus item biaya tambahan"}
                open={(additionalItemToDelete.hasOwnProperty("id")) ? true : false}
                onCancel={() => changeAdditionalItemToDelete({})}
                disabled
                destroyOnClose
                footer={[
                    <Button
                        key="back" type='primary'
                        onClick={() => habdleDeleteAddtionalItemSave(klotersJamaahsId, additionalItemToDelete.id)}
                    >
                        Proses
                    </Button>
                ]}
                width={700}
            >
                <p>hapus item <strong>{additionalItemToDelete.name}</strong> dari biaya tambahan ?</p>
                <p>dengan biaya: <strong>{rupiahFormat(additionalItemToDelete.cost)}</strong></p>
            </Modal>
        </>
    )
}

export default App;
