import React, { } from 'react'
import { Button, Modal, Form, Input, Spin } from 'antd';

const App = (props) => {

    const {
        // data
        accountUpdateData, updateLoading,
        // actions
        handleUpdateAccount, changeAccountUpdateData,
    } = props

    const validateNumber = (rule, value, callback) => {
        const regex = /^[0-9]*$/; // Regex untuk angka (tanpa tanda + atau -)
        if (value && !regex.test(value)) {
            callback('Hanya angka yang diperbolehkan!');
        } else {
            callback();
        }
    };

    return (
        <Modal
            title="Update Data Akun?"
            open={(accountUpdateData.hasOwnProperty('id') ? true : false)}
            footer={null}
            layout="horizontal"
            style={{ top: 20 }}
            width={700}
            closable={false}
            destroyOnClose
        >
            <Spin spinning={updateLoading}>
                <Form
                    labelCol={{ span: 4 }}
                    layout="horizontal"
                    onFinish={(data) => handleUpdateAccount(accountUpdateData.id, data)}
                >
                    <Form.Item
                        label="Nama Akun" name={"name"} initialValue={accountUpdateData.name}
                        rules={[
                            { required: true, message: 'Nama Akun harus diisi!' },
                        ]}
                    >
                        <Input
                            onInput={e => e.target.value = e.target.value.toUpperCase()} min={2} max={50}
                            rules={[{ required: true, message: "required" }]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Group"
                        name="group"
                        initialValue={accountUpdateData.group}
                        rules={[
                            { required: true, message: 'Group harus diisi!' },
                            { validator: validateNumber }, // Gunakan validator untuk memastikan hanya angka yang diperbolehkan
                        ]}
                    >
                        <Input
                            onInput={e => e.target.value = e.target.value.toUpperCase()} min={2} max={50}
                            rules={[{ required: true, message: "required" }]}
                        />
                    </Form.Item>

                    <Form.Item label="Type" name={"type"} initialValue={accountUpdateData.type}>
                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()} min={2} max={50} />
                    </Form.Item>

                    <Form.Item label="Header" name={"header"} initialValue={accountUpdateData.header}>
                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()} min={2} max={50} />
                    </Form.Item>

                    <Form.Item label="Level" name={"level"} initialValue={accountUpdateData.level}>
                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()} min={2} max={50} />
                    </Form.Item>

                    <Form.Item>
                        <div style={{ float: 'right' }}>
                            <Button type="default" style={{ marginRight: 2 }} onClick={() => changeAccountUpdateData({})}>Cancel</Button>
                            <Button type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Perubahan</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>

        </Modal>
    )
}

export default App;

