import { Get, Post } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification, Tag } from 'antd';

const key = "KLOTER_JAMAAHS_ITEMS"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_DATA: `${key}_CHANGE_DATA`,
    CHANGE_ITEM_HEAD: `${key}_CHANGE_ITEM_HEAD`,
    CHANGE_MODAL_VERIFICATION_DATA: `${key}_CHANGE_MODAL_VERIFICATION_DATA`,
    CHANGE_VERIFY_LOADING: `${key}_CHANGE_VERIFY_LOADING`,
    CHANGE_MODAL_COMPLETING_ITEM_DATA: `${key}_CHANGE_MODAL_COMPLETING_ITEM_DATA`,
    CHANGE_SAVE_COMPLETING_ITEM_LOADING: `${key}_CHANGE_SAVE_COMPLETING_ITEM_LOADING`,
    CHANGE_KLOTERS_DATA: `${key}_CHANGE_KLOTERS_DATA`,
    CHANGE_SELECTED_KLOTER_ID: `${key}_CHANGE_SELECTED_KLOTER_ID`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})
export const changeData = (value) => ({
    type: type.CHANGE_DATA,
    value
})
export const changeItemHead = (value) => ({
    type: type.CHANGE_ITEM_HEAD,
    value
})

export const changeModalVerificationData = (value) => ({
    type: type.CHANGE_MODAL_VERIFICATION_DATA,
    value
})

export const changeVerifyLoading = (value) => ({
    type: type.CHANGE_VERIFY_LOADING,
    value
})

export const changeModalCompletingItemData = (value) => ({
    type: type.CHANGE_MODAL_COMPLETING_ITEM_DATA,
    value
})

export const changeSaveCompletingItemLoading = (value) => ({
    type: type.CHANGE_SAVE_COMPLETING_ITEM_LOADING,
    value
})

export const changeKlotersData = (value) => ({
    type: type.CHANGE_KLOTERS_DATA,
    value
})

export const changeSelectedKloterId = (value) => ({
    type: type.CHANGE_SELECTED_KLOTER_ID,
    value
})

export const handleFetchItemHead = () => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/items?is_active=1`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        let itemHead = []
        res.data.forEach((data, key) => {
            let temp = {
                title: data.name,
                dataIndex: data.name,
                align: 'center',
                key: key,
                render: (text, record) => {
                    if (text == null) {
                        return (
                            <Tag
                                color={"warning"} key={data?.id} style={{ cursor: "pointer" }}
                                onClick={() => dispatch(changeModalCompletingItemData({
                                    kloter_jamaah_id: record.kloters_jamaah_id,
                                    item_id: data.id,
                                    name: data.name,
                                    supporting_files_number: data.supporting_files_number
                                }))}
                            >
                                NoData
                            </Tag>
                        )
                    }
                    return (
                        <span
                            onClick={() => dispatch(changeModalVerificationData({
                                target_id: record[data.name + "_jamaah_kloter_item_id"],
                                status: text,
                                files: record[data.name + "_files"],
                                description: record[data.name + "_description"],
                                itemName: data.name,
                            }))}
                        >
                            {
                                // eslint-disable-next-line eqeqeq
                                (text == 0) &&
                                <Tag style={{ cursor: "pointer" }}>Menunggu Approve</Tag>
                            }
                            {
                                // eslint-disable-next-line eqeqeq
                                (text == 1) &&
                                <Tag style={{ cursor: "pointer" }} color="green">ok</Tag>
                            }
                            {
                                // eslint-disable-next-line eqeqeq
                                (text == 2) &&
                                <Tag color={'red'} style={{ cursor: "pointer" }}>Rejected</Tag>
                            }
                        </span>
                    )

                },
            }
            itemHead.push(temp)
        });

        dispatch(changeItemHead(itemHead))
        return
    }
}

export const handleFetchData = (kloterId) => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/jamaah-kloter-items/${kloterId}`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeData(res?.data || []))
        return
    }
}

export const verifyProcess = (jamaah_kloter_item_id, data) => {
    return async (dispatch, getState) => {
        await dispatch(changeVerifyLoading(true))
        const res = await Post(`${BASE_API_URL}/jamaah-kloter-items/verify/${jamaah_kloter_item_id}`, data);
        await dispatch(changeVerifyLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status === 'nok') {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        const state = getState()
        const { selectKloterId } = state.kloterJamaahsItems

        await dispatch(changeModalVerificationData({}))
        await dispatch(handleFetchData(selectKloterId))
        return
    }
}

export const habdleSaveCompletingItem = (klotersJamaahsId, itemId, files) => {
    return async (dispatch, getState) => {

        const payload = {
            item_id: itemId,
            images: files
        }

        await dispatch(changeSaveCompletingItemLoading(true))
        const res = await Post(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/items`, payload);
        await dispatch(changeSaveCompletingItemLoading(false))

        if (!res) return

        const state = getState()
        const { selectKloterId } = state.kloterJamaahsItems

        await dispatch(changeModalCompletingItemData({}))
        await dispatch(handleFetchData(selectKloterId))
    }
}

export const handleFetchDataKloter = () => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/kloters`
        const res = await Get(url);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        const kloters_val = [];

        if (res.data) {
            res.data.forEach(data => {
                const temp_val = {
                    label: data.name,
                    value: data.id,
                }
                kloters_val.push(temp_val);
            });
        }

        dispatch(changeKlotersData(kloters_val))
        return
    }
}

export default type