import { Get, Put } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import moment from 'moment';
import { notification } from 'antd';

const key = "KLOTER_DETAIL"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_KLOTER_DETAIL: `${key}_CHANGE_KLOTER_DETAIL`,
    
    CHANGE_ACTIVE_INACTIVE_KLOTER_LOADING: `${key}_CHANGE_ACTIVE_INACTIVE_KLOTER_LOADING`,    
    CHANGE_MODAL_INACTIVE_KLOTER_OPEN: `${key}_CHANGE_MODAL_INACTIVE_KLOTER_OPEN`, 
    CHANGE_MODAL_ACTIVE_KLOTER_OPEN: `${key}_CHANGE_MODAL_ACTIVE_KLOTER_OPEN`,

    CHANGE_MODAL_UPDATE_KLOTER_OPEN: `${key}_CHANGE_MODAL_UPDATE_KLOTER_OPEN`,
    CHANGE_UPDATE_KLOTER_LOADING: `${key}_CHANGE_UPDATE_KLOTER_LOADING`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeKloterDetail = (value) => ({
    type: type.CHANGE_KLOTER_DETAIL,
    value
})

export const changeActiveInactiveKloterLoading = (value) => ({
    type: type.CHANGE_ACTIVE_INACTIVE_KLOTER_LOADING,
    value
})

export const changeModalInactiveKloterOpen = (value) => ({
    type: type.CHANGE_MODAL_INACTIVE_KLOTER_OPEN,
    value
})

export const changeModalActiveKloterOpen = (value) => ({
    type: type.CHANGE_MODAL_ACTIVE_KLOTER_OPEN,
    value
})

export const changeModalUpdateKloterOpen = (value) => ({
    type: type.CHANGE_MODAL_UPDATE_KLOTER_OPEN,
    value
})

export const changeUpdateKloterLoading = (value) => ({
    type: type.CHANGE_UPDATE_KLOTER_LOADING,
    value
})


export const handleGetKloterDetail = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeLoading(true))
        const res = await Get(`${BASE_API_URL}/kloters/${id}`);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeKloterDetail(res.data || {}))
        return
    }
}

export const handleActiveInactiveKloter = (id) => {
    return async (dispatch, getState) => {
        dispatch(changeActiveInactiveKloterLoading(true))
        const res = await Put(`${BASE_API_URL}/kloters/${id}/active-toggle`);
        dispatch(changeActiveInactiveKloterLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        window.location.reload()
        return
    }
}

export const handleStoreKloter = (data, id) => {
    return async (dispatch, getState) => {
        const momentDate = moment(data.flying_date, "DD-MM-YYYY");
        const formattedDate = momentDate.format("YYYY-MM-DD");

        data.flying_date = formattedDate

        dispatch(changeUpdateKloterLoading(true))
        const res = await Put(`${BASE_API_URL}/kloters/${id}`, data);
        dispatch(changeUpdateKloterLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        window.location.reload()
        return
    }
}

export default type