import React, { useState } from 'react'
import { Button, Modal, Form, Input, Select, InputNumber } from 'antd';
import moment from 'moment';

import { generateRandomNumber } from "../../../../helpers/randomThings";

const Textarea = Input.TextArea

const App = (props) => {
    const [cost, setCost] = useState(null)
    const {
        modalKasKeluarOpen, accountData, saveKasKeluarLoading,
        changeModalKasKeluarOpen,
        // handleFetchDataAccounts,
        handleSaveKasKeluar,
    } = props

    const nominalIsValid = (cost) => {
        if (!cost) {
            return Promise.reject(new Error('Nominal harus diisi'))
        }
        if (cost.toString().length < 5) {
            return Promise.reject(new Error('Nominal minimal 6 digit'))
        }
        if (cost < 1) {
            return Promise.reject(new Error('Nominal harus positif'))
        }
        return Promise.resolve()
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Modal
            title="Tambah Data Kas Keluar"
            open={modalKasKeluarOpen}
            style={{ top: 20 }}
            bodyStyle={{
                height: "100%",
            }}
            footer={null}
            disabled
            destroyOnClose
            width={"700px"}
            closable={false}
        >
            <Form
                labelCol={{ span: 5 }}
                layout="horizontal"
                onFinish={(data) => handleSaveKasKeluar(data)}
            >
                <Form.Item label="No Transaksi" name={"no_bukti"}
                    initialValue={generateRandomNumber() + "" + moment(Date.now()).format("DDMMYYYY")}
                >
                    <Input
                        disabled
                    />
                </Form.Item>

                <Form.Item label="Tanggal Bayar" name={"date"} initialValue={moment(Date.now()).format("DD-MM-YYYY")}
                    rules={[{ required: true, message: 'Date harus diisi' }]}
                >
                    <Input
                        placeholder="format: d-m-Y, contoh: 30-01-2002"
                    />
                </Form.Item>

                <Form.Item label="Account" name={"account_id"}
                    rules={[{ required: true, message: "Akun harus dipilih" }]}
                >
                    <Select
                        showSearch
                        placeholder="Pilih Akun"
                        optionFilterProp="children"
                        filterOption={filterOption}
                        options={accountData}
                    />
                </Form.Item>
                <Form.Item label="Nominal" name={"nominal"}
                    rules={[{ required: true, message: 'Nominal harus diisi' }, { validator: () => nominalIsValid(cost) }]}
                >
                    <InputNumber
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        style={{ width: 200 }} addonBefore="Rp"
                        onChange={setCost}
                        decimalSeparator={false}
                    />
                </Form.Item>
                <Form.Item label="Deskripsi" name={"description"} rules={[{ min: 2, max: 100 }]}>
                    <Textarea min={2} max={100} ></Textarea>
                </Form.Item>
                <Form.Item>
                    <div style={{ float: 'right' }}>
                        <Button type="default" style={{ marginRight: 2 }}
                            loading={saveKasKeluarLoading}
                            disabled={saveKasKeluarLoading}
                            onClick={() => changeModalKasKeluarOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button type="primary" style={{ marginRight: 2 }} htmlType="submit"
                            loading={saveKasKeluarLoading}
                            disabled={saveKasKeluarLoading}
                        >
                            Proses
                        </Button>
                    </div>
                </Form.Item>
            </Form>

        </Modal>
    )
}

export default App;

