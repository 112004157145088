import React, { } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { useParams } from "react-router-dom";


const Textarea = Input.TextArea
const App = (props) => {
    const { kloter_id } = useParams()

    const {
        modalAddRoomOpen, changeModalAddRoomOpen,
        saveRoomLoading, handleSaveRoomData,
    } = props
    return (
        <>
            <Modal
                title="Add New Room"
                open={modalAddRoomOpen}
                footer={null}
                onCancel={() => {
                    changeModalAddRoomOpen(false)
                }}
                destroyOnClose
                bodyStyle={{
                    top: "-30px",
                    height: '300px',
                    overflow: 'auto',
                }}
            >
                <Form
                    layout="vertical"
                    onFinish={(data) => handleSaveRoomData(kloter_id, data)}
                >
                    <Form.Item label="Nama" name={"name"} rules={[{ required: true, max: 50 }]}>
                        <Input max={50} onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>

                    <Form.Item label="Keterangan" name={"description"} rules={[{ required: false, max: 100 }]}>
                        <Textarea max={50} onInput={e => e.target.value = e.target.value.toUpperCase()}  ></Textarea>
                    </Form.Item>


                    <div style={{ float: 'right' }}>
                        <Button type="primary" style={{ marginRight: 2 }} htmlType="submit"
                            loading={saveRoomLoading}
                        >
                            Simpan Data
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    )
};
export default App;