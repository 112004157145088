import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Skeleton, Divider, Button
} from 'antd';

import Layout from "../../../layout"
import { rupiahFormat } from "../../../helpers/currency"

import ModalDeleteConfirm from "./childComponets/ModalDeleteConfirm"
import ModalUpdate from "./childComponets/ModalUpdate"

const App = (props) => {
  let { id } = useParams();

  const {
    // actions
    handleGetPackageDetail, changeModalDeletePackageOpen, changeModalUpdatePackageOpen,
    // data 
    isLoading, packageData,
  } = props

  useEffect(() => {
    document.title = 'Branch Detail';
    handleGetPackageDetail(id)
  }, [handleGetPackageDetail, id]);

  if (isLoading) {
    return (
      <Layout>
        <Card>
          <Skeleton active />
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <ModalDeleteConfirm {...props} />
      <ModalUpdate {...props} />
      <Card>
        <Divider orientation="left" orientationMargin="0">
          <strong>Detail Package</strong>
        </Divider>
        <p><strong>Nama Package:</strong> {packageData.name}</p>
        <p><strong>Kode:</strong> {packageData.kode}</p>
        <p><strong>Estimasi Biaya:</strong> {rupiahFormat(packageData.estimated_cost)}</p>
        <br />

        <Button
          type="default"
          style={{marginRight:5}}
          onClick={() => changeModalUpdatePackageOpen(true)}
        >
          Update Package
        </Button>
        
        <Button
          type="danger"
          onClick={() => changeModalDeletePackageOpen(true)}
        >
          Hapus Package
        </Button>
      </Card>
    </Layout>
  );
};

export default App;