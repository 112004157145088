import React from 'react'
import { Button, Modal, Spin } from 'antd';
import moment from 'moment';

const App = (props) => {
    const {
        // data
        setInactiveToggleData, setInactiveSaveLoading,
        // actions
        changeInactiveToggleData, handleActiveOrInactiveUser,
    } = props
    return (
        <Modal
            title={"Apakah anda yakin " + ((setInactiveToggleData.active) ? "menonaktifkan" : "mengaktifkan") + " user ini?"}
            open={(setInactiveToggleData.hasOwnProperty('id') ? true : false)}
            disabled
            closable={false}
            destroyOnClose
            footer={[
                <Button key="cancel" type='default' onClick={() => changeInactiveToggleData({})} loading={setInactiveSaveLoading}>
                    Batalkan
                </Button>,
                <Button key="ok" type='danger' onClick={() => handleActiveOrInactiveUser(setInactiveToggleData.id)} loading={setInactiveSaveLoading}>
                    {(setInactiveToggleData.active) ? "Set Nonaktif" : "Set Aktif"}
                </Button>,
            ]}
        >
            <Spin spinning={setInactiveSaveLoading}>
                <p><strong>Nama User:</strong> {setInactiveToggleData.name}</p>
                <p><strong>Email:</strong> {setInactiveToggleData.email}</p>
                <p><strong>Created At:</strong> {moment(setInactiveToggleData.createdAt).format('DD MMMM YYYY')}</p>
                <p><strong>Updated At:</strong> {moment(setInactiveToggleData.updatedAt).format('DD MMMM YYYY')}</p>
                <p><strong>Status:</strong> {(setInactiveToggleData.active) ? "Aktif" : "Tidak Aktif"}</p>
            </Spin>

        </Modal>
    )
}

export default App;

