import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_FETCH_LOADING:
            return {
                ...state,
                isFetchLoading: action.value
            }
        case type.CHANGE_ADDITIONAL_ITEMS:
            return {
                ...state,
                additionalItems: action.value
            }
        case type.CHANGE_MODAL_ADD_OPEN:
            return {
                ...state,
                modalAddOpen: action.value
            }
        case type.CHANGE_ADDITIONAL_ITEM_UPDATE:
            return {
                ...state,
                additionalItemUpdate: action.value
            }
        case type.CHANGE_ADDITIONAL_ITEM_DELETE:
            return {
                ...state,
                additionalItemDelete: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        default:
            return state
    }
}

const defaultState = () => ({
    isFetchLoading: false,
    additionalItems: [],
    modalAddOpen:false,
    additionalItemUpdate: {},
    additionalItemDelete: {},
})

export default main