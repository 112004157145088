import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_LOADING_REGION:
            return {
                ...state,
                isLoadingKecamatan: action.value
            }
        case type.CHANGE_REGION:
            return {
                ...state,
                regions: action.value
            }
        case type.CHANGE_KECAMATAN_ID:
            return {
                ...state,
                kecamatanId: action.value
            }
        case type.CHANGE_IS_KODE_VALID:
            return {
                ...state,
                isKodeValid: action.value
            }
        case type.CHANGE_IS_EMAIL_VALID:
            return {
                ...state,
                isEmailValid: action.value
            }
        case type.CHANGE_IS_PHONE_VALID:
            return {
                ...state,
                isPhoneValid: action.value
            }
        case type.CHANGE_IS_OWNER_PHONE_VALID:
            return {
                ...state,
                isOwnerPhoneValid: action.value
            }
        case type.CHANGE_IS_OWNER_NIK_VALID:
            return {
                ...state,
                isOwnerNIKValid: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    isLoadingKecamatan:false,

    isKodeValid:true,
    isEmailValid:true,
    isPhoneValid:true,

    isOwnerPhoneValid:true,
    isOwnerNIKValid:true,

    regions:[],
    kecamatanId:"",
})

export default main