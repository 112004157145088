const App = () => {
    return (
        <div
        >
            <br />
            <table style={{
                width: "100%",
                fontFamily: 'Raleway Dots, sans-serif',
            }}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: "left", width: "50%" }}>
                            <strong>PT. ZAM ZAM TOUR & TRAVEL</strong> <br />
                            <strong>
                                08 2121 2020 10<br />
                                Jl. Brigjen Katamso No.90, Pandean, Jetis, Kec. Sukoharjo
                            </strong>
                        </td>
                        <td style={{ textAlign: "right", width: "50%" }}>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default App