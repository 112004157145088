import { Delete, Get, Post } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";
import moment from 'moment';
import { notification } from 'antd';

const key = "PAYMENT"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_PAYMENTS: `${key}_CHANGE_PAYMENTS`,
    CHANGE_FETCH_PAYMENTS_LOADING: `${key}_CHANGE_FETCH_PAYMENTS_LOADING`,
    CHANGE_SELECTED_DATE_ARR: `${key}_CHANGE_SELECTED_DATE_ARR`,
    CHANGE_MODAL_VERIFICATION_DATA: `${key}_CHANGE_MODAL_VERIFICATION_DATA`,
    // CHANGE_PAGE: `${key}_CHANGE_PAGE`,
    // CHANGE_PER_PAGE: `${key}_CHANGE_PER_PAGE`,
    CHANGE_SORTER: `${key}_CHANGE_SORTER`,
    CHANGE_FILTER_PAYMENT_METHOD_ID: `${key}_CHANGE_FILTER_PAYMENT_METHOD_ID`,
    CHANGE_PAYMENT_METHODS: `${key}_CHANGE_PAYMENT_METHODS`,
    CHANGE_MODAL_DELETE_DATA: `${key}_CHANGE_MODAL_DELETE_DATA`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changePayments = (value) => ({
    type: type.CHANGE_PAYMENTS,
    value
})

export const changeFetchPaymentsLoading = (value) => ({
    type: type.CHANGE_FETCH_PAYMENTS_LOADING,
    value
})

export const changeSelectedDateArr = (value) => ({
    type: type.CHANGE_SELECTED_DATE_ARR,
    value
})

export const changeModalVerificationData = (value) => ({
    type: type.CHANGE_MODAL_VERIFICATION_DATA,
    value
})

// export const changePage = (value) => ({
//     type: type.CHANGE_PAGE,
//     value
// })

// export const changePerPage = (value) => ({
//     type: type.CHANGE_PER_PAGE,
//     value
// })

export const changeSorter = (value) => ({
    type: type.CHANGE_SORTER,
    value
})

export const changeFilterPaymentMethodId = (value) => ({
    type: type.CHANGE_FILTER_PAYMENT_METHOD_ID,
    value
})

export const changePaymentMethodss = (value) => ({
    type: type.CHANGE_PAYMENT_METHODS,
    value
})

export const changeModalDeleteData = (value) => ({
    type: type.CHANGE_MODAL_DELETE_DATA,
    value
})



export const handleFetchPaymentsData = () => {
    return async (dispatch, getState) => {
        const state = getState()

        const {
            sorter, selectedDateArr, filterPaymentMethodId,
            // page, perPage
        } = state.paymentList

        let sorterQuery = (sorter !== null) ? `&sort_by=${sorter.field}&order=${sorter.order}` : ``
        let filterPaymentMethodIdQry = (filterPaymentMethodId) ? `&payment_method_id=${filterPaymentMethodId[0]}` : ``

        let filterDateQuery = ``
        if (selectedDateArr) {
            // Menghitung selisih hari, dianggap 31 hari
            const diffInMilliseconds = selectedDateArr[1] - selectedDateArr[0];
            const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1;

            if (diffInDays > 31) {
                alert("Range filter tanggal tidak lebih dari 31 hari")
                return
            }


            let start_date = (moment(selectedDateArr[0]).format('YYYY-MM-DD'))
            let end_date = (moment(selectedDateArr[1]).format('YYYY-MM-DD'))
            filterDateQuery = `&start_date=${start_date}&end_date=${end_date}`
        }

        let url = `${BASE_API_URL}/payments?${filterDateQuery}${sorterQuery}${filterPaymentMethodIdQry}`

        dispatch(changeFetchPaymentsLoading(true))
        const res = await Get(url);
        dispatch(changeFetchPaymentsLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        dispatch(changePayments(res || []))
        return
    }
}

export const handleVerifyPayment = (paymentId) => {
    return async (dispatch, getState) => {
        const state = getState()

        const {
            selectedDateArr
        } = state.paymentList

        let url = `${BASE_API_URL}/payments/${paymentId}/verify`

        const res = await Post(url);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        dispatch(changeModalVerificationData({}))
        dispatch(handleFetchPaymentsData(selectedDateArr))
        return
    }
}

export const handleDeleteData = (paymentId) => {
    return async (dispatch, getState) => {
        const state = getState()

        const {
            selectedDateArr
        } = state.paymentList

        let url = `${BASE_API_URL}/payments/${paymentId}/delete`

        const res = await Delete(url);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        dispatch(changeModalDeleteData({}))
        dispatch(handleFetchPaymentsData(selectedDateArr))
        return
    }
}

export const handleFetchDataPaymentMethods = () => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/kloters-jamaahs/payment-methods`

        const res = await Get(url);

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        let paymentMethods = []

        res.data.map((kloter) => {
            let kloterTemp = {
                text: kloter.name,
                value: kloter.id,
            }
            return paymentMethods.push(kloterTemp);
        })

        dispatch(changePaymentMethodss(paymentMethods))
        return
    }
}


export default type