import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Card, Table
} from 'antd';

import Layout from "../../../layout"
import moment from 'moment';

const App = (props) => {
  const {
    // data
    kloterData, isLoading,
    // actions
    handleFetchDataKloter,
  } = props

  useEffect(() => {
    document.title = 'Kloter List';
    handleFetchDataKloter()
  }, [handleFetchDataKloter])

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      align:'left',
      render: (text, record) => <Link to={`/kloters/detail/${record.id}`}>{text}</Link>,
    },
    {
      title: 'Tanggal Terbang',
      dataIndex: 'flying_date',
      render:(text, record)=>(moment(text).format('DD MMMM YYYY')),
      align:'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => <>{(record.active)?"Aktif":"Tidak Aktif"}</>,
      align:'right'
    },
  ]

  return (
    <Layout>
      <Card>
        <Table
          style={{ marginTop: 5 }}
          columns={columns}
          dataSource={kloterData.data || []}
          loading={isLoading}
          rowKey={kloters => kloters.id}
          pagination={false}
        />
      </Card>

    </Layout>
  );
};

export default App;