import { useState } from 'react';
import { notification } from 'antd';
import { BASE_API_URL } from "../../../../config";
import { Get, Post } from "../../../../helpers/ajax";

export const useSearchJamaah = (q) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const searchJamaah = async (q) => {
        setLoading(true)
        const res = await Get(`${BASE_API_URL}/dashboard/bayar-cepat-search-jamaah?key=${q}`);
        if (!res) {
            setLoading(false)
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            setLoading(false)
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        setLoading(false)
        setData(res.data)
    };

    return { data, loading, searchJamaah, setData };
};

export const useProsesbayar = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false); // Tambahkan state success

    const prosesBayar = async (kloters_jamaahs_id, payload) => {
        setLoading(true)
        const res = await Post(`${BASE_API_URL}/kloters-jamaahs/${kloters_jamaahs_id}/add-payment`, payload);
        setLoading(false)

        if (!res) {
            setSuccess(false)
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            setSuccess(false)
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }
        
        setSuccess(true); // Set success menjadi true jika pengiriman berhasil
        return
    };

    return { loading, success, prosesBayar };
};