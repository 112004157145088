import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Card, Table, Select, Button
} from 'antd';

import Layout from "../../../layout"
import ModalVerification from "./childComponets/ModalVerification"
import ModalAddItem from "./childComponets/ModalAddItem"

const App = (props) => {
  const {
    itemHead, data, isLoading, kloters, selectKloterId,
    handleFetchItemHead, handleFetchData, handleFetchDataKloter, changeSelectedKloterId,
  } = props

  useEffect(() => {
    handleFetchItemHead()
    handleFetchDataKloter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let columns = [
    {
      title: 'Nama',
      dataIndex: 'jamaah_name',
      align: 'left',
      fixed: 'left',
      render: (text, record) => <Link to={`/kloters-jamaahs/detail/${record.kloters_jamaah_id}`}>{text}</Link>,
    },
  ]

  columns = columns.concat(itemHead);

  return (
    <Layout>
      <ModalVerification {...props} />
      <ModalAddItem {...props} />
      <Card>
        <span>
          <Select
            placeholder="Pilih Kloter"
            style={{
              width: 300,
            }}
            options={kloters}
            onChange={changeSelectedKloterId}
          />
          <Button
            type='primary'
            disabled={(selectKloterId) ? false : true}
            onClick={() => {
              if (!selectKloterId) {
                return
              }
              handleFetchData(selectKloterId)
              return
            }}
          >
            Show Data
          </Button>
        </span>
        <Table
          style={{ marginTop: 5 }}
          columns={columns}
          dataSource={data}
          rowKey={items => items.id}
          pagination={false}
          loading={isLoading}
          size="small"
        />
      </Card>
    </Layout>
  );
};

export default App;