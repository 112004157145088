import { connect } from 'react-redux'
import App from './component'
import {
    handleGetJamaahDetail, handleGetJamaahHeir, changeModalAddHeir, handleSaveJamaahHeir, handleUploadFoto, handleDeleteImage,
    changeModalUpdateOpen, handleUpdateJamaahData,
} from './actions'


const mapStateToProps = (state) => ({
    ...state.jamaahDetail,
})

const mapDispatchToProps = {
    handleGetJamaahDetail, handleGetJamaahHeir, changeModalAddHeir, handleSaveJamaahHeir, handleUploadFoto, handleDeleteImage,
    changeModalUpdateOpen, handleUpdateJamaahData,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)