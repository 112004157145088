import { Get } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "PACKAGE_LIST"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_PACKAGE_DATA: `${key}_CHANGE_PACKAGE_DATA`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changePackageData = (value) => ({
    type: type.CHANGE_PACKAGE_DATA,
    value
})

export const handleFetchDataPackage = () => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/packages`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changePackageData(res || {}))
        return
    }
}

export default type