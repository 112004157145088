import React, { useEffect, useState } from 'react';
import {
  Modal, Button, Form, Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const App = (props) => {

  const {
    saveLoading, handleUploadProcess, modalOpen,
    changeModalOpen,
  } = props

  useEffect(() => {

  }, []);

  const [file, setFile] = useState(null);

  const handleFileChange = (info) => {
    setFile(info.file.originFileObj); // Simpan file yang dipilih
  };

  const handleFormSubmit = () => {
    const formData = new FormData();
    formData.append('excel_file', file);
    handleUploadProcess(formData)
  }


  return (
    <>
      <Modal
        title="Input Jurnal Umum"
        visible={modalOpen} // Ubah "open" menjadi "visible" untuk menampilkan modal
        style={{ top: 20 }}
        bodyStyle={{
          height: "200px",
        }}
        width={"800px"}
        closable={false}
        footer={false}
        destroyOnClose
      >
        <Form
          name="basic"
          onFinish={handleFormSubmit}
        >
          <Form.Item
            label="File Excel"
            name="excel_file"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            rules={[
              { required: true, message: 'Silakan unggah file Excel!' },
            ]}
          >
            <Upload
              onChange={handleFileChange}
              beforeUpload={() => false} // Mencegah unggah otomatis
              maxCount={1} // Hanya satu file yang diizinkan
            >
              <Button icon={<UploadOutlined />}>Unggah File Excel</Button>
            </Upload>
          </Form.Item>

          <Form.Item style={{ float: "right" }}>
            <Button
              key={1}
              loading={saveLoading}
              disabled={saveLoading}
              onClick={() => changeModalOpen(false)}
            >
              cancel
            </Button>
            <Button
              key={2} type='primary'
              loading={saveLoading}
              disabled={saveLoading}
              style={{ marginLeft: "5px" }}
              htmlType="submit"
            >
              Proses
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default App;
