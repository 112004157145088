import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Skeleton, Divider, Button
} from 'antd';
import moment from 'moment';

import Layout from "../../../layout"

import ModalActiveOrInactiveConfirm from "./childComponets/ModalActiveOrInactiveConfirm"
import ModalUpdate from "./childComponets/ModalUpdate"

const App = (props) => {
  let { id } = useParams();

  const {
    // actions
    handleGetItemDetail, changeModalActiveOrInactiveItemOpen, changeModalUpdateOpen,
    // data 
    isLoading, itemData,
  } = props

  useEffect(() => {
    document.title = 'Branch Detail';
    handleGetItemDetail(id)
  }, [handleGetItemDetail, id]);

  if (isLoading) {
    return (
      <Layout>
        <Card>
          <Skeleton active />
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <ModalActiveOrInactiveConfirm {...props} />
      <ModalUpdate {...props} />
      <Card>
        <Divider orientation="left" orientationMargin="0">
          <strong>Detail Item</strong>
        </Divider>
        <p><strong>Nama Item:</strong> {itemData.name}</p>
        <p><strong>Keterangan:</strong> {itemData.description}</p>
        <p><strong>Created At:</strong> {moment(itemData.created_at).format('DD MMMM YYYY')}</p>
        <p><strong>Updated At:</strong> {moment(itemData.updated_at).format('DD MMMM YYYY')}</p>
        <p><strong>Status:</strong> {(itemData.is_active)?"Aktif":"Tidak Aktif"}</p>
        <br />
        
        <Button
          type="primary"
          onClick={() => changeModalUpdateOpen(true)}
          style={{marginRight:5}}
        >
          Update Item
        </Button>
        <Button
          type="default"
          onClick={() => changeModalActiveOrInactiveItemOpen(true)}
          style={{marginRight:5}}
        >
          Set Aktif / Nonaktif
        </Button>
      </Card>
    </Layout>
  );
};

export default App;