import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card, Skeleton, Divider, Tag, Button, Modal
} from 'antd';

import Layout from "../../../layout"

const App = (props) => {
  let { id } = useParams();

  const {
    // actions
    handleGetBranchDetail, changeModalConfirmOpen,
    // data
    isLoading,
    branch, branchOwner,
  } = props

  useEffect(() => {
    document.title = 'Branch Detail';
    handleGetBranchDetail(id)
  }, [handleGetBranchDetail, id]);

  if (isLoading) {
    return (
      <Layout>
        <Card>
          <Skeleton active />
        </Card>
      </Layout>
    );
  }

  let awaitingBtn = {
    float: "right",
  }

  let color = "success"
  if (branch.status === "awaiting") {
    color = "warning"
    awaitingBtn.background = "#fffbe6"
    awaitingBtn.borderColor = "#ffe58f"
    awaitingBtn.color = "#faad14"
  }
  if (branch.status === "suspended") { color = "error" }
  if (branch.status === "inactive") { color = "default" }

  return (
    <Layout>
      <ModalConfirm {...props} />
      <Card>
        <Divider orientation="left" orientationMargin="0">
          <strong>Detail Perusahaan</strong>
        </Divider>
        <p>
          <strong>Status: </strong>
          <Tag color={color}>
            {branch.status}
          </Tag>
        </p>
        <p><strong>Kode Perusahaan:</strong> {branch.kode}</p>
        <p><strong>Nama Perusahaan:</strong> {branch.name}</p>
        <p><strong>Alamat:</strong> {branch.address}</p>
        <p><strong>No Telephone:</strong> {branch.phone}</p>
        <p><strong>Alamat Email:</strong> {branch.email}</p>
        <br />
        <Divider orientation="left" orientationMargin="0">
          <strong>Detail Pemilik</strong>
        </Divider>
        <p><strong>Nama:</strong> {branchOwner.name}</p>
        <p><strong>NIK:</strong> {branchOwner.nik}</p>
        <p><strong>No Telephone:</strong> {branchOwner.phone}</p>
        <p><strong>Alamat Email:</strong> {branchOwner.email}</p>
        <p><strong>Alamat:</strong> {branchOwner.address}</p>
        <Button
          style={awaitingBtn}
          type="primary"
          disabled={(branch.status === "awaiting") ? false : true}
          onClick={() => changeModalConfirmOpen(true)}
        >
          Konfirmasi
        </Button>
        <Button
          style={awaitingBtn}
          type="primary"
          disabled={(branch.status === "active") ? false : true}
          onClick={() => changeModalConfirmOpen(true)}
        >
          Non Aktifkan
        </Button>
      </Card>
    </Layout>
  );
};

const ModalConfirm = (props) => {
  const {
    // data
    isModalConfirmOpen, isModalConfirmLoading, branch, branchOwner,
    // actions
    handleChangeBranchStatus, changeModalConfirmOpen,
  } = props
  return (
    <Modal
      title="Apakah anda yakin mengkonfirmasi cabang baru ini?"
      open={isModalConfirmOpen}
      onOk={() => handleChangeBranchStatus(branch.id, "active")}
      confirmLoading={isModalConfirmLoading}
      disabled
      closable={false}
      cancelButtonProps={{
        disabled: isModalConfirmLoading,
        onClick: () => changeModalConfirmOpen(false)
      }}
    >
      <p><strong>Kode Perusahaan:</strong> {branch.kode}</p>
      <p><strong>Nama Perusahaan:</strong> {branch.name}</p>
      <p><strong>Nama Pemilik:</strong> {branchOwner.name}</p>
    </Modal>
  )
}

export default App;