import { Delete, Get, Post, Put } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "JOURNAL_LIST"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_JOURNAL_DATA_LOADING: `${key}_CHANGE_JOURNAL_DATA_LOADING`,
    CHANGE_JOURNAL_DATA: `${key}_CHANGE_JOURNAL_DATA`,
    CHANGE_ACCOUNT_DATA: `${key}_CHANGE_ACCOUNT_DATA`,
    CHANGE_MODAL_ADD_JOURNAL_OPEN: `${key}_CHANGE_MODAL_ADD_JOURNAL_OPEN`,
    CHANGE_ADD_JOURNAL_LOADING: `${key}_CHANGE_ADD_JOURNAL_LOADING`,

    CHANGE_PAGE: `${key}_CHANGE_PAGE`,
    CHANGE_SORTER: `${key}_CHANGE_SORTER`,

    CHANGE_FILTER_TYPE: `${key}_CHANGE_FILTER_TYPE`,
    CHANGE_FILTER_NO_BUKTI: `${key}_CHANGE_FILTER_NO_BUKTI`,
    CHANGE_FILTER_DESCRIPTION: `${key}_CHANGE_FILTER_DESCRIPTION`,
    CHANGE_FILTER_NO_REF: `${key}_CHANGE_FILTER_NO_REF`,
    CHANGE_FILTER_ACCOUNT: `${key}_CHANGE_FILTER_ACCOUNT`,
    CHANGE_FILTER_ACCOUNT_GROUP: `${key}_CHANGE_FILTER_ACCOUNT_GROUP`,

    CHANGE_JOURNAL_UPDATE_DATA: `${key}_CHANGE_JOURNAL_UPDATE_DATA`,
    CHANGE_JOURNAL_DELETE_DATA: `${key}_CHANGE_JOURNAL_DELETE_DATA`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeJournalDataLoading = (value) => ({
    type: type.CHANGE_JOURNAL_DATA_LOADING,
    value
})
export const changeJournalData = (value) => ({
    type: type.CHANGE_JOURNAL_DATA,
    value
})
export const changeAccountData = (value) => ({
    type: type.CHANGE_ACCOUNT_DATA,
    value
})
export const changeModalAddJournalOpen = (value) => ({
    type: type.CHANGE_MODAL_ADD_JOURNAL_OPEN,
    value
})
export const changeAddJournalLoading = (value) => ({
    type: type.CHANGE_ADD_JOURNAL_LOADING,
    value
})
export const changeFilterType = (value) => ({
    type: type.CHANGE_FILTER_TYPE,
    value
})
export const changeFilterNoBukti = (value) => ({
    type: type.CHANGE_FILTER_NO_BUKTI,
    value
})
export const changeFilterDescription = (value) => ({
    type: type.CHANGE_FILTER_DESCRIPTION,
    value
})
export const changeFilterNoRef = (value) => ({
    type: type.CHANGE_FILTER_NO_REF,
    value
})
export const changeFilterAccount = (value) => ({
    type: type.CHANGE_FILTER_ACCOUNT,
    value
})
export const changeFilterAccountGroup = (value) => ({
    type: type.CHANGE_FILTER_ACCOUNT_GROUP,
    value
})
export const changePage = (value) => ({
    type: type.CHANGE_PAGE,
    value
})
export const changeSorter = (value) => ({
    type: type.CHANGE_SORTER,
    value
})
export const changeJournalUpdateData = (value) => ({
    type: type.CHANGE_JOURNAL_UPDATE_DATA,
    value
})
export const changeJournalDeleteData = (value) => ({
    type: type.CHANGE_JOURNAL_DELETE_DATA,
    value
})

export const handleFetchJournalData = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const {
            page, perPage, filterType, filterNoBukti, filterNoRef, filterDescription,
            filterAccount, filterAccountGroup,
        } = state.journalList

        let filterTypeQuery = (filterType) ? `&type=${filterType}` : ``
        let filterNoRefQuery = (filterNoRef !== null) ? `&no_ref=${filterNoRef}` : ``
        let filterNoBuktiQuery = (filterNoBukti !== null) ? `&no_bukti=${filterNoBukti}` : ``
        let filterDescriptionQuery = (filterDescription !== null) ? `&description=${filterDescription}` : ``
        let filterAccountQuery = (filterAccount !== null) ? `&account=${filterAccount}` : ``
        let filterAccountGroupQuery = (filterAccountGroup !== null) ? `&account_group=${filterAccountGroup}` : ``

        let url = `${BASE_API_URL}/non-cash?page=${page}&per_page=${perPage}
        ${filterTypeQuery}${filterNoBuktiQuery}${filterDescriptionQuery}${filterNoRefQuery}${filterAccountQuery}${filterAccountGroupQuery}`

        dispatch(changeJournalDataLoading(true))
        const res = await Get(url);
        dispatch(changeJournalDataLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        dispatch(changeJournalData(res || {}))
        return
    }
}

export const handleFetchDataAccounts = () => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/accounts?per_page=200`

        dispatch(changeJournalDataLoading(true))
        const res = await Get(url);
        dispatch(changeJournalDataLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        let accounts = []
        if (res.data) {
            res.data.forEach((val) => {
                let temp = {
                    label: val.group + " / " + val.name,
                    value: val.id,
                };
                accounts.push(temp)
            });
        }

        dispatch(changeAccountData(accounts))
        return
    }
}

export const handleAddJournalSave = (data) => {
    return async (dispatch, getState) => {
        await dispatch(changeAddJournalLoading(true))
        const res = await Post(`${BASE_API_URL}/non-cash/add`, data);
        await dispatch(changeAddJournalLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        await dispatch(changeModalAddJournalOpen(false))
        await dispatch(handleFetchJournalData())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })
    }
}

export const handleUpdateJournalSave = (id, data) => {
    return async (dispatch, getState) => {
        await dispatch(changeAddJournalLoading(true))
        const res = await Put(`${BASE_API_URL}/non-cash/update/${id}`, data);
        await dispatch(changeAddJournalLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        await dispatch(changeJournalUpdateData({}))
        await dispatch(handleFetchJournalData())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })
    }
}

export const handleDeleteJournalSave = (id) => {
    return async (dispatch, getState) => {
        await dispatch(changeAddJournalLoading(true))
        const res = await Delete(`${BASE_API_URL}/non-cash/delete/${id}`);
        await dispatch(changeAddJournalLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        await dispatch(changeJournalDeleteData({}))
        await dispatch(handleFetchJournalData())
        return notification.success({
            message: 'Sukses',
            description: res.message,
        })
    }
}

export default type