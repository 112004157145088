import React, { } from 'react'
import { Button, Modal, Form, Input, InputNumber } from 'antd';
import moment from 'moment';

const Textarea = Input.TextArea

const App = (props) => {
    const {
        // data
        kloterData, isModalUpdateKloterOpen, isUpdateKloterLoading,
        // actions
        changeModalUpdateKloterOpen, handleStoreKloter,
    } = props

    return (
        <Modal
            title="Update Data Kloter?"
            open={isModalUpdateKloterOpen}
            footer={null}
            onCancel={() => changeModalUpdateKloterOpen(false)}
            style={{ width: "600" }}
            destroyOnClose
        >
            <Form
                layout="vertical"
                onFinish={(data) => handleStoreKloter(data, kloterData.id)}
            >
                <Form.Item label="Nama Kloter" name={"name"} rules={[{ required: true, min: 2, max: 50 }]}
                    initialValue={kloterData.name}
                >
                    <Input
                        min={2} max={50}
                    />
                </Form.Item>

                <Form.Item label="Tanggal Terbang" name={"flying_date"} rules={[{ required: true }]} initialValue={moment(kloterData.flying_date).format("DD-MM-YYYY")}>
                    <Input
                        style={{ width: '50%' }}
                        placeholder="format: d-m-Y, contoh: 30-01-2002"
                    />
                </Form.Item>

                <Form.Item label="Estimasi Biaya" name={"basic_cost"} rules={[{ required: true, message: "'Estimasi Biaya', required" }]}
                    initialValue={kloterData.basic_cost}
                >
                    <InputNumber
                        style={{ width: "30%" }} min={10000000}
                    />
                </Form.Item>

                <Form.Item label="Quota" name={"quota"} rules={[{ required: true }]}
                    initialValue={kloterData.quota}
                >
                    <InputNumber
                        min={1} max={1000}
                    />
                </Form.Item>

                <Form.Item label="Deskripsi" name={"description"} rules={[{ required: true, min: 2, max: 100 }]}
                    initialValue={kloterData.name}
                >
                    <Textarea
                        min={2} max={100}
                    />
                </Form.Item>

                <Form.Item>
                    <div style={{ float: 'right' }}>
                        <Button loading={isUpdateKloterLoading} type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Perubahan</Button>
                    </div>
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default App;

