import { useState } from 'react';
import { notification } from 'antd';
import { BASE_API_URL } from "../../../config";
import { Get } from "../../../helpers/ajax";

export const useManifestData = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchManifestData = async (kloterId) => {
        setLoading(true)
        const res = await Get(`${BASE_API_URL}/manifest/per-kloter/${kloterId}`);
        if (!res) {
            setLoading(false)
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            setLoading(false)
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        setLoading(false)
        setData(res.data)
    };

    return { data, loading, fetchManifestData };
};

export const useFetchKloters = () => {
    const [kloters, setKloters] = useState(null);
    const [fetchKlotersLoading, setFetchKlotersLoading] = useState(false);

    const fetchKloters = async () => {
        setFetchKlotersLoading(true)
        const res = await Get(`${BASE_API_URL}/kloters`);
        if (!res) {
            setFetchKlotersLoading(false)
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            setFetchKlotersLoading(false)
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        const kloters_val = [];

        if (res.data) {
            res.data.forEach(data => {
                const temp_val = {
                    label: data.name,
                    value: data.id,
                }
                kloters_val.push(temp_val);
            });
        }

        setFetchKlotersLoading(false)
        setKloters(kloters_val)
        return
    };

    return { kloters, fetchKlotersLoading, fetchKloters };
};