import { connect } from 'react-redux'
import App from './component'
import {
    handleFetchJournalData, handleFetchDataAccounts, reset,
    changeModalAddJournalOpen, handleAddJournalSave,
    changePage, changeSorter,
    changeFilterType, changeFilterNoBukti, changeFilterDescription, changeFilterNoRef,
    changeFilterAccount, changeFilterAccountGroup,
    changeJournalUpdateData, handleUpdateJournalSave, handleDeleteJournalSave,
    changeJournalDeleteData,
} from './actions'

import {
    changeModalOpen as handleChangeModalBRIOpen,
} from './childComponets/ModalUploadBRI/actions'


const mapStateToProps = (state) => ({
    ...state.journalList,
})

const mapDispatchToProps = {
    handleFetchJournalData, handleFetchDataAccounts, reset,
    changeModalAddJournalOpen, handleAddJournalSave,
    changePage, changeSorter,
    changeFilterType, changeFilterNoBukti, changeFilterDescription, changeFilterNoRef,
    changeFilterAccount, changeFilterAccountGroup,
    changeJournalUpdateData, handleUpdateJournalSave, handleDeleteJournalSave,
    changeJournalDeleteData,

    handleChangeModalBRIOpen
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App)