import React, { useEffect } from 'react';
import {
  Form, Input, Button, Card, Spin, InputNumber,
} from 'antd';

import Layout from "../../../layout"

const Textarea = Input.TextArea

const App = (props) => {

  const { handleSaveItem, isLoading } = props

  useEffect(() => {
    document.title = 'Item New';
  }, []);


  return (
    <Layout>
      <Card>
        <Spin tip="Processing data..." spinning={isLoading}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={(data) => handleSaveItem(data)}
          >
            <Form.Item label="Nama Item" name={"name"} rules={[{ required: true, min: 2, max: 50 }]}>
              <Input min={2} max={50} />
            </Form.Item>

            <Form.Item label="Keterangan" name={"description"} rules={[{ required: false, min: 2, max: 100 }]}>
              <Textarea min={2} max={50} />
            </Form.Item>

            <Form.Item initialValue={0} label="Supporting files" name={"supporting_files_number"}>
              <InputNumber max={9} />
            </Form.Item>

            <div style={{ float: 'right' }}>
              <Button type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Data Item</Button>
            </div>
          </Form>
        </Spin>
      </Card>

    </Layout >
  );
};

export default App;