export const checkNameIsValid = (name) => {
    if (name) {
        if (name.length < 2 || name.length > 100) {
            return Promise.reject(new Error('Nama harus 2 s/d 100 karakter'))
        }
        return Promise.resolve()
    }
    return Promise.reject(new Error('Nama required. Minimal 2 dan maksimal 100 Karakter'))
}

export const checGenderIsValid = (gender) => {
    if (gender) {
        return Promise.resolve()
    }
    return Promise.reject(new Error('Jenis kelamin required'))
}

export const checkPOBIsValid = (pob) => {
    if (pob) {
        if (pob.length < 2 || pob.length > 100) {
            return Promise.reject(new Error('Tempat lahir harus 2 s/d 100 karakter'))
        }
        return Promise.resolve()
    }
    return Promise.reject(new Error('Tempat lahir required. Minimal 2 dan maksimal 100 Karakter'))
}

export const checkDateFormIsValid = (dateStringParam) => {
    if (!dateStringParam) {
        return Promise.resolve()
    }
    let dateString = dateStringParam.replace(/\s/g, "");
    // Pengecekan panjang string
    if (dateString.length !== 10) {
        return Promise.reject(new Error('Tanggal lahir required. Format: d-m-Y, contoh: 30-01-2002'))
    }

    // Pengecekan format menggunakan regular expression
    var regex = /^\d{2}-\d{2}-\d{4}$/;
    if (!regex.test(dateString)) {
        return Promise.reject(new Error('Tanggal lahir required. Format: d-m-Y, contoh: 30-01-2002'))
    }

    // Pengecekan tanggal, bulan, dan tahun yang valid
    var parts = dateString.split("-");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    if (year < 1000 || year > 9999 || month === 0 || month > 12) {
        return Promise.reject(new Error('Tanggal lahir required. Format: d-m-Y, contoh: 30-01-2002'))
    }

    // Pengecekan jumlah hari pada bulan tertentu
    var monthLength = [
        31, // Januari
        28, // Februari
        31, // Maret
        30, // April
        31, // Mei
        30, // Juni
        31, // Juli
        31, // Agustus
        30, // September
        31, // Oktober
        30, // November
        31, // Desember
    ];

    // Penanganan khusus untuk tahun kabisat (Februari memiliki 29 hari)
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29;
    }

    if (day <= 0 || day > monthLength[month - 1]) {
        return Promise.reject(new Error('Tanggal lahir required. Format: d-m-Y, contoh: 30-01-2002'))
    }

    return Promise.resolve()
}

export const checkNIKIsValid = (nik) => {
    if (nik) {
        if (nik.length === 16) {
            return Promise.resolve()
        }
    }
    return Promise.reject(new Error('NIK required. Panjang harus 16 karakter'))
}

export const checkOccupationIsValid = (occupation) => {
    if (occupation) {
        if (occupation.length < 2 || occupation.length > 100) {
            return Promise.reject(new Error('Pekerjaan harus 2 s/d 100 karakter'))
        }
        return Promise.resolve()
    }
    return Promise.reject(new Error('Pekerjaan required. Minimal 2 dan maksimal 100 Karakter'))
}

export const checkAddressIsValid = (address) => {
    if (address) {
        if (address.length < 2 || address.length > 100) {
            return Promise.reject(new Error('Alamat harus 2 s/d 100 karakter'))
        }
        return Promise.resolve()
    }
    return Promise.reject(new Error('Alamat required. Minimal 2 dan maksimal 100 Karakter'))
}

export const checkPhoneIsValid = (phone) => {
    if (phone) {
        if (phone.length < 2 || phone.length > 100) {
            return Promise.reject(new Error('Nomer Hp harus 6 s/d 13 karakter'))
        }
        return Promise.resolve()
    }
    return Promise.reject(new Error('Nomer Hp required. Minimal 6 dan maksimal 13 Karakter'))
}

export const checkEmailIsValid = (email) => {
    if (!email) {
        return Promise.resolve()
    }
    if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return Promise.resolve()
    }
    return Promise.reject(new Error('Format alamat email salah.'))
}