import { useState } from 'react';
import { notification } from 'antd';
import { BASE_API_URL } from "../../../../../config";
import { Get, Post } from "../../../../../helpers/ajax";

export const useAvailableKloters = () => {
    const [kloters, setKloters] = useState(null);
    const [loadingFetchAvailKloter, setLoadingFetchAvailKloter] = useState(false);

    const fetchAvailableKloters = async (jamaahId, planDate = null) => {

        const query = (planDate) ? `&period=${planDate}` : ''

        setLoadingFetchAvailKloter(true)
        const res = await Get(`${BASE_API_URL}/jamaah/${jamaahId}/detail/avail-kloters?${query}`);
        setLoadingFetchAvailKloter(false)

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        setKloters(res.data)
        return
    };

    return { kloters, loadingFetchAvailKloter, fetchAvailableKloters };
};

export const useProcessTransferToKloter = () => {
    const [loadingTransferProcess, setLoadingTransferProcess] = useState(false);

    const handleProcessTransferToKloter = async (klotersJamaahsId, selectedKloterId) => {

        const payload = {
            selected_kloter_id: selectedKloterId
        }

        setLoadingTransferProcess(true)
        const res = await Post(`${BASE_API_URL}/kloters-jamaahs/${klotersJamaahsId}/transfer-to-kloter`, payload);
        setLoadingTransferProcess(false)

        if (!res) {
            setLoadingTransferProcess(false)
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res?.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: JSON.stringify(res.message),
            })
        }

        setLoadingTransferProcess(false)
        window.location.reload()
        return
    };

    return { loadingTransferProcess, handleProcessTransferToKloter };
};