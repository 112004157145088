import { Get, Post } from "../../../../../helpers/ajax";
import { BASE_API_URL } from "../../../../../config";

import { notification } from 'antd';

import { handleFetchDataJamaah as reloadJammahListInkloter } from "../KloterDetailJamaahList/actions";

const key = "KLOTER_DETAIL_JAMAAH_ADD_TO_KLOTER"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING_FETCH_DATA: `${key}_CHANGE_LOADING_FETCH_DATA`,
    CHANGE_JAMAAHS_DATA: `${key}_CHANGE_JAMAAHS_DATA`,
    CHANGE_PER_PAGE: `${key}_CHANGE_PER_PAGE`,
    CHANGE_SORTER: `${key}_CHANGE_SORTER`,
    CHANGE_MODAL_JAMAAH_ADD_TO_KLOTER_OPEN: `${key}_CHANGE_MODAL_JAMAAH_ADD_TO_KLOTER_OPEN`,
    CHANGE_SELECTED_NIK: `${key}_CHANGE_SELECTED_NIK`,
    CHANGE_SELECTED_JAMAAHS: `${key}_CHANGE_SELECTED_JAMAAHS`,
    CHANGE_LOADING_SAVE_JAMAAH_TO_KLOTER: `${key}_CHANGE_LOADING_SAVE_JAMAAH_TO_KLOTER`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoadingFetchData = (value) => ({
    type: type.CHANGE_LOADING_FETCH_DATA,
    value
})

export const changeJamaahsData = (value) => ({
    type: type.CHANGE_JAMAAHS_DATA,
    value
})

export const changePerPage = (value) => ({
    type: type.CHANGE_PER_PAGE,
    value
})

export const changeSorter = (value) => ({
    type: type.CHANGE_SORTER,
    value
})

export const changeModalJamaahAddToKloterOpen = (value) => ({
    type: type.CHANGE_MODAL_JAMAAH_ADD_TO_KLOTER_OPEN,
    value
})

export const changeSelectedNik = (value) => ({
    type: type.CHANGE_SELECTED_NIK,
    value
})

export const changeSelectedJamaah = (value) => ({
    type: type.CHANGE_SELECTED_JAMAAHS,
    value
})

export const changeLoadingSaveJamaahToKloter = (value) => ({
    type: type.CHANGE_LOADING_SAVE_JAMAAH_TO_KLOTER,
    value
})

export const closeModal = () => {
    return async (dispatch, getState) => {
        dispatch(reset())
    }
}

export const openModal = () => {
    return async (dispatch, getState) => {
        dispatch(changeModalJamaahAddToKloterOpen(true))
        dispatch(handleFetchDataJamaah())
    }
}

export const handleFetchDataJamaah = () => {
    return async (dispatch, getState) => {

        const state = getState()

        const { id: kloterId } = state.kloterDetail.kloterData
        const { selectedNik } = state.kloterDetailModalJamaahAddToKloter

        const searchByNikQuery = (selectedNik) ? `&nik=${selectedNik}` : ''

        let url = `${BASE_API_URL}/kloters-jamaahs/${kloterId}/list-available-jamaah?${searchByNikQuery}`

        dispatch(changeLoadingFetchData(true))
        const res = await Get(url);
        dispatch(changeLoadingFetchData(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.error) {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeJamaahsData(res || {}))
        return
    }
}

export const handleSaveJamaahsToKloter = (data) => {
    return async (dispatch, getState) => {
        const state = getState()
        const { id: kloterId } = state.kloterDetail.kloterData

        let selecteJamaahsId = data.map(a => a.id);

        const payload = {
            jamaahs_id:selecteJamaahsId
        }

        dispatch(changeLoadingSaveJamaahToKloter(true))
        const res = await Post(`${BASE_API_URL}/kloters-jamaahs/${kloterId}`, payload);
        dispatch(changeLoadingSaveJamaahToKloter(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(reloadJammahListInkloter())
        dispatch(closeModal())
        return

    }
}

export default type