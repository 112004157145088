import React, { useEffect, useRef, useState } from 'react';
import { DownOutlined, MinusOutlined, PlusOutlined, FileExcelOutlined, FilePdfOutlined, } from '@ant-design/icons';
import {
  Card, Table, Dropdown, Button, Input, Space, Row, Col,
  DatePicker, notification, Menu,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import Highlighter from 'react-highlight-words';

import { BASE_API_URL } from '../../../config';

import Layout from "../../../layout"
import { numeratorFormatID } from "../../../helpers/currency";
import { truncateText } from "../../../helpers/randomThings";

import ModalKasMasuk from "./childComponets/ModalKasMasuk"
import ModalKasKeluar from "./childComponets/ModalKasKeluar"
import ModalDeleteKas from "./childComponets/ModalDeleteKas"
import { Link } from 'react-router-dom';

const App = (props) => {
  const searchInput = useRef(null);
  const [tableKey, setTableKey] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [startDate, setStartDate] = useState(null); // 2. Tambahkan startDate
  const [endDate, setEndDate] = useState(null); // 2. Tambahkan endDate

  const {
    //data
    kasData, isLoading,
    page, perPage, filterDescription, filterNoBukti, type, filterDate,
    //actions
    reset, handleFetchDataAccounts,
    changeModalKasMasukOpen, changeModalKasKeluarOpen, handleFetchDataKas,
    changePage, changeSorter, changeType, changeFilterDescription, changeFilterNoBukti, changeFilterDate,
    changeKasDeleteData,
  } = props

  const items = [
    {
      label: 'Tambah Kas Masuk',
      key: 'kas_masuk',
      icon: <PlusOutlined />,
      danger: false,
    },
    {
      label: 'Tambah Kas Keluar',
      key: 'kas_keluar',
      icon: <MinusOutlined />,
      danger: true,
    },
  ];

  const itemsDownload = [
    {
      label: 'Download Excel',
      key: 'excel',
      icon: <FileExcelOutlined />,
      danger: false,
    },
    {
      label: 'Download PDF',
      key: 'pdf',
      icon: <FilePdfOutlined />,
      danger: true,
    },
  ];

  const handleMenuClickKas = (e) => {
    if (e.key === "kas_masuk") {
      changeModalKasMasukOpen(true)
      return
    }
    if (e.key === "kas_keluar") {
      changeModalKasKeluarOpen(true)
      return
    }
    return
  };

  const handleMenuClickDownload = (e) => {
    handleExport(e.key)
    return
  };

  const menuPropsKas = {
    items,
    onClick: handleMenuClickKas,
  };

  useEffect(() => {
    document.title = 'Kas';

    handleFetchDataKas()
    handleFetchDataAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleDateFilter = () => {
    // Filter data berdasarkan startDate dan endDate
    let start_date = (moment(startDate).format('YYYY-MM-DD'))
    let end_date = (moment(endDate).format('YYYY-MM-DD'))

    const diffInMilliseconds = endDate - startDate;
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1;

    if (diffInDays > 31) {
      return notification.warning({
        message: 'Error',
        description: 'Range filter tanggal tidak boleh lebih dari 31 hari',
      })
    }

    changeFilterDate({
      start_date,
      end_date
    });
    handleFetchDataKas()
    return
  };

  const handleResetDateFilter = () => {
    // Reset filter tanggal
    setStartDate(null);
    setEndDate(null);
    changeFilterDate(null)
    handleFetchDataKas(); // Ambil data tanpa filter tanggal
    return
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setTableKey(tableKey => tableKey + 1);
    setSearchText('');
    setSearchedColumn('');
  };

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const dataIndexValue = record[dataIndex];
      if (dataIndexValue && dataIndexValue.toString) {
        return dataIndexValue.toString().toLowerCase().includes(value.toLowerCase());
      }
      return false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const columns = [
    {
      title: 'Tanggal',
      dataIndex: 'date',
      align: "left",
      render: (text, record) => <>{moment(text).format('DD MMMM YYYY')}</>,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <DatePicker.RangePicker // 3. Tambahkan RangePicker di sini
            value={[startDate, endDate]}
            onChange={(dates) => {
              if(!dates){
                return
              }
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={handleDateFilter}
              size="small"
              style={{
                width: 90,
              }}
            >
              Filter
            </Button>
            <Button
              onClick={handleResetDateFilter}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: 'No Bukti',
      dataIndex: 'no_bukti',
      render: (text, record) => <>{text}</>,
      ...getColumnSearchProps('no_bukti'),
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      width: "300px",
      ...getColumnSearchProps('description'),
      render: (text, record) => <>{truncateText(text, 80)}</>,
    },
    {
      title: 'Nominal (Rp)',
      dataIndex: 'nominal',
      align: "right",
      render: (text, record) => {
        const numericValue = numeratorFormatID(text);
        const color = record.type === 'd' ? '#52c41a' : '#f5222d';

        return (
          <span style={{ color }}>{numericValue}</span>
        );
      },
    },
    {
      title: 'Keterangan',
      dataIndex: 'type',
      align: "center",
      filterSearch: true,
      filterMultiple: false,
      filters: [
        {
          text: 'Debit',
          value: 'd',
        },
        {
          text: 'Kredit',
          value: 'c',
        },
      ],
      render: (text, record) => {
        const color = record.type === 'd' ? '#52c41a' : '#f5222d';

        if (text === "d") {
          return <span style={{ color }}>Debit</span>
        }
        if (text === "c") {
          return <span style={{ color }}>Kredit</span>
        }
      },
    },
    {
      title: 'Saldo (Rp)',
      dataIndex: 'saldo',
      align: "right",
      render: (text, record) => {
        const numericValue = numeratorFormatID(text);
        let color = '#52c41a';
        if (text < 0) {
          color = '#f5222d';
        }

        return (
          <span style={{ color }}>{numericValue}</span>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: "center",
      render: (text, record) => {
        return (
          <>
            {/* <Link onClick={() => changeJournalUpdateData(record)}>update</Link>&nbsp;|&nbsp; */}
            <Link onClick={() => changeKasDeleteData(record)}>hapus</Link>
          </>
        )
      },
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    changeFilterDescription((filters?.description) ? filters?.description : null)
    changeFilterNoBukti((filters?.no_bukti) ? filters?.no_bukti : null)

    const { type: typeFilter } = filters
    // set filter type
    if (typeFilter) {
      changeType(typeFilter[0])
    } else {
      changeType(null)
    }

    changePage(pagination.current)

    // get sort colum and order
    if (sorter.hasOwnProperty("column")) {
      if (typeof sorter.order === "undefined") {
        changeSorter(null)
      } else {
        changeSorter({
          field: sorter.field,
          order: (sorter.order === "ascend") ? "asc" : "desc",
        })
      }
    }

    handleFetchDataKas()
    return
  }

  const resetFilters = () => {
    const clearFilters = () => { };
    handleReset(clearFilters);
    setStartDate(null)
    setEndDate(null)
    reset() // reset redux
    handleFetchDataKas()
  };

  const handleExport = (doc_type) => {
    if (!doc_type) {
      return
    }
    let filterTypeQ = (type) ? `&type=${type}` : ``
    let filterDescriptionQ = (filterDescription !== null) ? `&description=${filterDescription}` : ``
    let filterNoBuktiQ = (filterNoBukti !== null) ? `&no_bukti=${filterNoBukti}` : ``
    let filterDateQ = (filterDate !== null) ? `&start_date=${filterDate?.start_date}&end_date=${filterDate?.end_date}` : ``
    let url

    if (doc_type === "excel") {
      url = `${BASE_API_URL}/cash/excel?${filterTypeQ}${filterDescriptionQ}${filterNoBuktiQ}${filterDateQ}`
    }
    if (doc_type === "pdf") {
      url = `${BASE_API_URL}/cash/pdf?${filterTypeQ}${filterDescriptionQ}${filterNoBuktiQ}${filterDateQ}`
    }

    const newTab = window.open(url, '_blank');
    newTab.focus();
    return
  }

  return (
    <Layout>
      <ModalKasMasuk  {...props} />
      <ModalKasKeluar  {...props} />
      <ModalDeleteKas  {...props} />

      <>
        <Dropdown
          disabled={isLoading}
          menu={menuPropsKas}
          placement="bottom"
          arrow={{
            pointAtCenter: true,
          }}
        >
          <Button disabled={isLoading} type='primary'>Tambah Data Kas</Button>
        </Dropdown>
      </>
      <Card>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <small>
              {`Ditemukan: ${kasData.total_data} data`}
              <Button
                onClick={resetFilters}
                size="small"
                style={{ marginLeft: "5px" }}
                disabled={isLoading}
              >
                Reset Filter
              </Button> <br />
              {
                filterDate !== null &&
                <><strong>Rentang Waktu</strong>: {moment(startDate).format('DD-MM-YY')} - {moment(endDate).format('DD-MM-YY')}&nbsp;</>
              }
              {
                filterDescription !== null &&
                <><strong>Deskripsi</strong>:{filterDescription}&nbsp;</>
              }
              {
                filterNoBukti !== null &&
                <><strong>No Bukti</strong>:{filterNoBukti}&nbsp;</>
              }
            </small>
          </Col>
          <Col xs={24} sm={24} md={8} lg={{ span: 4, offset: 12 }} style={{ textAlign: 'right' }}>
            <Dropdown
              disabled={isLoading}
              overlay={
                <Menu onClick={handleMenuClickDownload}>
                  {itemsDownload.map(item => (
                    <Menu.Item key={item.key} icon={item.icon}>
                      {item.label}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Link onClick={(e) => e.preventDefault()}>
                <Space>
                  Download <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
          </Col>
        </Row>
        <Table
          key={tableKey}
          onChange={(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)}
          style={{ marginTop: 5 }}
          columns={columns}
          dataSource={kasData.data}
          loading={isLoading}
          rowKey={kas => kas.id}
          pagination={{
            simple: true,
            // defaultCurrent: 1,
            current: page,
            total: kasData.total_data,
            pageSize: perPage,
          }}
          size="small"
        />
      </Card>
    </Layout>
  );
};

export default App;
