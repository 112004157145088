import {
    PieChartOutlined, CalendarOutlined, CarryOutOutlined,
    DollarOutlined, PartitionOutlined, SwapOutlined, SettingOutlined,
} from '@ant-design/icons';


export const items = [
    {
        label: "Jamaah",
        key: "jamaah",
        icon: <PieChartOutlined />,
        auth: "*",
        children: [
            {
                label: "New Jamaah",
                key: "jamaah/new",
            },
            {
                label: "List Jamaah",
                key: "jamaah/list",
            },
        ],
    },
    {
        label: "Branch",
        key: "branch",
        icon: <PartitionOutlined />,
        auth: "*",
        children: [
            {
                label: "New Branch",
                key: "branch/new",
            },
            {
                label: "List Branch",
                key: "branch/list",
            },
        ],
    },
    {
        label: "Kloter",
        key: "kloters",
        icon: <CalendarOutlined />,
        auth: "*",
        children: [
            {
                label: "New Kloter",
                key: "kloters/new",
                auth: "*",
            },
            {
                label: "List Kloter",
                key: "kloters/list",
                auth: "*",

            },
        ],
    },
    {
        label: "Item Kelengkapan",
        key: "items",
        icon: <CarryOutOutlined />,
        auth: "*",
        children: [
            {
                label: "New Item",
                key: "items/new",
                auth: "*",
            },
            {
                label: "List Item",
                key: "items/list",
                auth: "*",

            },
            {
                label: "Kelengkapan Item",
                key: "items/kloter-jamaahs-items",
                auth: "*",

            },
        ],
    },
    {
        label: "Pembayaran",
        key: "payments",
        icon: <DollarOutlined />,
        auth: "*",
        children: [
            {
                label: "Rekap Pembayaran",
                key: "payments/list",
                auth: "*",

            },
        ],
    },
    {
        label: "Accounting",
        key: "kas",
        icon: <SwapOutlined />,
        auth: "*",
        children: [
            {
                label: "Kas",
                key: "kas/list",
                auth: "*",

            },
            {
                label: "Journal",
                key: "journal/list",
                auth: "*",

            },
            {
                label: "Akun",
                key: "account/list",
                auth: "*",

            },
        ],
    },
    {
        label: "Manifest",
        key: "manifest",
        icon: <SettingOutlined />,
        auth: "*",
        children: [
            {
                label: "Kloter Manifest",
                key: "manifest/kloter-manifest",
            },
            {
                label: "Room Setting",
                key: "manifest/kloter-room",
            },

        ],
    },
    {
        label: "Setting",
        key: "setting",
        icon: <SettingOutlined />,
        auth: "manager",
        children: [
            {
                label: "User",
                key: "setting/users",
            },

        ],
    },

];