import React from 'react';
import { Button, Modal, Form, Input, Spin, Select } from 'antd';

const App = (props) => {
    const {
        // data
        modalNewOpen, saveLoading, roles,
        // actions
        handleSaveUser, changeModalNewOpen
    } = props;

    return (
        <Modal
            title="Tambah Data User"
            open={modalNewOpen}
            footer={null}
            style={{ top: 20 }}
            width={700}
            closable={false}
            destroyOnClose
        >
            <Spin spinning={saveLoading}>
                <Form
                    labelCol={{ span: 6 }}
                    layout="horizontal"
                    onFinish={(data) => handleSaveUser(data)}
                >
                    <Form.Item
                        label="Nama User"
                        name="name"
                        rules={[
                            { required: true, message: 'Nama User harus diisi!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email User"
                        name="email"
                        rules={[
                            { required: true, message: 'Email User harus diisi!' },
                            { type: 'email', message: 'Format email tidak valid!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="User Role"
                        name="role_id"
                        rules={[
                            // { required: true, message: 'Role User harus diisi!' },
                        ]}
                    >
                        <Select
                            placeholder="Pilih Role"
                            style={{
                                width: 300,
                            }}
                            options={roles}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            { required: true, message: 'Password harus diisi!' },
                            { min: 8, message: 'Password minimal harus 6 karakter!' },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Konfirmasi Password"
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: 'Konfirmasi password harus diisi!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Konfirmasi password tidak cocok!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <div style={{ float: 'right' }}>
                            <Button type="default" style={{ marginRight: 2 }} onClick={() => changeModalNewOpen(false)}>Cancel</Button>
                            <Button type="primary" style={{ marginRight: 2 }} htmlType="submit">Simpan Data</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default App;
