import React from 'react';
import { Button, Modal, Form, Input, Spin } from 'antd';

const App = (props) => {
    const {
        // data
        userUpdatePasswordData, updatePasswordLoading,
        // actions
        changeUserUpdatePasswordData, handleUpdatePassword
    } = props;

    return (
        <Modal
            title="Ubah Password User"
            open={(userUpdatePasswordData.hasOwnProperty('id') ? true : false)}
            footer={null}
            style={{ top: 20 }}
            width={700}
            closable={false}
            destroyOnClose
        >
            <Spin spinning={updatePasswordLoading}>
                <Form
                    labelCol={{ span: 6 }}
                    layout="horizontal"
                    onFinish={(data) => {
                        handleUpdatePassword(userUpdatePasswordData.id, {
                            password: data.password
                        })
                    }}
                >
                    <Form.Item
                        label="Nama User"
                        name={"name"}
                        initialValue={userUpdatePasswordData.name}
                        rules={[
                            { required: true, message: 'Nama User harus diisi!' },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name={"email"}
                        initialValue={userUpdatePasswordData.email}
                        rules={[
                            { required: true, message: 'Nama User harus diisi!' },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>


                    <Form.Item
                        label="Password Baru"
                        name="password"
                        rules={[
                            { required: true, message: 'Password harus diisi!' },
                            { min: 8, message: 'Password minimal harus 8 karakter!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <div style={{ float: 'right' }}>
                            <Button type="default" style={{ marginRight: 2 }}
                                onClick={() => {
                                    changeUserUpdatePasswordData({})
                                }}
                            >Cancel</Button>
                            <Button type="primary" style={{ marginRight: 2 }} htmlType="submit">Perbarui Password</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default App;
