import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_PAYMENTS:
            return {
                ...state,
                payments: action.value
            }
        case type.CHANGE_FETCH_PAYMENTS_LOADING:
            return {
                ...state,
                fetchPaymentsLoading: action.value
            }
        case type.CHANGE_SELECTED_DATE_ARR:
            return {
                ...state,
                selectedDateArr: action.value
            }
        case type.CHANGE_MODAL_VERIFICATION_DATA:
            return {
                ...state,
                modalVerificationData: action.value
            }
        // case type.CHANGE_PAGE:
        //     return {
        //         ...state,
        //         page: action.value
        //     }
        // case type.CHANGE_PER_PAGE:
        //     return {
        //         ...state,
        //         perPage: action.value
        //     }
        case type.CHANGE_SORTER:
            return {
                ...state,
                sorter: action.value
            }
        case type.CHANGE_FILTER_PAYMENT_METHOD_ID:
            return {
                ...state,
                filterPaymentMethodId: action.value
            }
        case type.CHANGE_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: action.value
            }
        case type.CHANGE_MODAL_DELETE_DATA:
            return {
                ...state,
                modalDeleteData: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        default:
            return state
    }
}

const defaultState = () => ({
    payments: [],
    fetchPaymentsLoading: false,
    modalVerificationData: {},
    modalDeleteData: {},
    paymentMethods: [],
    // pagination
    // page: 1,
    // perPage: 50,
    sorter: null,
    filterPaymentMethodId: null,
    selectedDateArr: null
})

export default main