import { Get } from "../../../helpers/ajax";
import { BASE_API_URL } from "../../../config";

import { notification } from 'antd';

const key = "ITEM_LIST"

export const type = {
    RESET: `${key}_RESET`,
    CHANGE_LOADING: `${key}_CHANGE_LOADING`,
    CHANGE_ITEM_DATA: `${key}_CHANGE_ITEM_DATA`,
}

export const reset = () => ({
    type: type.RESET,
});

export const changeLoading = (value) => ({
    type: type.CHANGE_LOADING,
    value
})

export const changeItemData = (value) => ({
    type: type.CHANGE_ITEM_DATA,
    value
})

export const handleFetchDataItem = () => {
    return async (dispatch, getState) => {
        let url = `${BASE_API_URL}/items`

        dispatch(changeLoading(true))
        const res = await Get(url);
        dispatch(changeLoading(false))

        if (!res) {
            return notification.error({
                message: 'Error',
                description: "Hubungi administrator",
            })
        }

        if (res.status !== "ok") {
            return notification.error({
                message: 'Error',
                description: res.message,
            })
        }

        dispatch(changeItemData(res || {}))
        return
    }
}

export default type