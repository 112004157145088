import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }
        case type.CHANGE_SELECTED_KLOTER_ID:
            return {
                ...state,
                selectKloterId: action.value
            }
        case type.CHANGE_VERIFY_LOADING:
            return {
                ...state,
                verifyLoading: action.value
            }
        case type.CHANGE_DATA:
            return {
                ...state,
                data: action.value
            }
        case type.CHANGE_ITEM_HEAD:
            return {
                ...state,
                itemHead: action.value
            }
        case type.CHANGE_MODAL_VERIFICATION_DATA:
            return {
                ...state,
                modalVerificationData: action.value
            }
        case type.CHANGE_MODAL_COMPLETING_ITEM_DATA:
            return {
                ...state,
                modalCompletingItemData: action.value
            }
        case type.CHANGE_SAVE_COMPLETING_ITEM_LOADING:
            return {
                ...state,
                saveCompletingItemLoading: action.value
            }
        case type.CHANGE_KLOTERS_DATA:
            return {
                ...state,
                kloters: action.value
            }
        case type.RESET:
            return {
                ...defaultState(),
            };
        default:
            return state
    }
}

const defaultState = () => ({
    kloters: [],
    selectKloterId:null,

    itemHead: [],
    data: [],
    isLoading: false,

    modalVerificationData: {},
    verifyLoading: false,

    modalCompletingItemData: {},
    saveCompletingItemLoading: false,
})

export default main