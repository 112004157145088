import React, { } from 'react'
import { useParams } from 'react-router-dom';
import {
    Modal, Image, Button
} from 'antd';

function App(props) {
    let { id: klotersJamaahsId } = useParams();

    const {
        modalItemDetail, changeModalItemDetail, habdleDeleteItem
    } = props

    return (
        <>
            <Modal
                title={modalItemDetail?.name}
                open={(modalItemDetail.hasOwnProperty('name')) ? true : false}
                onCancel={() => {
                    changeModalItemDetail({})
                }
                }
                disabled
                destroyOnClose
                footer={[
                    <Button key="back" type='danger' onClick={() => habdleDeleteItem(klotersJamaahsId, modalItemDetail.jamaah_kloter_item_id)}>
                        Hapus Data?
                    </Button>
                ]}
            >
                <p>
                    status: <strong>sudah</strong>
                </p>
                <p>
                    {modalItemDetail.description}
                </p>
                {
                    (modalItemDetail?.sf_images)
                    &&
                    <Image.PreviewGroup
                        preview={{
                            onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                        }}
                    >
                        {
                            modalItemDetail.sf_images.map((image) => (
                                <Image width={200} src={image} />
                            ))
                        }
                    </Image.PreviewGroup>
                }
            </Modal>
        </>
    )
}

export default App;
