export const rupiahFormat = (number) => (
    new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
    }).format(number).replace(/\D00$/, '')
);

export const usdFormat = (number) => (
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    }).format(number).replace(/(?<=\d)(?=(\d{3})+$)/g, '.').replace('$', '$ ')
);


export const numeratorFormatID = (number) => {
    // Ubah angka menjadi string untuk mempermudah pemisahan ribuan
    if (typeof number !== "number") {
        return number;
    }
    const numberString = number.toString();

    // Gunakan regex untuk memisahkan angka dalam format ribuan, ratusan ribu, dan juta
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
