export const bilanganIndonesia = (nilai) => {
    nilai = Math.abs(nilai);
    var simpanNilaiBagi = 0;
    var huruf = ["", "Satu", "Dua", "Tiga", "Empat", "Lima", "Enam", "Tujuh", "Delapan", "Sembilan", "Sepuluh", "Sebelas"];
    var temp = "";

    if (nilai < 12) {
        temp = " " + huruf[nilai];
    }
    else if (nilai < 20) {
        temp = bilanganIndonesia(nilai - 10) + " Belas";
    }
    else if (nilai < 100) {
        simpanNilaiBagi = Math.floor(nilai / 10);
        temp = bilanganIndonesia(simpanNilaiBagi) + " Puluh" + bilanganIndonesia(nilai % 10);
    }
    else if (nilai < 200) {
        temp = " Seratus" + bilanganIndonesia(nilai - 100);
    }
    else if (nilai < 1000) {
        simpanNilaiBagi = Math.floor(nilai / 100);
        temp = bilanganIndonesia(simpanNilaiBagi) + " Ratus" + bilanganIndonesia(nilai % 100);
    }
    else if (nilai < 2000) {
        temp = " Seribu" + bilanganIndonesia(nilai - 1000);
    }
    else if (nilai < 1000000) {
        simpanNilaiBagi = Math.floor(nilai / 1000);
        temp = bilanganIndonesia(simpanNilaiBagi) + " Ribu" + bilanganIndonesia(nilai % 1000);
    }
    else if (nilai < 1000000000) {
        simpanNilaiBagi = Math.floor(nilai / 1000000);
        temp = bilanganIndonesia(simpanNilaiBagi) + " Juta" + bilanganIndonesia(nilai % 1000000);
    }
    else if (nilai < 1000000000000) {
        simpanNilaiBagi = Math.floor(nilai / 1000000000);
        temp = bilanganIndonesia(simpanNilaiBagi) + " Miliar" + bilanganIndonesia(nilai % 1000000000);
    }
    else if (nilai < 1000000000000000) {
        simpanNilaiBagi = Math.floor(nilai / 1000000000000);
        temp = bilanganIndonesia(nilai / 1000000000000) + " Triliun" + bilanganIndonesia(nilai % 1000000000000);
    }

    return temp;
}