import type from './actions'

const main = (state = defaultState(), action) => {
    switch (action.type) {
        case type.RESET:
            return {
                ...defaultState(),
            };
        case type.CHANGE_KLOTER_DETAIL:
            return {
                ...state,
                kloterData: action.value
            }
        case type.CHANGE_LOADING:
            return {
                ...state,
                isLoading: action.value
            }

        case type.CHANGE_ACTIVE_INACTIVE_KLOTER_LOADING:
            return {
                ...state,
                isActiveInactiveKloterLoading: action.value
            }
        case type.CHANGE_MODAL_INACTIVE_KLOTER_OPEN:
            return {
                ...state,
                isModalInactiveKloterOpen: action.value
            }

        case type.CHANGE_MODAL_ACTIVE_KLOTER_OPEN:
            return {
                ...state,
                isModalActiveKloterOpen: action.value
            }
            
        case type.CHANGE_MODAL_UPDATE_KLOTER_OPEN:
            return {
                ...state,
                isModalUpdateKloterOpen: action.value
            }
        case type.CHANGE_UPDATE_KLOTER_LOADING:
            return {
                ...state,
                isUpdateKloterLoading: action.value
            }
        default:
            return state
    }
}

const defaultState = () => ({
    isLoading: false,
    kloterData: {},

    isActiveInactiveKloterLoading: false,
    isModalInactiveKloterOpen: false,
    isModalActiveKloterOpen: false,

    isModalUpdateKloterOpen: false,
    isUpdateKloterLoading: false,
})

export default main